import React from 'react'

const ModalContainer = ({ children, modalOpen, toggleModal, ...props }) => {
  return (
    <div
      style={{ backgroundColor: '#000000aa' }}
      className={`  fixed bottom-0 w-screen min-h-screen h-full overflow-hidden z-20 justify-center items-center ${modalOpen ? 'flex opacity-100 transition-all delay-1000 ' : 'hidden opacity-0 delay-1000 transition-all'}  `}
      onClick={toggleModal}
      {...props}
    >
      <div onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  )
}

export default ModalContainer