import { useState } from "react";
import { Link } from "react-scroll";
import { SectionTitle } from "../atoms";
import { SectionWrapper } from "../Layout";
import { Header } from "../molecules";
import { Footer } from "../organisms";

const PrivacyPolicy = () => {

  const Section = ({ title, children, ourValues }) => (

    <div className="">
      {/* <h4 id={title.trim().replace(/ /g, '')} className="text-2xl font-black text-primary mb-5">
        {title}
      </h4> */}
      <SectionTitle title={"Privacy Policy"} />
      <div className="flex flex-col-reverse md:flex-row items-center">
        <div className="flex-grow text-justify">
          {ourValues ? <>{children}</> :
            <p className="font-normal text-sm font-sofia text-black leading-6 space-y-5">
              {children}
            </p>
          }
        </div>
      </div>

    </div>)



  return (
    <div>
      <Header />

      <main className="flex-1 py-20 px-7 sm:px-20 lg:pr-44 md:pl-20 space-y-11 ">
        <Section title='' >

          <p>
            The following terms and conditions constitute a legally binding contract (this "Agreement") between you (“you” or “your”) and Uplandshunt, Inc., a Uplandshunt corporation that governs all use by you of uplandshunt.com website (the "Site") and the services available on or at the Site (taken together with the use of the Site, the "Services"). We’ll refer to uplandshunt and all of its subsidiaries and affiliated companies collectively as "uplandshunt", "we", "our", "us", etc.
          </p>

          <p>
            We provide this website, for the convenience of buyers, tenants, landlords business owners and browsers interested in immovable property, and collect content in the form of advertisements from private sellers, subscribing estate agents, letting agents, developers as well as related parties, to display properties they are marketing.  YOUR USE OF THE SITE CONSTITUTES YOUR ACCEPTANCE OF AND AGREEMENT TO BE BOUND BY THIS AGREEMENT.  FURTHERMORE, BY PLACING AN ORDER FOR PRODUCTS OR SERVICES FROM THE SITE, YOU ACCEPT AND ARE BOUND BY THIS  AGREEMENT.  If you do not agree to this Agreement, do not use the Site or any other Services. If you use our Services only for your personal use, you are considered a "User". If you use our Services to execute orders you are considered a "Agent".
            Welcome to Uplandshunt Privacy Policy!
          </p>

          <p>
            Uplandshunt, Inc. (“we”, “our”, “us”, or “Uplandshunt”) respects the privacy of its users and is fully committed to protect their personal data and use it in accordance with data privacy laws. This Privacy Policy describes how we collect, use, and process any personal data that we collect from you or you provide to us in connection with your use of our website (www.Uplandshunt.com) or our mobile apps and  Services. By accessing or using our Services, you signify your understanding of the terms set out in this Privacy Policy. <br />
            We do not knowingly collect, maintain, disclose, or sell the personal information about users under the age of sixteen (16). If you are under the age of 16, please do not use our Services. If you are under the age of 16 and have used our Services, please contact us at the email address below so that we may delete your personal information.
          </p>

          <p>
            If you use our Services only for your personal use, you are to be considered as the “User” and for the purpose of the General Data Protection Regulation (“GDPR”), we are the data controller.
          </p>

          <p>
            Like many websites, we use "cookies" to enable us to personalise your visits to this website, simplify the signing-in procedure, keep track of your preferences and to track the usage of this website. Cookies are small pieces of information that are stored in the hard drive of your computer by your browser. Your browser will have the option to prevent websites using cookies, but please note that this may reduce the functionality of this Site and other website.
          </p>
        </Section>


      </main>


      <Footer />
    </div>
  )

};

export { PrivacyPolicy };

  // <div>
  //   <SectionWrapper>
  //     <p className="font-semibold text-xl my-4 text-primary">Privacy</p>
  //     <div className="font-normal text-base mb-20">
  //       <p className="my-4">
  //         Thank you for visiting our website. This privacy policy tells you
  //         how we use personal information collected at this site. Please read
  //         this privacy policy carefully before using this website or
  //         submitting any personal information. By using the site, you are
  //         accepting the practices described in this privacy policy. These
  //         practices may be changed, but any changes will be posted and changes
  //         will only apply to activities and information on a going forward,
  //         not retroactive basis. You are encouraged to review the privacy
  //         policy whenever you visit the site to make sure that you understand
  //         how any personal information you provide will be used.
  //       </p>
  //       <p className="text-lg font-bold my-2"> Note</p>
  //       <p className="my-4">
  //         The privacy practices set forth in this privacy policy are for this
  //         website only. If you link to other websites, please review the
  //         privacy policies posted at those sites. This privacy policy governs
  //         the way we collect and use information, and by using the services on
  //         our website ('Services') you agree to be bound by this policy. All
  //         references within this policy to 'we/us/our' refer to Uplandshunt.
  //       </p>
  //       <p className="text-lg font-bold my-2">Collection of Information</p>
  //       <p className="my-4">
  //         We collect personally identifiable information, like names, postal
  //         addresses, email addresses, etc. when voluntarily submitted by our
  //         visitors. The information you provide is used to fulfill your
  //         specific request. When visiting our website, we may place one or
  //         more cookies (a small file that is stored by your browser to
  //         recognize you and store your preferences) on your computer. This
  //         helps us to improve our Services by personalizing information you
  //         may want. You may adjust the settings on your browser to refuse
  //         cookies but some of the Services may not work if you do so. We may
  //         also log information from your computer including the existence of
  //         cookies, your IP address, and information about your browser program
  //         in order to allow us to diagnose problems, administer and track your
  //         usage of the Services.
  //       </p>
  //       <p className="text-lg font-bold my-2">How We Use Information</p>
  //       <p className="my-4">
  //         We use the information we collect to provide the Services and to
  //         communicate with you. We do not rent or sell personal information
  //         and will only share your information in the following circumstances:
  //         <br />
  //         (1) When you have consented or directed us to share the information.{" "}
  //         <br />
  //         (2) With service providers or affiliate companies who perform
  //         services on our behalf. <br />
  //         (3) In order to satisfy any requirement under the law or to protect
  //         our rights or prevent any concerns relating to fraud or security.{" "}
  //         <br />
  //         (4) In the event of a merger or sale of all or part of our business
  //         whereby personal information may be transferred as an asset of the
  //         business. We may share with third parties aggregate information or
  //         information that does not personally identify individuals.
  //       </p>
  //       <p className="text-lg font-bold my-2"> Safety And Security</p>
  //       <p className="my-4">
  //         Personal information is processed and stored in our databases and we
  //         have taken reasonable steps to secure and protect the information
  //         under our control, including establishing processes to prevent
  //         unauthorized access or disclosure of this information. However,
  //         whilst we make every effort to ensure the security of your
  //         information, we are unable to guarantee the protection of the
  //         information from misuse, accidental disclosure, or unauthorized acts
  //         by others. Information provided by you may be stored or processed
  //         outside Nigeria.
  //       </p>
  //       <p className="text-lg font-bold my-2"> Third Parties</p>
  //       <p className="my-4">
  //         We use third parties to serve advertisements on our website and
  //         these third parties may use cookies or other means to help measure
  //         the effectiveness of their advertisements. Our website may also
  //         include links to other unrelated websites and/or contact forms to
  //         other unrelated parties which may collect personal information. Our
  //         privacy policy does not extend to these third parties or other
  //         websites and we recommend that users refer directly to these parties
  //         regarding their privacy policies before supplying personal details
  //         to these companies.
  //       </p>
  //       <p className="text-lg font-bold my-2"> Commitment to Data Security</p>
  //       <p className="my-4">
  //         Your personally identifiable information is kept secure. Only
  //         authorized employees, agents, and contractors (who have agreed to
  //         keep information secure and confidential) have access to this
  //         information. All emails and newsletters from this site allow you to
  //         opt-out of further mailings.
  //       </p>
  //       <p className="text-lg font-bold my-2"> Communicating With You</p>
  //       <p className="my-4">
  //         to receive emails about our Services, you may opt-out of such
  //         communications by following the instructions at the bottom of any of
  //         our emails, or by mailing us via uplandshuntt@gmail.com
  //       </p>
  //     </div>
  //   </SectionWrapper>
  //   <Footer />
  // </div>