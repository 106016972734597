import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { axiosWithAuth } from "../Auth/Axios";
import DashboardLoader from "../templates/DashboardLoader";

const Transactions = () => {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const getAllTransactions = () => {
    setLoading(false);
    axiosWithAuth()
      .get("/transaction")
      .then((response) => {
        const results = response.data.data;
        const sucessMessage = response.data.message;
        setTransactions(results);
        toast.success(sucessMessage);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllTransactions();
  }, []);
  return (
    <>
      {loading ? (
        <DashboardLoader />
      ) : (
        <div className="mt-8  m-auto w-11/12 border border-b-0 border-ashThree rounded-md">
          <div className="mt-2 p-4 grid grid-cols-5 w-full mb-4 font-bold text-base border-b border-ashThree">
            <p>ID No</p>
            <p>Date</p>
            <p>Amount</p>
            <p>Module Type</p>
            <p>Status</p>
          </div>
          {transactions.map((transaction, index) => {
            return (
              <div
                key={transaction.id}
                className="p-4 grid grid-cols-5 mb-4 font-normal text-base border-b border-ashThree"
              >
                <div>
                  <p>{index + 1}</p>
                </div>
                <p>{new Date(transaction.createdAt).toLocaleDateString()}</p>
                <p>₦{transaction.amount}</p>
                <p>
                  {" "}
                  {transaction.module_type.charAt(0).toUpperCase() +
                    transaction.module_type.slice(1)}
                </p>
                <div className="flex items-center justify-center">
                  <button
                    className="w-1/2 uppercase text-white rounded-3xl px-2 py-1 text-xs"
                    style={{
                      backgroundColor:
                        transaction.status === "completed"
                          ? "#29CC97"
                          : transaction.status === "pending"
                          ? "black"
                          : "#5C1763",
                    }}
                  >
                    {transaction.status}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export { Transactions };
