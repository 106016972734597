import { useState } from "react";
import { axiosWithAuth } from "../Auth/Axios";
import SimpleDropZone from "../templates/SimpleDropZone";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { DashboardSectionTitle } from "../atoms";

const UpdateDocument = () => {
  let { id } = useParams();
  let { name } = useParams();

  // const handleChangeStatus = ({ meta }, status) => {
  // };

  const [loading, setLoading] = useState(false);

  const handleSubmit = (files) => {
    const fileNames = files.map((file) => file.file);

    const formdata = new FormData();
    formdata.append("file", fileNames[0]);
    formdata.append("document_name", name);
    setLoading(true);
    axiosWithAuth()
      .put(`property/uploads/${id}/document`, formdata)
      .then((response) => {
        const successMessage = response.data.data;
        toast.success(successMessage);
        setLoading(false);
        // location.push(`/dashboard/listings/documents/${id}`);
      });
  };

  return (
    <div className="m-auto w-11/12 mt-6">
      <div className="w-full ">
        <DashboardSectionTitle text='Update Existing Document' showButton={false} />
      </div>
      <div className=" w-8/12">
        <SimpleDropZone
          maximumFiles="1"
          minimumFiles="1"
          fileType=".pdf,.doc,.docx,.jpg,.jpeg"
          handleSubmit={handleSubmit}
          // handleChangeStatus={handleChangeStatus}
          buttonText="Update"
          loading={loading}
          isImageTitle
          imageTitle={name}
        />
        <div className="mt-4">
          <p className="text-xs font-semibold text-ashThree">
            Document should not be more than 2MB*
          </p>
        </div>
      </div>
    </div>
  );
};

export { UpdateDocument };
