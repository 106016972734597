import { useState } from "react";
import dropdown from "../assets/dropdown.svg";
import Option from "./Option";

const FilterOption = ({ item, setQueryParameter, queryParameter }) => {
  const [filterOption, setFilterOption] = useState(false);
  const toggle = (e) => {
    e && e.stopPropagation()
    setFilterOption(!filterOption);
  };
  return (
    <div className="">
      <div
        className="flex items-center mb-5 cursor-pointer"
        // onMouseEnter={() => setFilterOption(true)}
        onClick={toggle}
        tabIndex="0"
      >
        <p className="font-semibold text-base capitalize" style={{ flex: "1" }}>
          {item[0].replace('_', ' ')}
        </p>
        <img src={dropdown} alt="dropdown" className="w-4" />
      </div>
      {filterOption && (
        <div
          className="mb-4 shadow py-1 rounded-md"
        >

          <Option
            filteredOption={queryParameter}
            options={item}
            onClick={
              (name) => {
                setQueryParameter((prev) => { return { ...prev, [item[0]]: name } })
              }
            }

          />
        </div>
      )
      }
    </div >
  );
};

export default FilterOption;
