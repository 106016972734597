import { useState } from "react";
import SimpleDropZone from "../templates/SimpleDropZone";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, DashboardSectionTitle } from "../atoms";
import { axiosWithAuth, axiosInstance } from "../Auth/Axios";

const DocumentUpload = () => {
  let { id } = useParams();
  const location = useHistory();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (files, imageTitle) => {
    const fileNames = files.map((file) => file.file);
    const formdata = new FormData();
    formdata.append("file", fileNames[0]);
    formdata.append("document_name", imageTitle);

    setLoading(true);
    axiosWithAuth()
      .post(`property/${id}/documents`, formdata)
      .then((response) => {
        const successMessage = response.data.data;
        toast.success(successMessage);
        setLoading(false);
      });
  };

  // const getPropertyDetails = () => {
  //   // setLoading(true);
  //   axiosInstance.get(`property/${id}`).then((response) => {
  //     return response;
  //   });
  // };

  const saveDocuments = () => {
    setLoading(true);
    axiosWithAuth()
      .post(`property/${id}/save`)
      .then((response) => {
        setLoading(false);
        const successMessage = response.data.message;
        toast.success(successMessage);
        location.push(`/dashboard/listings/editDetails/${id}`);
      });
  };
  return (
    <div className="m-auto w-11/12 mt-6">
      <div className="w-full ">
        <DashboardSectionTitle text='Upload Documents' showButton={false} />

      </div>

      <div className="w-full">
        <SimpleDropZone
          maximumFiles="1"
          minimumFiles="1"
          handleSubmit={handleSubmit}
          fileType=".pdf,.doc,.docx,.jpg,.jpeg"
          buttonText="Submit"
          imageTitle="Certificate Of Occupancy"
          loading={loading}
          isImageTitle
        />

        <SimpleDropZone
          maximumFiles="1"
          minimumFiles="1"
          handleSubmit={handleSubmit}
          fileType=".pdf,.doc,.docx,.jpg,.jpeg"
          buttonText="Submit"
          imageTitle="Governor’s Consent"
          loading={loading}
          isImageTitle
        />
        <SimpleDropZone
          maximumFiles="1"
          minimumFiles="1"
          handleSubmit={handleSubmit}
          fileType=".pdf,.doc,.docx,.jpg,.jpeg"
          buttonText="Submit"
          imageTitle="Government Gazette"
          loading={loading}
          isImageTitle
        />
        <SimpleDropZone
          maximumFiles="1"
          minimumFiles="1"
          handleSubmit={handleSubmit}
          fileType=".pdf,.doc,.docx,.jpg,.jpeg"
          buttonText="Submit"
          imageTitle="Rent agreement"
          loading={loading}
          isImageTitle
        />
      </div>
      <div
        onClick={saveDocuments}
        // to={`/dashboard/listings/editDetails/${id}`}
        className="flex justify-center w-full md:w-1/6"
      >
        <Button buttonText="Save" loading={loading} />
      </div>
    </div>
  );
};

export { DocumentUpload };
