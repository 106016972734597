import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { MiniPropertyCard, Modal } from "../organisms";
import { axiosWithAuth } from "../Auth/Axios";
import { ListingsLayout } from "../Layout";
import DashboardLoader from "../templates/DashboardLoader";
import { Documents } from "./Documents";
import LoadSpinner from "../templates/LoadSpinner";
import { set } from "react-hook-form";

const BidsRecieved = () => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [counterInputView, setCounterInputView] = useState(false)
  const [counterInputId, setCounterInputId] = useState(0)
  const [counterInput, setCounterInput] = useState('')
  const [error, setError] = useState("");

  const handleClose = () => {
    setShowModal(false);
  };

  const handleCounterInputChange = (e) => {
    setCounterInput(e.target.value)
  }

  const getBidsReceived = () => {
    axiosWithAuth()
      .get(`/bid/received`)
      .then((response) => {
        const results = response.data.data;
        console.log(results);
        setData(results);
        setLoading(false);
      });
  };

  const acceptBid = (bidId) => {
    if (!window.confirm('Are you sure you want to accept this bid?')) return

    setSpinnerLoading(true);
    axiosWithAuth()
      .post(`bid/${bidId}/accept`)
      .then((response) => {
        const result = response.data.data;
        toast.success(result);
        getBidsReceived()
        setSpinnerLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          setSpinnerLoading(false);
          const errorMessage = error.response.data.data;
          setError(errorMessage);
          toast.error(errorMessage);
        }
        // handle error
        setError(error.status);
      });
  };

  const submitCounter = (e, bidId) => {
    e.preventDefault()
    if (!counterInput) return
    setSpinnerLoading(true);

    const bidObject = {
      bidId,
      "amount": counterInput
    }

    axiosWithAuth()
      .post(`bid/counter`, bidObject)
      .then((response) => {
        const result = response.data.data;
        toast.success(result);
        getBidsReceived()
        setSpinnerLoading(false);

        if (response.data.message === 'success') {
          counterInputViewToggle(bidId, true)
        }
      })
      .catch(function (error) {
        if (error.response) {
          setSpinnerLoading(false);
          const errorMessage = error.response.data.data;
          setError(errorMessage);
          toast.error(errorMessage);
        }
        // handle error
        setError(error.status);
      });
  };


  const counterInputViewToggle = (id, close) => {
    setCounterInputView(close ? false : true)
    id && setCounterInputId(id)
  }


  useEffect(() => {
    getBidsReceived();
  }, []);

  const showDocument = () => {
    setShowModal(true);
  };

  return (
    <>
      <div >
        <ListingsLayout>
          {loading ? (
            <DashboardLoader />
          ) : (
            <section className="overflow-x-auto">

              <table className="mt-8 overflow-x-auto w-full border border-ashThree rounded-md">

                <tr
                  className="mt-2  mb-4 w-full font-bold text-base border-b border-ashThree"
                // style={{ flex: "1" }}
                >
                  <td className="p-4">Document Name</td>
                  <td>Bid Date</td>
                  <td>Price</td>
                  <td>Counter Bid</td>
                  <td style={{ minWidth: '200px' }} className="min-w-max">Action</td>
                </tr>


                {data.map((bid, index) => {
                  return (
                    <tr
                      key={bid.id}
                      className=" w-full shadow mb-4 font-normal text-base "
                    >
                      <td className="flex items-center p-4 ">
                        <img
                          src={bid.user.avatar}
                          alt="user-avatar"
                          className="rounded-full w-8 mr-2"
                        />
                        <div className="">
                          <p className="font-semibold text-base">
                            {bid.user.first_name + " " + bid.user.last_name}
                          </p>
                          <p className="font-normal text-xs w-2/3 lg:w-full text- break-words">
                            {bid.user.email}
                          </p>
                        </div>
                      </td>
                      <td>{new Date(bid.updatedAt).toLocaleDateString()}</td>
                      <td className="font-semibold text-base">₦{bid.amount}</td>


                      <td>
                        <div className=" uppercase text-sm font-bold">
                          {bid.counterBidId
                            ?
                            <div> {' '}
                              <span className="font-semibold text-base text-blue-600">
                                ₦{bid?.counterBid?.amount}
                              </span>
                            </div>
                            : <div className="text-yellow ">Pending</div>}
                        </div>
                      </td>


                      <td >
                        <div className="flex justify-between">
                          {bid.counterBidId
                            ? ''
                            : (counterInputView && counterInputId === bid.id)
                              ?
                              <form className="flex flex-col mt-2 " onSubmit={(e) => submitCounter(e, bid?.id)}>

                                <div>
                                  <label htmlFor="counter"></label>
                                  <input id='counter' type="text" onChange={handleCounterInputChange}
                                    className="border p-1.5" />
                                </div>

                                <div className="flex mt-2">
                                  <button className="uppercase block borde mx-auto  text-yellow text-xs font-bold p-2 focus:outline-none" type='button' onClick={() => counterInputViewToggle(bid?.id, true)}>
                                    Cancel
                                  </button>

                                  <button className="uppercase block borde mx-auto text-green text-xs font-bold p-2 focus:outline-none">
                                    {spinnerLoading ? '...' : "Submit"}
                                  </button>
                                </div>

                              </form>
                              : <div>
                                <button
                                  className="uppercase text-yellow text-xs font-bold p-2 focus:outline-none"
                                  onClick={() => counterInputViewToggle(bid?.id)}
                                >
                                  Counter
                                </button>
                                <button
                                  className="uppercase text-green text-xs font-bold p-2 focus:outline-none"
                                  onClick={() => acceptBid(bid.id)}
                                >
                                  Accept
                                </button>
                              </div>
                          }

                        </div>
                      </td>
                      <Modal
                        showModal={showModal}
                        handleClose={handleClose}
                        transferDocument={true}
                      >
                        <MiniPropertyCard id={id} />
                        <Documents showHeader={false} />
                        <div
                          className="m-auto w-11/12 flex justify-end"
                          onClick={() => acceptBid(bid.id)}
                        >
                          <button className="bg-primary text-white text-base font-bold px-6 py-2 my-2 rounded-md shadow-sm focus:outline-none">
                            {spinnerLoading ? <LoadSpinner /> : "Share"}
                          </button>
                        </div>
                      </Modal>
                    </tr>
                  );
                })}
              </table>
            </section>
          )}
        </ListingsLayout>
      </div>
    </>
  );
};

export { BidsRecieved };
