import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { DashboardHeader, Sidebar } from "../molecules";
import {
  Listings,
  Notification,
  Subscription,
  Profile,
  PropertyRequest,
  SavedProperty,
  MyPurchase,
  Documents,
  UserView,
  EditDetails,
  BidsRecieved,
  BidsPlaced,
  InterestSent,
  AddNewListing,
  PropertyRequestDocument,
  PaymentHistory,
  PropertyPurchased,
  PropertySold,
  AllSavedProperties,
  EditIndividualProfile,
  EditBusinessProfile,
  ImageUpload,
  DocumentUpload,
  RequestProperty,
  ViewBidsPropertyRequest,
  BidsSent,
  UserSubscription,
  AllProperties,
  Transactions,
  UpdateDocument,
  UpdateImage,
} from "../pages";
import { VideoUpload } from "./VideoUpload";

const Dashboard = () => {
  return (
    <div
      style={{
        backgroundImage: 'linear-gradient(#54436B, white)'
      }}
      className="">

      {/* <Router> */}
      <div className="md:flex md:min-w-full">
        <div className="hidden md:block w-80 flex-none ">
          <Sidebar />
        </div>

        <div className="h-screen overflow-y-auto md:rounded-l-3xl bg-white shadow-md md:border border-ashThree  inner-layou flex-1  relative">
          <DashboardHeader />
          <div className="flex-1 ">
            <Switch>
              <Route path="/dashboard" exact component={Notification} />
              <Route path="/dashboard/listings" exact component={Listings} />
              <Route
                path="/dashboard/listings/add"
                exact
                component={AddNewListing}
              />
              <Route
                path="/dashboard/listings/imageupload/:id"
                component={ImageUpload}
              />
              <Route
                path="/dashboard/listings/videoupload/:id"
                component={VideoUpload}
              />
              <Route
                path="/dashboard/listings/documentupload/:id"
                component={DocumentUpload}
              />
              <Route
                path="/dashboard/listings/documentupdate/:id/:name"
                component={UpdateDocument}
              />
              <Route
                path="/dashboard/listings/updateimage/:id"
                component={UpdateImage}
              />
              <Route
                path="/dashboard/listings/editdetails/:id"
                exact
                component={EditDetails}
              />
              <Route
                path="/dashboard/listings/userview/:id"
                exact
                component={UserView}
              />
              <Route
                path="/dashboard/listings/documents/:id"
                exact
                component={Documents}
              />
              <Route
                path="/dashboard/listings/bidsreceived/:id"
                exact
                component={BidsRecieved}
              />
              <Route
                path="/dashboard/listings/bidsSent/:id"
                exact
                component={BidsSent}
              />
              {/* <Route
              path="/dashboard/listings"
              exact
              component={EditNewListing}
            /> */}
              <Route
                path="/dashboard/savedproperty"
                exact
                component={SavedProperty}
              />
              <Route
                path="/dashboard/savedProperty/allproperties"
                exact
                component={AllProperties}
              />
              <Route
                path="/dashboard/savedProperty/allsavedproperties"
                exact
                component={AllSavedProperties}
              />
              <Route
                path="/dashboard/request/propertyrequest"
                exact
                component={PropertyRequest}
              />
              <Route
                path="/dashboard/request/requestproperty"
                exact
                component={RequestProperty}
              />
              <Route
                path="/dashboard/request/bidsplaced"
                exact
                component={BidsPlaced}
              />
              <Route
                path="/dashboard/request/bidsplaced/viewbids/:id"
                exact
                component={ViewBidsPropertyRequest}
              />
              <Route
                path="/dashboard/request/interestsent"
                exact
                component={InterestSent}
              />
              <Route
                path="/dashboard/request/documents"
                exact
                component={PropertyRequestDocument}
              />
              <Route path="/dashboard/purchase" exact component={MyPurchase} />
              <Route
                path="/dashboard/purchase/propertypurchased"
                exact
                component={PropertyPurchased}
              />
              <Route
                path="/dashboard/purchase/propertysold"
                exact
                component={PropertySold}
              />
              <Route
                path="/dashboard/purchase/paymenthistory"
                exact
                component={PaymentHistory}
              />
              <Route path="/dashboard/profile" exact component={Profile} />
              <Route
                path="/dashboard/editindividualprofile"
                exact
                component={EditIndividualProfile}
              />
              <Route
                path="/dashboard/editbusinessprofile"
                exact
                component={EditBusinessProfile}
              />
              <Route
                path="/dashboard/subscription"
                exact
                component={UserSubscription}
              />
              <Route
                path="/v1/payment/:checkpayment?"
                exact
                component={UserSubscription}
              />
              <Route
                path="/dashboard/subscription/upgradesub"
                exact
                component={Subscription}
              />
              <Route
                path="/dashboard/transactions"
                exact
                component={Transactions}
              />
            </Switch>
          </div>

        </div>
      </div >
      {/* </Router> */}
    </div>
  );
};

export { Dashboard };
