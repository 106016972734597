import prop1 from "../assets/prop1.png";
import prop2 from "../assets/prop2.png";
import prop3 from "../assets/prop3.png";
import prop4 from "../assets/prop4.svg";
import lagos from "../assets/lagos.svg";
import kano from "../assets/kano.svg";
import ibadan from "../assets/ibadan.svg";
import ilorin from "../assets/ilorin.svg";

export const property = [
  {
    id: "1",
    name: "Silver Spring | Residence Phase II",
    location: "Ibeju-Lekki, Lekki",
    price: "₦3,499,000",
    photo: prop1,
  },
  {
    id: "2",
    name: "Silver Spring | Residence Phase II",
    location: "Ibeju-Lekki, Lekki",
    price: "₦3,499,000",
    photo: prop2,
  },
  {
    id: "3",
    name: "Silver Spring | Residence Phase II",
    location: "Ibeju-Lekki, Lekki",
    price: "₦3,499,000",
    photo: prop3,
  },
  {
    id: "4",
    name: "Silver Spring | Residence Phase II",
    location: "Ibeju-Lekki, Lekki",
    price: "₦3,499,000",
    photo: prop4,
  },
  {
    id: "5",
    name: "Silver Spring | Residence Phase II",
    location: "Ibeju-Lekki, Lekki",
    price: "₦3,499,000",
    photo: prop1,
  },
  {
    id: "6",
    name: "Silver Spring | Residence Phase II",
    location: "Ibeju-Lekki, Lekki",
    price: "₦3,499,000",
    photo: prop2,
  },
  {
    id: "7",
    name: "Silver Spring | Residence Phase II",
    location: "Ibeju-Lekki, Lekki",
    price: "₦3,499,000",
    photo: prop3,
  },
  {
    id: "8",
    name: "Silver Spring | Residence Phase II",
    location: "Ibeju-Lekki, Lekki",
    price: "₦3,499,000",
    photo: prop4,
  },
  {
    id: "9",
    name: "Silver Spring | Residence Phase II",
    location: "Ibeju-Lekki, Lekki",
    price: "₦3,499,000",
    photo: prop1,
  },
  {
    id: "10",
    name: "Silver Spring | Residence Phase II",
    location: "Ibeju-Lekki, Lekki",
    price: "₦3,499,000",
    photo: prop2,
  },
  {
    id: "11",
    name: "Silver Spring | Residence Phase II",
    location: "Ibeju-Lekki, Lekki",
    price: "₦3,499,000",
    photo: prop3,
  },
  {
    id: "12",
    name: "Silver Spring | Residence Phase II",
    location: "Ibeju-Lekki, Lekki",
    price: "₦3,499,000",
    photo: prop4,
  },
];

export const city = [
  {
    id: "1",
    location: "Lagos",
    photo: lagos,
  },
  {
    id: "2",
    location: "Kano",
    photo: kano,
  },
  {
    id: "3",
    location: "Ibadan",
    photo: ibadan,
  },
  {
    id: "4",
    location: "Ilorin",
    photo: ilorin,
  },
  {
    id: "5",
    location: "Lagos",
    photo: lagos,
  },
  {
    id: "6",
    location: "Kano",
    photo: kano,
  },
  {
    id: "7",
    location: "Ibadan",
    photo: ibadan,
  },
  {
    id: "8",
    location: "Ilorin",
    photo: ilorin,
  },
  {
    id: "9",
    location: "Lagos",
    photo: lagos,
  },
  {
    id: "10",
    location: "Kano",
    photo: kano,
  },
  {
    id: "11",
    location: "Ibadan",
    photo: ibadan,
  },
  {
    id: "12",
    location: "Ilorin",
    photo: ilorin,
  },
];
