import { useState } from "react";
import { useParams } from "react-router";
import { axiosWithAuth } from "../Auth/Axios";
import SimpleDropZone from "../templates/SimpleDropZone";
import { toast } from "react-toastify";
import { DashboardSectionTitle } from "../atoms";

const UpdateImage = () => {
  let { id } = useParams();

  const [loading, setLoading] = useState(false);

  const handleChangeStatus = ({ meta }, status) => { };

  const handleSubmit = (files) => {
    const fileNames = files.map((file) => file.file);

    const formdata = new FormData();
    formdata.append("file", fileNames[0]);

    setLoading(true);
    axiosWithAuth()
      .put(`property/uploads/${id}/image`, formdata)
      .then((response) => {
        const successMessage = response.data.data;
        toast.success(successMessage);
        setLoading(false);
        // location.push(`/dashboard/listings/documents/${id}`);
      })
      .catch(function (error) {
        if (error.response) {
          const errorMessage = error.response.data.data;
          toast.error(errorMessage);
          setLoading(false);
        }
      });
  };

  return (
    <div className="m-auto w-11/12 mt-6">
      <div className="w-full ">
        <DashboardSectionTitle text='Update Existing Image' showButton={false} />
      </div>
      <div className="w-8/12">
        <SimpleDropZone
          maximumFiles="1"
          minimumFiles="1"
          fileType="image/*,png/*,svg/*,jpeg/*,jpg/*"
          handleSubmit={handleSubmit}
          handleChangeStatus={handleChangeStatus}
          buttonText="Update"
          loading={loading}
        />
        <div className="mt-4">
          <p className="text-xs font-semibold text-ashThree">
            Image should not be more than 2MB*
          </p>
        </div>
      </div>
    </div>
  );
};

export { UpdateImage };
