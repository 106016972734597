import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";

// import leftArrow from "../assets/leftArrow.svg";
import { Button, DashboardSectionTitle, Input } from "../atoms";
import SelectInput from "../atoms/Select";
import { axiosInstance, axiosWithAuth } from "../Auth/Axios";
import { multiSelectOptions } from "../data/SelectOptions";

const AddNewListing = () => {
  const location = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);
  const [listingTypeOptions, setListingTypeOptions] = useState([]);
  const [availabilityOptions, setAvailabilityOptions] = useState([]);
  const [furnishingTypeOptions, setFurnishingTypeOptions] = useState([]);
  const [depositStructureOptions, setDepositStructureOptions] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([])
  const [allCities, setAllCities] = useState({})
  const [cityOptions, setCityOptions] = useState([])
  const [roomsOptions, setRoomsOptions] = useState([{
    bedroom: '',
    bathroom: '',
    kitchen: '',

  }])


  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Property Name is required"),
    type: Yup.number().required("Property Type is required"),
    category: Yup.number().required("Category is required"),
    city: Yup.string().required("City is required"),
    price: Yup.number()
      .typeError("Price must be a number")
      .required("Price is required"),
    area: Yup.number()
      .typeError("Area of property must be a number")
      .required("Area of the Property is required"),
    postal_code: Yup.string().required("Postal Code is required"),
    address_line_one: Yup.string().required("Address Line 1 is required"),
    address_line_two: Yup.string().required("Address Line 2 is required"),
    country: Yup.string().required("Country is required"),
    amenity: Yup.array(Yup.string()).required("Amenities is required").min(1),
    availability: Yup.number().required("Availability is required"),
    kitchen: Yup.number()
      .typeError("Number of Kitchen must be a number")
      .required("Number of Kitchen is required"),
    bathroom: Yup.string().required("Number of Bathroom is required"),
    bedroom: Yup.number()
      .typeError("Number of Bedrooms must be a number")
      .required("Number of Bedrooms is required"),
    description: Yup.string().required("Property description is required"),
    furnish_type: Yup.number().required("Furnishing Type is required"),
    deposit_structure: Yup.number().required("Deposit Structure is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState, control, getFieldState, getValues } = useForm(formOptions);
  const { errors } = formState;



  const addNewListing = (data, e) => {
    console.log(data);
    setLoading(true);
    axiosWithAuth()
      .post("property", data)
      .then(function (response) {
        response && setLoading(false);
        toast.success(response.data.message);
        location.push(
          `/dashboard/listings/imageUpload/${response.data.data.id}`
        );
      })
      .catch(function (error) {
        if (error.response.status === 422) {
          setLoading(false);
          console.log(error.message);
          toast.error(error.message);
          // const errorMessage = error.response.data.data;
          // Object.values(errorMessage.errors)
          //   .flat()
          //   .map((err) => {
          //     toast.error(err);
          //   });
          setError(error);
        } else if (error.response.status === 400) {
          setLoading(false);
          const errorMessage = error.response.data.data;
          toast.error(errorMessage);
        }
      });
  };

  const getFilters = () => {
    setLoading(true);
    axiosInstance
      .get("property/attributes/all")
      .then((response) => {
        // handle success
        const category = response.data;
        const entries = Object.entries(category.data);
        console.log(entries);
        const propertyEntries = Object.values(entries[0][1]);
        const availabilityEntries = Object.values(entries[1][1]);
        const bathroom = Object.values(entries[3][1])
        const bedroom = Object.values(entries[4][1])
        const depositEntries = Object.values(entries[5][1]);
        const furnishingEntries = Object.values(entries[6][1]);
        const kitchen = Object.values(entries[7][1])
        const listingEntries = Object.values(entries[8][1]);
        const countries = Object.keys(Object.values(entries[9])[1]).filter(item => item === 'nigeria').map(item => ({ id: item, name: item }))
        const allCities = Object.values(entries[9])[1]

        setRoomsOptions({
          bedroom: bedroom?.map(item => ({ id: item?.id, name: item?.bedroom })),
          bathroom: bathroom?.map(item => ({ id: item?.id, name: item?.bathroom })),
          kitchen: kitchen?.map(item => ({ id: item?.id, name: item?.kitchen })),
        })
        setListingTypeOptions(listingEntries);
        setPropertyTypeOptions(propertyEntries);
        setAvailabilityOptions(availabilityEntries);
        setFurnishingTypeOptions(furnishingEntries);
        setDepositStructureOptions(depositEntries);
        setCountriesOptions(countries);
        setAllCities(allCities);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
      })
      .then(function () {
        // always executed
      });
  };

  const getCities = ({ target: { value } }) => {
    if (value) {
      const cities = allCities?.[value]
        ?.sort()
        ?.map(item => ({ id: item, name: item }))
        || [];

      setCityOptions(cities);
    }
  }

  useEffect(() => {
    getFilters();
  }, []);

  console.log(errors)

  return (


    <div className="w-11/12 mx-auto px-5 md:px-0  ">
      <div className="">
        <DashboardSectionTitle text='Add New Property Listing' showButton={false} />
      </div>


      <form
        className=" mt-8  flex flex-wrap"
        onSubmit={handleSubmit(addNewListing)}
      >
        <section className="md:w-10/12 w-full ">
          <Input
            type="text"
            label="Property Name"
            name="name"
            register={register("name")}
            error={errors.name?.message}
          />
          <SelectInput
            values={propertyTypeOptions}
            selectedValue="Sell"
            labelName="Category"
            name="category"
            register={register("category")}
            error={errors.category?.message}
          />

          <Input
            type="text"
            label="Price"
            name="price"
            register={register("price")}
            error={errors.price?.message}
          />

          <Input
            type="text"
            label="Area of Property (in sq. km.)"
            name="area"
            register={register("area")}
            error={errors.area?.message}
          />
          <Input
            type="text"
            label="Postal Code"
            name="postal_code"
            register={register("postal_code")}
            error={errors.postal_code?.message}
          />

          <SelectInput
            // onChange={getCities}
            // values={[...countriesOptions, { id: 'us', name: 'usa' }]}
            values={countriesOptions}
            type="text"
            labelName="Country"
            name="country"
            register={register("country")}
            error={errors.country?.message}
          />


          <SelectInput
            // values={cityOptions}
            values={allCities?.['nigeria']
              ?.sort()
              ?.map(item => ({ id: item, name: item }))
              || []}
            type="text"
            labelName="City"
            name="city"
            register={register("city")}
            error={errors.city?.message}
          />

          {/* {cityOptions.length > 0
            && 
          } */}

          {/* <Input
            type="text"
            label="City"
            name="city"
            register={register("city")}
            error={errors.city?.message}
          /> */}
          <Input
            type="text"
            label="Address Line 1"
            name="address_line_one"
            register={register("address_line_one")}
            error={errors.address_line_one?.message}
          />
          <Input
            type="text"
            label="Address Line 2"
            name="address_line_two"
            register={register("address_line_two")}
            error={errors.address_line_two?.message}
          />


          <SelectInput
            values={listingTypeOptions}
            selectedValue="1"
            labelName="Listing Type"
            name="type"
            register={register("type")}
            error={errors.type?.message}
          />
          <SelectInput
            values={availabilityOptions}
            selectedValue="Residence"
            labelName="Availability"
            name="availability"
            register={register("availability")}
            error={errors.availability?.message}
          />
          <SelectInput
            values={roomsOptions?.kitchen || []}
            type="text"
            labelName="Kitchens"
            name="kitchen"
            register={register("kitchen")}
            error={errors.kitchen?.message}
          />
          <SelectInput
            values={roomsOptions?.bathroom || []}
            type="text"
            labelName="Bathrooms"
            name="bathroom"
            register={register("bathroom")}
            error={errors.bathroom?.message}
          />
          <SelectInput
            values={roomsOptions?.bedroom || []}
            type="text"
            labelName="Bedrooms"
            name="bedroom"
            register={register("bedroom")}
            error={errors.bedroom?.message}
          />


          <SelectInput
            values={furnishingTypeOptions}
            selectedValue="Not furnished"
            labelName="Furnishing Type"
            name="furnish_type"
            register={register("furnish_type")}
            error={errors.furnish_type?.message}
          />
          <SelectInput
            values={depositStructureOptions}
            selectedValue="10%"
            labelName="Deposit Structure"
            name="deposit_structure"
            register={register("deposit_structure")}
            error={errors.deposit_structure?.message}
          />



          <div className="mb-10 mt-20 ">
            <Controller
              control={control}
              name="amenity"
              render={({ field: { onChange, value, ref } }) => {
                console.log('test', multiSelectOptions?.filter((c) =>
                  value?.includes(c?.value)
                ))

                return (
                  <div className="">
                    <label
                      htmlFor="amenity"
                      className="text-lg w-max font-semibold text-left"
                    >
                      Amenities
                    </label>

                    <Select
                      inputRef={ref}
                      value={multiSelectOptions?.filter((c) => value?.includes(c?.value)
                      )}
                      className='cursor-pointer mt-2'
                      onChange={(val) => onChange(val?.map((c) => c?.value))}
                      options={multiSelectOptions}
                      isMulti />
                    <span className="text-red-500 text-sm">
                      {errors.amenity?.message}
                    </span>
                  </div>
                );
              }}
            />
          </div>



          <div>
            <label className="text-lg font-semibold text-left">
              Property Description
            </label>
            <textarea
              className="w-full border border-ashThree focus:outline-none px-2 rounded-md py-1 mt-2"
              rows="4"
              cols="5"
              placeholder="Write a short property description"
              name="description"
              error={errors.description?.message}
              {...register("description")}
            ></textarea>
          </div>


        </section>
        <div className="flex w-full md:w-1/3 mx-auto justify-center items-center text-center my-10">
          <Button loading={loading} buttonText="Continue" type='submit' />
        </div>

      </form>
    </div>
  );
};

export { AddNewListing };
