import React, { useState } from "react";

import pictureIcon from "../assets/pictureIcon.png";
import { axiosWithAuth } from "../Auth/Axios";
import { toast } from "react-toastify";
import LoadSpinnerTwo from "../templates/LoadSpinnerTwo";
import edit from "../assets/userDashboard/edit.svg";
import upload from '../assets/upload.svg'


const UploadProfilePicture = ({ avatar }) => {
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
    console.log(e.target);
  };

  const getUserProfile = () => {
    axiosWithAuth()
      .get("auth/me")
      .then((response) => {
        const res = response.data.data;
        console.table(res);
        delete res.subscription
        const profile = JSON.parse(localStorage.getItem('auth'))
        console.table(profile.user);
        console.table();
        localStorage.setItem('auth', JSON.stringify({ ...profile.user, ...res }))
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error);
          console.log(error.status);
        }
        // handle error
      });
  };


  const handleUpload = async (e) => {
    if (!image.raw.length > 0) return
    e.preventDefault();
    setSpinnerLoading(true);
    console.log(image);
    const formData = new FormData();
    formData.append("avatar", image.raw, image.raw.name);
    axiosWithAuth()
      .post("account/profile-upload", formData)
      .then((response) => {
        const successMessage = response.data.message;
        toast.success(successMessage);
        setSpinnerLoading(false);
        getUserProfile()
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
        setSpinnerLoading(false);
      }
      )

      ;
  };

  return (
    <div>
      <label htmlFor="upload-button">
        {image.preview ? (
          <img src={image.preview} alt="dummy" width="300" height="300" />
        ) : (
          <>
            <span className="fa-stack fa-2x mt-3 mb-2">
              <i className="fas fa-circle fa-stack-2x" />
              <i className="fas fa-store fa-stack-1x fa-inverse" />
            </span>
            <div className="cursor-pointer overflow-hidden rounded-full w-28 ">
              <img
                src={avatar}
                alt="profileAvatar"
                className=" rounded-full h-28 w-28 object-cover overflow-hidden"
              />
            </div>
          </>
        )}
      </label>
      <input
        type="file"
        id="upload-button"
        style={{ display: "none" }}
        onChange={handleChange}

      />

      <br />
      <div onClick={handleUpload} className="cursor-pointer relative">
        {spinnerLoading ? (
          <LoadSpinnerTwo />
        ) : (
          <img
            src={upload}
            alt="upload"
            className="w-8 -mt-12 ml-16 bg-ashThree rounded-full p-2  "
          />
        )}
      </div>
    </div>
  );
};

export { UploadProfilePicture };
