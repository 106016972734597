import React from "react";
import { useState } from "react";

const Option = ({ options, onClick, filteredOption }) => {
  const [selectedOption, setSelectedOption] = useState(null)

  const title = options[0]
  const optionEntries = Object.values(options[1]);
  console.log(title);
  const getName = (id) => {
    console.log(optionEntries, id);
    return id
  };

  return (
    <div className="cursor-pointer text-sm px-1 ">

      {
        optionEntries.map(
          (option, index) => {
            const { name, bathroom, id, bedroom, kitchen, city, country } = option
            return (
              <div
                key={index}
                onClick={() => {
                  console.log('testing options', { name, bathroom, id, bedroom, kitchen, city, country });
                  console.log(getName(id));
                  setSelectedOption(id)
                  onClick(getName(name || bathroom || bedroom || kitchen || city || country))
                }}
                className={`flex items-center  my-1 px-1 py-2 rounded-md hover:bg-hoverPrimary ${selectedOption === id && "bg-hoverPrimary"} `}
              >
                <div className="w-4 mx-2">
                  {((selectedOption === id) || (filteredOption[title] === (name ?? bathroom ?? bedroom ?? kitchen ?? city ?? country))) && <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.28033 9.77513C5.98744 9.48223 5.51256 9.48223 5.21967 9.77513C4.92678 10.068 4.92678 10.5429 5.21967 10.8358L7.94202 13.5581C8.23491 13.851 8.70979 13.851 9.00268 13.5581L15.447 7.11383C15.7399 6.82093 15.7399 6.34606 15.447 6.05317C15.1541 5.76027 14.6792 5.76027 14.3863 6.05317L8.47235 11.9671L6.28033 9.77513Z" fill="#54436B" />
                  </svg>}

                </div>
                <p className=" flex-1 w-full ">
                  {name ?? bathroom ?? bedroom ?? kitchen ?? city ?? country}
                </p>
              </div>
            );
          }
        )
      }
    </div >
  );
};

export default Option;
