import { useEffect, useState } from "react";
import { useAuthState } from "../Context";

// import search from "../assets/search.svg";
// import active from "../assets/userDashboard/active.svg";
import closeButton from "../assets/closeButton.svg";
import DashboardLoader from "../templates/DashboardLoader";
import notify from "../assets/userDashboard/Notify.png";
import harmburger from "../assets/harmburger.svg";
import { Sidebar } from "../pages";

const DashboardHeader = () => {
  const userDetails = useAuthState();

  const [loading] = useState(false);
  const [notifications] = useState("0");
  const [showNotifications, setShowNotifications] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleShowSideBar = (e) => {
    // e.stopPropagation();
    setShowSidebar(!showSidebar);

  };

  // const getNotifications = () => {
  //   setLoading(true);
  //   axiosWithAuth()
  //     .get("/user/dashboard/notification_count")
  //     .then((response) => {
  //       const result = response.data;
  //       setNotifications(result);
  //       setLoading(false);
  //     });
  // };

  // const fetchNotifications = () => {
  //   setLoading(true);
  //   axiosWithAuth()
  //     .get("/user/dashboard/notifications")
  //     .then((response) => {
  //       const result = response.data;
  //       setNotifications(result);
  //       setLoading(false);
  //     });
  // };

  const showUserNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  useEffect(() => {
    // getNotifications();
    // fetchNotifications();
  }, []);
  return (
    <>
      {loading ? (
        <DashboardLoader />
      ) : (
        <>
          <div
            className={`flex items-center p-4  ${showSidebar && 'w-3/4 bg-primary fixed'} `}
            style={{ boxShadow: "0px 0px 4px 0px #eea7a740" }}
            onClick={toggleShowSideBar}
          >
            <div className={`md:hidden `}>
              {showSidebar ? (
                <img
                  src={closeButton}
                  alt="closeButton"
                  onClick={toggleShowSideBar}
                />
              ) : (
                <img
                  src={harmburger}
                  alt="harmburger"
                  className={`cursor-pointer ${showSidebar && ''}`}
                  onClick={toggleShowSideBar}
                />
              )}
            </div>


          </div>
          <div className="md:hidden ">{showSidebar && <Sidebar toggleShowSideBar={toggleShowSideBar} />}</div>
        </>
      )}
    </>
  );
};

export { DashboardHeader };
