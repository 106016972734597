import { useState } from 'react'

const Pagination = ({ pageCount = 5, colorClass = 'bg-primary', onPageChange, onSelectPage, nextText = 'Next >', prevText = '< Prev', currentPage }) => {
  // const [activePage, setActivePage] = useState(1)
  let activePage = 1
  const pages = []
  let current = 0
  for (let i = 1; i <= pageCount; i++) {
    pages.push(i)
  }
  const pagesStart = current > 1 ? [current - 1, current, current + 1] : [current, current + 1, current + 2]

  const changePage = ({ target }) => {
    switch (target.innerText) {

      case prevText:
        onPageChange({ type: prevText })
        break;

      case nextText:
        onPageChange({ type: nextText })
        break;

      default:
        break;
    }
  }

  const selectPage = (selectedPage) => {
    onSelectPage({ selectedPage })

  }

  return (
    <div className={`flex px-5 py-3 font-mono rounded-md font-bold capitalize text-yellow justify-between  lg:w-1/2 w-5/6 mx-auto text-sm md:text-base  border  focus:outline-none ${colorClass} `}>
      <button className='block focus:outline-none ' onClick={changePage}>{prevText} </button >
      <div className={` flex-1 flex justify-around `}>
        {pages.map((page, index) =>
          <button key={index} onClick={() => selectPage(page)} className={`md:w-8 md:h-8 w-5 h-5 md:mx-2 block duration-300 rounded-full focus:outline-none ${page === currentPage && 'font-black text-white'}`}>
            {page}
          </button>
        )}
      </div>
      <button className='block focus:outline-none ' onClick={changePage}>{nextText}</button >
    </div>
  )
}

export default Pagination