import React from "react";

const LoadSpinner = ({ className }) => (
  <div className={"lds-ring " + className}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default LoadSpinner;
