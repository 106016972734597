export const boostedProperties = [
  {
    start_at: "2021-08-08T09:27:31.586Z",
    end_at: "2021-08-28T12:27:31.586Z",
    status: "ACTIVE",
    boost_plan: {
      category: "Plan B",
      time: "30",
      price: "20000",
    },
    property: {
      id: 2,
      name: "Seeder House Two",
      price: "9000000",
      city: "Lekki Phase II",
      country: "Nigeria",
      postal_code: "10092",
      address_line_one: "Lekki-Epe Expressway",
      address_line_two: "Lekki",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      area: "600",
      status: "active",
      trade_status: "completed",
      createdAt: "2021-08-08T09:27:31.543Z",
      property_category: {
        id: 2,
        name: "To Buy",
      },
      property_type: {
        id: 1,
        name: "Under Construction",
      },
      bed: {
        id: 4,
        bedroom: "4 Bedrooms",
      },
      bath: {
        id: 6,
        bathroom: "6 Bathrooms",
      },
      kitchens: {
        id: 3,
        kitchen: "3 Kitchens",
      },
      furnish: {
        id: 1,
        name: "Not Furnished",
      },
      deposit_type: {
        id: 3,
        name: "30%",
      },
      amenities: [
        {
          id: 4,
          name: "Concierge",
        },
        {
          id: 3,
          name: "Rooftop Decker",
        },
        {
          id: 2,
          name: "Swimming Pool",
        },
        {
          id: 1,
          name: "Gym",
        },
        {
          id: 5,
          name: "Barbeque",
        },
      ],
      availability_status: {
        id: 1,
        name: "Available",
      },
      user: {
        email: "individualtest4@uplandhunt.com",
        last_name: "Fourth",
        first_name: "Test",
        phone: "9098989898",
        business: null,
        avatar: {
          url: "https://via.placeholder.com/150",
        },
      },
      images: [
        {
          id: 27,
          image_url: "https://via.placeholder.com/150",
        },
        {
          id: 2,
          image_url: "https://via.placeholder.com/150",
        },
      ],
      documents: [
        {
          id: 81,
          name: "Land Tenancy Agreement",
          image_url: "https://via.placeholder.com/150",
          status: "approved",
        },
        {
          id: 80,
          name: "Government Certificate",
          image_url: "https://via.placeholder.com/150",
          status: "rejected",
        },
        {
          id: 79,
          name: "Certificate of Occupancy",
          image_url: "https://via.placeholder.com/150",
          status: "pending",
        },
      ],
    },
  },
  {
    start_at: "2021-08-28T09:27:31.586Z",
    end_at: "2021-08-28T15:27:31.586Z",
    status: "ACTIVE",
    boost_plan: {
      category: "Plan B",
      time: "30",
      price: "20000",
    },
    property: {
      id: 10,
      name: "Seeder House Ten",
      price: "9000000",
      city: "Lekki Phase II",
      country: "Nigeria",
      postal_code: "10092",
      address_line_one: "Lekki-Epe Expressway",
      address_line_two: "Lekki",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      area: "600",
      status: "active",
      trade_status: null,
      createdAt: "2021-08-08T09:27:31.543Z",
      property_category: {
        id: 3,
        name: "Under Construction",
      },
      property_type: {
        id: 1,
        name: "Under Construction",
      },
      bed: {
        id: 4,
        bedroom: "4 Bedrooms",
      },
      bath: {
        id: 6,
        bathroom: "6 Bathrooms",
      },
      kitchens: {
        id: 3,
        kitchen: "3 Kitchens",
      },
      furnish: {
        id: 1,
        name: "Not Furnished",
      },
      deposit_type: {
        id: 3,
        name: "30%",
      },
      amenities: [
        {
          id: 1,
          name: "Gym",
        },
      ],
      availability_status: {
        id: 1,
        name: "Available",
      },
      user: {
        email: "individualtest4@uplandhunt.com",
        last_name: "Fourth",
        first_name: "Test",
        phone: "9098989898",
        business: null,
        avatar: {
          url: "https://via.placeholder.com/150",
        },
      },
      images: [
        {
          id: 35,
          image_url: "https://via.placeholder.com/150",
        },
        {
          id: 10,
          image_url: "https://via.placeholder.com/150",
        },
      ],
      documents: [
        {
          id: 105,
          name: "Land Tenancy Agreement",
          image_url: "https://via.placeholder.com/150",
          status: "approved",
        },
        {
          id: 104,
          name: "Government Certificate",
          image_url: "https://via.placeholder.com/150",
          status: "rejected",
        },
        {
          id: 103,
          name: "Certificate of Occupancy",
          image_url: "https://via.placeholder.com/150",
          status: "pending",
        },
      ],
    },
  },
  {
    start_at: "2021-08-08T09:27:31.586Z",
    end_at: "2021-08-08T14:27:31.586Z",
    status: "ACTIVE",
    boost_plan: {
      category: "Plan B",
      time: "30",
      price: "20000",
    },
    property: {
      id: 8,
      name: "Seeder House Eight",
      price: "9000000",
      city: "Lekki Phase II",
      country: "Nigeria",
      postal_code: "10092",
      address_line_one: "Lekki-Epe Expressway",
      address_line_two: "Lekki",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      area: "600",
      status: "active",
      trade_status: null,
      createdAt: "2021-08-08T09:27:31.543Z",
      property_category: {
        id: 2,
        name: "To Buy",
      },
      property_type: {
        id: 1,
        name: "Under Construction",
      },
      bed: {
        id: 4,
        bedroom: "4 Bedrooms",
      },
      bath: {
        id: 6,
        bathroom: "6 Bathrooms",
      },
      kitchens: {
        id: 3,
        kitchen: "3 Kitchens",
      },
      furnish: {
        id: 1,
        name: "Not Furnished",
      },
      deposit_type: {
        id: 3,
        name: "30%",
      },
      amenities: [
        {
          id: 1,
          name: "Gym",
        },
      ],
      availability_status: {
        id: 1,
        name: "Available",
      },
      user: {
        email: "individualtest4@uplandhunt.com",
        last_name: "Fourth",
        first_name: "Test",
        phone: "9098989898",
        business: null,
        avatar: {
          url: "https://via.placeholder.com/150",
        },
      },
      images: [
        {
          id: 33,
          image_url: "https://via.placeholder.com/150",
        },
        {
          id: 8,
          image_url: "https://via.placeholder.com/150",
        },
      ],
      documents: [
        {
          id: 99,
          name: "Land Tenancy Agreement",
          image_url: "https://via.placeholder.com/150",
          status: "approved",
        },
        {
          id: 98,
          name: "Government Certificate",
          image_url: "https://via.placeholder.com/150",
          status: "rejected",
        },
        {
          id: 97,
          name: "Certificate of Occupancy",
          image_url: "https://via.placeholder.com/150",
          status: "pending",
        },
      ],
    },
  },
  {
    start_at: "2021-08-08T09:27:31.586Z",
    end_at: "2021-08-08T14:27:31.586Z",
    status: "ACTIVE",
    boost_plan: {
      category: "Plan B",
      time: "30",
      price: "20000",
    },
    property: {
      id: 6,
      name: "Seeder House Six",
      price: "9000000",
      city: "Lekki Phase II",
      country: "Nigeria",
      postal_code: "10092",
      address_line_one: "Lekki-Epe Expressway",
      address_line_two: "Lekki",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      area: "600",
      status: "active",
      trade_status: null,
      createdAt: "2021-08-08T09:27:31.543Z",
      property_category: {
        id: 2,
        name: "To Buy",
      },
      property_type: {
        id: 1,
        name: "Under Construction",
      },
      bed: {
        id: 4,
        bedroom: "4 Bedrooms",
      },
      bath: {
        id: 6,
        bathroom: "6 Bathrooms",
      },
      kitchens: {
        id: 3,
        kitchen: "3 Kitchens",
      },
      furnish: {
        id: 1,
        name: "Not Furnished",
      },
      deposit_type: {
        id: 3,
        name: "30%",
      },
      amenities: [
        {
          id: 1,
          name: "Gym",
        },
      ],
      availability_status: {
        id: 1,
        name: "Available",
      },
      user: {
        email: "individualtest4@uplandhunt.com",
        last_name: "Fourth",
        first_name: "Test",
        phone: "9098989898",
        business: null,
        avatar: {
          url: "https://via.placeholder.com/150",
        },
      },
      images: [
        {
          id: 31,
          image_url: "https://via.placeholder.com/150",
        },
        {
          id: 6,
          image_url: "https://via.placeholder.com/150",
        },
      ],
      documents: [
        {
          id: 93,
          name: "Land Tenancy Agreement",
          image_url: "https://via.placeholder.com/150",
          status: "approved",
        },
        {
          id: 92,
          name: "Government Certificate",
          image_url: "https://via.placeholder.com/150",
          status: "rejected",
        },
        {
          id: 91,
          name: "Certificate of Occupancy",
          image_url: "https://via.placeholder.com/150",
          status: "pending",
        },
      ],
    },
  },
  {
    start_at: "2021-08-08T09:27:31.586Z",
    end_at: "2021-08-08T14:27:31.586Z",
    status: "ACTIVE",
    boost_plan: {
      category: "Plan B",
      time: "30",
      price: "20000",
    },
    property: {
      id: 4,
      name: "Seeder House Four",
      price: "9000000",
      city: "Lekki Phase II",
      country: "Nigeria",
      postal_code: "10092",
      address_line_one: "Lekki-Epe Expressway",
      address_line_two: "Lekki",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      area: "600",
      status: "active",
      trade_status: "completed",
      createdAt: "2021-08-08T09:27:31.543Z",
      property_category: {
        id: 2,
        name: "To Buy",
      },
      property_type: {
        id: 1,
        name: "Under Construction",
      },
      bed: {
        id: 4,
        bedroom: "4 Bedrooms",
      },
      bath: {
        id: 6,
        bathroom: "6 Bathrooms",
      },
      kitchens: {
        id: 3,
        kitchen: "3 Kitchens",
      },
      furnish: {
        id: 1,
        name: "Not Furnished",
      },
      deposit_type: {
        id: 3,
        name: "30%",
      },
      amenities: [
        {
          id: 5,
          name: "Barbeque",
        },
        {
          id: 4,
          name: "Concierge",
        },
        {
          id: 3,
          name: "Rooftop Decker",
        },
        {
          id: 2,
          name: "Swimming Pool",
        },
        {
          id: 1,
          name: "Gym",
        },
      ],
      availability_status: {
        id: 1,
        name: "Available",
      },
      user: {
        email: "individualtest4@uplandhunt.com",
        last_name: "Fourth",
        first_name: "Test",
        phone: "9098989898",
        business: null,
        avatar: {
          url: "https://via.placeholder.com/150",
        },
      },
      images: [
        {
          id: 29,
          image_url: "https://via.placeholder.com/150",
        },
        {
          id: 4,
          image_url: "https://via.placeholder.com/150",
        },
      ],
      documents: [
        {
          id: 87,
          name: "Land Tenancy Agreement",
          image_url: "https://via.placeholder.com/150",
          status: "approved",
        },
        {
          id: 86,
          name: "Government Certificate",
          image_url: "https://via.placeholder.com/150",
          status: "rejected",
        },
        {
          id: 85,
          name: "Certificate of Occupancy",
          image_url: "https://via.placeholder.com/150",
          status: "pending",
        },
      ],
    },
  },
];

export const propertyPurchased = [
  {
    id: 15,
    user_id: "10",
    name: "Seeder House Fifteen",
    price: "9000000",
    city: "Lekki Phase II",
    country: "Nigeria",
    postal_code: "10092",
    address_line_one: "Lekki-Epe Expressway",
    address_line_two: "Lekki",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    area: "600",
    status: "active",
    trade_status: null,
    createdAt: "2021-08-21T00:06:14.105Z",
    property_category: {
      id: 1,
      name: "To Rent",
    },
    property_type: {
      id: 1,
      name: "Under Construction",
    },
    bed: {
      id: 4,
      bedroom: "4 Bedrooms",
    },
    bath: {
      id: 6,
      bathroom: "6 Bathrooms",
    },
    kitchens: {
      id: 3,
      kitchen: "3 Kitchens",
    },
    furnish: {
      id: 1,
      name: "Not Furnished",
    },
    deposit_type: {
      id: 3,
      name: "30%",
    },
    amenities: [
      {
        id: 4,
        name: "Concierge",
      },
    ],
    availability_status: {
      id: 1,
      name: "Available",
    },
    user: {
      email: "businesstest3@uplandhunt.com",
      last_name: "Third",
      first_name: "Business",
      phone: "9098989898",
      business: {
        name: "Third Test Business",
        phone: "90909090909",
        address: "Illupeju",
        country: "Nigeria",
        city: "Mushin",
        state: "Lagos",
        website: "www.companylogo.test",
        logo: "https://via.placeholder.com/150",
      },
      avatar: {
        url: "https://via.placeholder.com/150",
      },
    },
    images: [
      {
        id: 40,
        image_url: "https://via.placeholder.com/150",
      },
      {
        id: 15,
        image_url: "https://via.placeholder.com/150",
      },
    ],
    documents: [
      {
        id: 45,
        name: "Land Tenancy Agreement",
        image_url: "https://via.placeholder.com/150",
        status: "approved",
      },
      {
        id: 44,
        name: "Government Certificate",
        image_url: "https://via.placeholder.com/150",
        status: "rejected",
      },
      {
        id: 43,
        name: "Certificate of Occupancy",
        image_url: "https://via.placeholder.com/150",
        status: "pending",
      },
    ],
  },
  {
    id: 17,
    user_id: "10",
    name: "Seeder House Seventeen",
    price: "9000000",
    city: "Lekki Phase II",
    country: "Nigeria",
    postal_code: "10092",
    address_line_one: "Lekki-Epe Expressway",
    address_line_two: "Lekki",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    area: "600",
    status: "active",
    trade_status: null,
    createdAt: "2021-08-21T00:06:14.105Z",
    property_category: {
      id: 2,
      name: "To Buy",
    },
    property_type: {
      id: 1,
      name: "Under Construction",
    },
    bed: {
      id: 4,
      bedroom: "4 Bedrooms",
    },
    bath: {
      id: 6,
      bathroom: "6 Bathrooms",
    },
    kitchens: {
      id: 3,
      kitchen: "3 Kitchens",
    },
    furnish: {
      id: 1,
      name: "Not Furnished",
    },
    deposit_type: {
      id: 3,
      name: "30%",
    },
    amenities: [],
    availability_status: {
      id: 1,
      name: "Available",
    },
    user: {
      email: "businesstest3@uplandhunt.com",
      last_name: "Third",
      first_name: "Business",
      phone: "9098989898",
      business: {
        name: "Third Test Business",
        phone: "90909090909",
        address: "Illupeju",
        country: "Nigeria",
        city: "Mushin",
        state: "Lagos",
        website: "www.companylogo.test",
        logo: "https://via.placeholder.com/150",
      },
      avatar: {
        url: "https://via.placeholder.com/150",
      },
    },
    images: [
      {
        id: 42,
        image_url: "https://via.placeholder.com/150",
      },
      {
        id: 17,
        image_url: "https://via.placeholder.com/150",
      },
    ],
    documents: [
      {
        id: 51,
        name: "Land Tenancy Agreement",
        image_url: "https://via.placeholder.com/150",
        status: "approved",
      },
      {
        id: 50,
        name: "Government Certificate",
        image_url: "https://via.placeholder.com/150",
        status: "rejected",
      },
      {
        id: 49,
        name: "Certificate of Occupancy",
        image_url: "https://via.placeholder.com/150",
        status: "pending",
      },
    ],
  },
];

export const propertySold = [
  {
    id: 1,
    user_id: "8",
    name: "Seeder House One",
    price: "9000000",
    city: "Lekki Phase II",
    country: "Nigeria",
    postal_code: "10092",
    address_line_one: "Lekki-Epe Expressway",
    address_line_two: "Lekki",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    area: "600",
    status: "active",
    trade_status: "completed",
    createdAt: "2021-08-21T00:06:14.105Z",
    property_category: {
      id: 2,
      name: "To Buy",
    },
    property_type: {
      id: 1,
      name: "Under Construction",
    },
    bed: {
      id: 4,
      bedroom: "4 Bedrooms",
    },
    bath: {
      id: 6,
      bathroom: "6 Bathrooms",
    },
    kitchens: {
      id: 3,
      kitchen: "3 Kitchens",
    },
    furnish: {
      id: 1,
      name: "Not Furnished",
    },
    deposit_type: {
      id: 3,
      name: "30%",
    },
    amenities: [
      {
        id: 1,
        name: "Gym",
      },
      {
        id: 2,
        name: "Swimming Pool",
      },
      {
        id: 3,
        name: "Rooftop Decker",
      },
      {
        id: 4,
        name: "Concierge",
      },
      {
        id: 5,
        name: "Barbeque",
      },
    ],
    availability_status: {
      id: 1,
      name: "Available",
    },
    user: {
      email: "businesstest1@uplandhunt.com",
      last_name: "First",
      first_name: "Business",
      phone: "9098989898",
      business: {
        name: "First Test Business",
        phone: "90909090909",
        address: "Illupeju",
        country: "Nigeria",
        city: "Mushin",
        state: "Lagos",
        website: "www.companylogo.test",
        logo: "https://via.placeholder.com/150",
      },
      avatar: {
        url: "https://via.placeholder.com/150",
      },
    },
    images: [
      {
        id: 26,
        image_url: "https://via.placeholder.com/150",
      },
      {
        id: 1,
        image_url: "https://via.placeholder.com/150",
      },
    ],
    documents: [
      {
        id: 3,
        name: "Land Tenancy Agreement",
        image_url: "https://via.placeholder.com/150",
        status: "approved",
      },
      {
        id: 2,
        name: "Government Certificate",
        image_url: "https://via.placeholder.com/150",
        status: "rejected",
      },
      {
        id: 1,
        name: "Certificate of Occupancy",
        image_url: "https://via.placeholder.com/150",
        status: "pending",
      },
    ],
  },
  {
    id: 2,
    user_id: "8",
    name: "Seeder House Two",
    price: "9000000",
    city: "Lekki Phase II",
    country: "Nigeria",
    postal_code: "10092",
    address_line_one: "Lekki-Epe Expressway",
    address_line_two: "Lekki",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    area: "600",
    status: "active",
    trade_status: "completed",
    createdAt: "2021-08-21T00:06:14.105Z",
    property_category: {
      id: 2,
      name: "To Buy",
    },
    property_type: {
      id: 1,
      name: "Under Construction",
    },
    bed: {
      id: 4,
      bedroom: "4 Bedrooms",
    },
    bath: {
      id: 6,
      bathroom: "6 Bathrooms",
    },
    kitchens: {
      id: 3,
      kitchen: "3 Kitchens",
    },
    furnish: {
      id: 1,
      name: "Not Furnished",
    },
    deposit_type: {
      id: 3,
      name: "30%",
    },
    amenities: [
      {
        id: 1,
        name: "Gym",
      },
      {
        id: 2,
        name: "Swimming Pool",
      },
      {
        id: 3,
        name: "Rooftop Decker",
      },
      {
        id: 4,
        name: "Concierge",
      },
      {
        id: 5,
        name: "Barbeque",
      },
    ],
    availability_status: {
      id: 1,
      name: "Available",
    },
    user: {
      email: "businesstest1@uplandhunt.com",
      last_name: "First",
      first_name: "Business",
      phone: "9098989898",
      business: {
        name: "First Test Business",
        phone: "90909090909",
        address: "Illupeju",
        country: "Nigeria",
        city: "Mushin",
        state: "Lagos",
        website: "www.companylogo.test",
        logo: "https://via.placeholder.com/150",
      },
      avatar: {
        url: "https://via.placeholder.com/150",
      },
    },
    images: [
      {
        id: 27,
        image_url: "https://via.placeholder.com/150",
      },
      {
        id: 2,
        image_url: "https://via.placeholder.com/150",
      },
    ],
    documents: [
      {
        id: 6,
        name: "Land Tenancy Agreement",
        image_url: "https://via.placeholder.com/150",
        status: "approved",
      },
      {
        id: 5,
        name: "Government Certificate",
        image_url: "https://via.placeholder.com/150",
        status: "rejected",
      },
      {
        id: 4,
        name: "Certificate of Occupancy",
        image_url: "https://via.placeholder.com/150",
        status: "pending",
      },
    ],
  },
];
