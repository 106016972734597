import { SectionWrapper } from "../Layout";
import { Header } from "../molecules";
import aboutGroup from "../assets/aboutGroup.png";
import focus from "../assets/focus.svg";
import target from "../assets/target.svg";
import aboutShake from "../assets/about-shake.png"
import { services, values } from "../data/values";
import { Footer } from "../organisms";
import { SectionTitle } from "../atoms";

export const Section = ({ title, children, ourValues }) => (

  <SectionWrapper className='my-20'>
    <SectionTitle title={title} />
    <div className="flex flex-col-reverse md:flex-row items-center">
      <div className="flex-grow text-justify p-2 md:pr-7 md:mr-14">
        {ourValues ? <>{children}</> :
          <p className="font-normal text-base text-textGray space-y-4">
            {children}
          </p>
        }
      </div>

    </div>
  </SectionWrapper>
)


const About = () => {



  const ValueCards = ({ title, number, className, children }) => (
    <div style={{ borderColor: '#E2E4E8', }} className="border rounded-xl shadow-sm max-w-lg pt-9 pb-6 px-3 font-sofia font-normal text-base text-black ">
      <div className="space-x-4 flex items-center font-bold mb-7" >
        <div className={" bg-gray rounded-full w-10 h-10 flex justify-center items-center " + className}>{number}</div>
        <h6>{title}</h6>
      </div>
      <p className="text-center text-sm leading-7 text-textGray">{children}</p>
    </div>
  )

  return (
    <div>
      <Header />
      <SectionWrapper wider className='px-10 mb-16' >
        <div className="text-center xl:text-left">
          <div className="bg-primary h-2 w-12 ml-4 "></div>
        </div>

        <div className="flex flex-col lg:flex-row items-center">
          <div className="flex-grow text-justify p-2 md:pr-7 md:mr-32">
            <h3 className="text-3xl text-primary font-bold">About Us</h3>
            <div className="bg-yellow my-2 h-2 w-16 mb-10" />

            <p className="font-normal text-base mb-4 leading-7">
              We are a data-focused technology company offering holistic solutions in the real estate
              property market using artificial intelligence and human empathy.
              <br />
              At UplandsHunt we have built a nationwide property listing platform that bridges the gap
              between vendors and seekers while offering an on-demand experience for selling, buying,
              renting and financing with transparency and nigh seamless end-to-end service at zero
              upfront cost.
            </p>
            <p className="font-normal text-base mb-4 leading-7">
              We deliver the most efficient set of tools to simplify your rental or purchase experience from
              submitting your applications, custom offers, to e-signing rental or purchase agreements &amp;
              payments.
            </p>
            <p className="font-normal text-base mb-4">
            </p>

          </div>
          <div
            className="flex-none my-10  xl:w-full xl:-mr-20 px-20 sm:px-0 xl:max-w-xl lg:max-w-lg md:max-w-md "
            style={{ width: "596px" }}
          >
            <img src={aboutShake} alt="Hand-Shake" />
          </div>
        </div>

      </SectionWrapper>

      {/* horizontal line */}
      <div className='border-t-2 border-gray' />

      <SectionWrapper className='px-0 mb-10 pt-10' >

        <Section title='Re-Estate Re-Imagined'  >
          <p>
            For vendors, We make it easy to verify a prospective seeker’s credit and criminal history to
            guarantee the financial safety of the vendor. We have also integrated a bidding system that
            enables seekers to bid for properties directly with the vendor who decides on the best deal.
            We also provide unparalleled property insurance for owners against certain factors, still at
            zero cost
          </p>
          <p>
            For Seekers, we make it easy to access complete property details, photos and
            neighbourhood info, so you know what to expect before your financial commitment. Using
            natural language processing. We make it possible to search for properties like you would ask
            a human.
          </p>
          <p>
            Our company is not confined by traditional real estate limitations when it comes to buying,
            selling, leasing or asset management. We are solving the problem of transaction friction
            experienced by both buyers and sellers caused by a low trust, opacity and poor
            record-keeping. We are constantly coming upon innovative ways to satisfy your needs. Our
            in-house knowledge combined with strategic partnerships allows us to promptly meet your
            expectations as we grow.
          </p>

          {/* <div
            className="flex-none my-10 md:my-10 m-auto w-8/12 md:w-full"
            style={{ width: "26rem" }}
          >
            <img src={aboutGroup} alt="aboutGroup" />
          </div> */}

        </Section>

        <Section title='Our Mission'>
          Our mission is to establish UplandsHunt as the #1 real estate platform in Nigeria by creating
          an automated, transparent, and equitable process for both vendors and seekers. With our
          robust inventory of land, houses, apartments, and commercial properties all around the
          country, we are uniquely positioned to empower Nigerians with the tools to make the best
          decision when it comes to real estate.
        </Section>

        <Section ourValues title='Our Values'>
          <p className="font-normal text-base mb-8 leading-7">
            UplandsHunt is a values-driven company committed to high standards of sincere and ethical
            business deeds, as well as compliance with relevant laws and regulations.
            At the core of our operations are three core values: transparency, innovation, and privacy.
            These guide how we operate as a team and how we interact with our partners and the
            community.
          </p>

          <div className=" grid grid-cols-1 md:grid-cols-2  gap-x-16 gap-y-5 ">

            <ValueCards title='Transparency' number={1} >
              We believe in information-sharing. We work hard to make sure that all of our internal and
              external processes are transparent—including what we're working on, what we've done in
              the past, and how that impacts our plans. We also believe in clear communication with each
              other—there's no room for ambiguity when you're working toward a common goal. We want
              everyone interested to be able to follow along with what we're up to.
            </ValueCards>
            <ValueCards title='Innovation' number={2} >
              We believe in trying new things. we recognize that the most exciting solutions come from
              new ideas and fresh perspectives. Whether it's exploring new products or playing around
              with new methods for approaching business problems, we value creativity and ingenuity
              here at UplandsHunt. We strive to be at the forefront of our industry by continually innovating
              and looking for ways to grow not just our company but also those around us.
            </ValueCards>
            <ValueCards title='Privacy' className='self-center ' number={3} >
              We believe in the importance of your personal information staying just that: your information.
              We work hard to make sure that your data is secure and that it stays secure. We also never
              share any identifying information about you without your permission. That’s why we’re
              making public our fair housing policies, corporate governance and business practise
              guidelines. No matter how much data you want to share or how much you don't, we make
              sure that your information is safe by implementing a variety of safeguards across all
              systems
            </ValueCards>
            {/* <ValueCards title='Solutions' number={4} >
              Our clients depend on our abiliy to draw on years of experience in the local market, our professionals know their communities
              and the property marjet inside out amd provide knowledgeable, creative solutions to all their clients real estate needs.
            </ValueCards> */}
          </div>
        </Section>

      </SectionWrapper>


      <SectionWrapper className=" md:flex text-center m-auto mx-10  w-11/12 mb-20 md:pl-20 py-20">
        <div style={{ minWidth: '500px' }} className="font-semibold w-1-3  text-primary text-3xl pb-10 md:pb-20 pt-8">
          <SectionTitle title='Our Services' />
        </div>
        <div className="md:flex flex-wrap justify-between w-3/4 mr-10   ">
          {services.map(({ id, title, image }) => {
            return (
              <div className="mb-10 flex space-x-5 items-center capitalize " key={id}>
                <div className="flex justify-center items-center">
                  <img src={image} alt="icon" />
                </div>
                <p className="font-bold text-primary text-sm ">
                  {title}
                </p>
              </div>
            );
          })}
        </div>
      </SectionWrapper>

      <Footer />
    </div>
  );
};

export { About };
