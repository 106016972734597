import axios from "axios";

const token = JSON.parse(localStorage.getItem("currentUser"));
// const baseURL = "http://178.128.153.214/api/v1/";
const baseURL = "https://www.uplandshunt.ng/api/v1";

export const axiosInstance = axios.create({ baseURL });

export const axiosWithAuth = (config) => {
  return axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
      ...config,
    },
  });
}
