import { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { loginUser, useAuthState, useAuthDispatch } from "../Context";
import { Button, Input, InputTwo } from "../atoms";
import Intro from "../templates/Intro";
import eyeClosed from "../assets/eyeClosed.svg";
import eyeOpened from "../assets/eyeOpen.svg";
import gmail from "../assets/gmail.svg";
import { AuthLayout } from "../Layout";

const Login = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .min(7, "Password must be at least 7 characters")
      .required("Password is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;

  const dispatch = useAuthDispatch();
  const { loading } = useAuthState();
  const userPath = sessionStorage.getItem("propertyPath");

  const handleLogin = async (data) => {
    let email = data.email;
    let password = data.password;
    try {
      let response = await loginUser(dispatch, { email, password });
      if (response.status === false) {
        reset();
        const errorMessage = response.data.errors.error[0];
        toast.error(errorMessage);
      } else {
        console.log(response);
        if (response.data.user.role.name === "Admin") {
          toast.error('Cannot sign in with a Super Admin account');
          localStorage.removeItem('currentUser')

          localStorage.removeItem('auth')

        } else {
          toast.success(response.message);
          window.location.replace(userPath || "/dashboard");
        }
      }
    } catch (error) { }
  };
  return (
    <AuthLayout>
      <Intro
        title="Welcome Back"
        subtitle="Provide your email and password to login to the system"
      />
      <form
        className="mt-12 m-auto w-11/12 md:w-8/12"
        onSubmit={handleSubmit(handleLogin)}
      >
        <Input
          type="email"
          placeholder="example@example.com"
          label="Email"
          error={errors.email?.message}
          register={register("email")}
        />
        <div className="w-full flex flex-row-reverse items-center relative">
          <i className="absolute cursor-pointer" onClick={togglePasswordVisibility}>
            <img
              src={passwordShown ? eyeOpened : eyeClosed}
              alt="visible"
              className=" w-6 visibility mb-9 leftIcon"
            />
          </i>
          <Input
            placeholder="xxxxxxx"
            type={passwordShown ? "text" : "password"}
            label="Password"
            error={errors.password?.message}
            autocomplete="on"
            register={register("password")}
          />
        </div>
        <Link to="/forgotpassword" className="">
          <p className="underline w-max ml-auto text-dimGrey hover:no-underline hover:text-lightBlack text-sm text-right mt-2">
            Forgot password ? Click <span> here </span>to reset the password
          </p>
        </Link>
        <Button loading={loading} type={'submit'} className='text-white ' buttonText="Log in" />
      </form>

      <Link to="/register">
        <p className="text-center font-normal text-sm">
          Don't have an account?
          <span className="text-primary font-bold mx-1"> Sign Up</span>
        </p>
      </Link>

      <div className="m-auto mb-24 lg:mb-0 w-11/12 md:w-8/12">
        <p className="text-primary in-between font-bold font-sm my-12">or</p>

        {/* <div className="flex justify-center rounded-md border border-primary p-3">
          <img src={gmail} alt="gmail" className="mr-4" />
          <p className="font-semibold text-base text-center cursor-pointer">
            Log in with Google
          </p>
        </div> */}
      </div>
    </AuthLayout>
  );
};

export { Login };
