import { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { v4 as uuidv4 } from "uuid";

import placeholder from "../assets/placeholder.png";
import rightArrow from "../assets/right.svg";
import leftArrow from "../assets/left.svg";
import locationIcon from "../assets/Location-icon.svg";
import dial from '../assets/dial.svg'
import bath from '../assets/bath.svg'
import bed from '../assets/bed.svg'
import carpark from '../assets/car.svg'
import kitchen from '../assets/kitchen.svg'
import horizontalLine from '../assets/horizontal-line.svg'
import verticalLine from '../assets/vertical-line.svg'
import propertyImage from '../assets/property-image.png'

import { SectionTitle } from "../atoms";
import { axiosInstance } from "../Auth/Axios";
import { Link } from "react-router-dom";
import DashboardLoader from "../templates/DashboardLoader";
import LandingModal from "../templates/LandingModal";
import { log } from "@craco/craco/lib/logger";

/* eslint-disable no-unused-expressions */


export const Property = ({ property }) => {

  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const getBadge = () => {
    switch (property?.property_category.name) {
      case 'To Buy': return { title: 'To Sale', color: '#0D7FE9' }
      case 'To Rent': return { title: 'To Rent', color: '#FF637B' }
      case 'Under Construction': return { title: 'Under Construction', color: '#54436B' }
      default: return 'For Sale'
    }
  }
  return (
    <div style={{ width: 319, height: 547 }} className='my-5' key={uuidv4()} >
      <div className="mr-8 mb-8  p-2 shadow-lg bg-white rounded-md">
        <section className="w-full relative rounded-md h-52 p-2 shadow object-cover">

          <div style={{ backgroundColor: getBadge().color }} className=" z-10 absolute top-3 right-3 w-max bg-red-500 rounded text-white">
            <p className="font-bold   py-1 px-4 text-xs text-white">
              {/* For Sale */}
              {getBadge().title}
            </p>
          </div>
          <img
            src={(property?.images?.length > 0)
              && (!property?.images[0]?.image_url?.includes('amazonaws'))
              ? property?.images[0]?.image_url : propertyImage}
            alt="location"
            className="object-cover  rounded-md w-full h-full "
          />
        </section>
        <section className="mt p-2">
          <div className="flex items-center max-h-5 h-5 my-4 mt-7">
            <img
              src={locationIcon}
              alt="location"
              className="mr-2"
            />
            <p className="font-normal text-sm text-ash my-2">
              {property?.address_line_one}
            </p>
          </div>
          <div className="w-full mb-3">
            <p className="font-semibold text-sm">{property?.name}</p>
          </div>
          <div>
            <p className="font-bold text-primary tracking-wide text-2xl ">
              ₦ {numberWithCommas(property?.price)}
            </p>
          </div>
          <PropertyIcons kitchenNo={property?.kitchens?.kitchen} bedroomNo={property?.bed?.bedroom} bathroomNo={property?.bath?.bathroom} extras={property?.amenities?.length} />
          <div className="w-full flex justify-between items-center mb-2">

            <Link
              to={`/propertydetails/${property?.id}`}
              className="bg-primary flex w-full h-12 justify-center text-yellow font-normal
                          rounded-md items-center"
            >
              <img src={dial} alt="dial" className="pr-2" /> Contact
            </Link>
          </div>
        </section>
      </div>
    </div>
  )
}

const PropertyIcons = ({ bathroomNo, bedroomNo, kitchenNo, extras }) => {
  return (
    <div className="font-sophia mt-5 my-8">

      <div className="space-y-5 px-2">
        <img src={horizontalLine} alt="horizontal-line" />

        <div className="flex justify-between font-normal text-center text-primary ">
          <div style={{ fontSize: 9 }} className="flex flex-col justify-between items-center">
            <img src={bath} alt="bath" />
            {/* {bathroomNo} Bathroom{bathroomNo > 1 && 's'} */}
            {bathroomNo}
          </div>

          <img src={verticalLine} alt="Vertical Line" />

          <div style={{ fontSize: 9 }} className="flex flex-col justify-between items-center">
            <img src={bed} alt="bedroom number" />
            {/* {bedroomNo} Bedroom{bedroomNo > 1 && 's'} */}
            {bedroomNo}
          </div>

          <img src={verticalLine} alt="Vertical Line" />

          <div style={{ fontSize: 9 }} className="flex flex-col justify-between items-center">
            <img src={kitchen} alt="kitchen" />
            {/* {kitchenNo} Kitchen{kitchenNo > 1 && 's'} */}
            {kitchenNo}
          </div>

          <img src={verticalLine} alt="Vertical Line" />

          <div style={{ fontSize: 9 }} className="flex flex-col justify-between items-center">
            <img src={carpark} alt="car park" className="pt-1" />
            {extras} extra{extras > 1 && 's'}
          </div>
        </div>
      </div>

    </div>
  )
}


const PropertySlider = ({ title, category }) => {
  const [showModal, setShowModal] = useState(true);
  const sliderRef = useRef();
  const handlePrev = (ref) => {
    ref.current.slickPrev();
  };

  const handleNext = (ref) => {
    ref.current.slickNext();
  };
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          infinite: true,
        },
      },
    ],
  };

  const [topProperties, setTopProperties] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTopProperties = () => {
    axiosInstance
      .get(`property/${category}/all`)
      .then(function (response) {
        // handle success
        const topProperties = response.data.data;
        setTopProperties(topProperties);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
      })
      .then(function () {
        // always executed
      });
  };



  useEffect(() => {
    getTopProperties();
  }, []);

  return (
    <>
      {loading ? (
        <DashboardLoader />
      ) : (
        <div className="my-16">
          <SectionTitle title={title} moreLink='/all-properties' />
          <Slider ref={sliderRef} {...settings}>
            {topProperties.map((property, index) => (
              <Property key={index} property={property} />
            ))}
          </Slider>
          {/* <div className="mt-10 flex justify-center items-center ">
            <section>
              <img
                src={leftArrow}
                alt="left-arrow"
                onClick={() => handlePrev(sliderRef)}
                className="cursor-pointer w-4 slider-arrow mr-2"
              />
            </section>
            <p>
              Previous <span className="text-primary">Next</span>
            </p>
            <section>
              <img
                src={rightArrow}
                alt="right-arrow"
                onClick={() => handleNext(sliderRef)}
                className="cursor-pointer w-4 slider-arrow"
              />
            </section>
           
          </div> */}
          {/* <LandingModal /> */}
        </div>
      )}
    </>
  );
};

export { PropertySlider };
