import { SectionWrapper } from "../Layout/SectionWrapper";
import { Header } from "../molecules";
import {
  Hero,
  PropertySlider,
  Explore,
  HowToSell,
  Subscription,
  DownloadApp,
  Footer,
} from "../organisms";
import mymsgsBg from '../assets/mymsgs-section.png'
import WhyUpland from "../templates/WhyUpland";
import WhyUplandHunt from "../templates/WhyUplandHunt";
import HowToBuy from "./HowToBuy";
import { useEffect } from "react";

const MyMsgSection = () => (
  <div
    style={{ backgroundImage: `url(${mymsgsBg})` }}
    className="font-inter h-32 flex items-center bg-no-repeat bg-cover rounded my-10 text-2xl pl-10 leading-7 text-white font-bold"
  >
    <p className="">
      Send <br />
      <span className="font-normal">Messages for free only on</span><br />
      Mymsgs.com.ng
    </p>
  </div>
)

const Landing = () => {
  useEffect(() => () => localStorage.setItem("UPLAND_OLD_USER", true), [])

  return (
    <>
      <Header />
      <main style={{ background: '#F8F8F8' }} >
        <Hero />
        <SectionWrapper>
          <div className="mt-14">
            <PropertySlider title="Top Property" category="top" />
            <PropertySlider title="Featured Property for Sale" category="boosted" />
            <MyMsgSection />
            <Explore title="Explore Nigeria" />
          </div >
        </SectionWrapper>

        <section className="mt-20">
          <WhyUplandHunt />
          <HowToBuy />
          <Subscription />
          {/* <DownloadApp /> */}


        </section>
        <Footer />
      </main>
    </>
  );
};

export { Landing };
