import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const DashboardSectionTitle = ({
  text,
  buttonUrl,
  buttonText,
  showButton = true,
  error,
  numberOfProperty,
  response,
}) => {
  const location = useHistory();

  const addNewListing = () => {
    window.scrollTo(0, 0);
    // if (error) {
    //   location.push(`/dashboard/subscription`);
    //   toast.error("You must have an active subscription to add listings");
    // } else {
    //   location.push(buttonUrl);
    // }
    if (error) {
      location.push(`/dashboard/subscription/upgradeSub`);
      toast.error("You must have an active subscription to add listings");
    } else if (response?.length > numberOfProperty) {
      location.push(`/dashboard/subscription/upgradeSub`);
      toast.error("You have exceeded the number of properties");
    } else {
      location.push(buttonUrl);
    }
  };

  return (
    <div className="flex w-11/12  justify-between items-center mx-auto  my-4">
      <div className="w-full flex-1 font-circular">
        <p className="text-sm md:text-2xl ">{text}</p>
      </div>
      {showButton && (
        <Link to={buttonUrl} className=" flex items-center justify-center">
          <button
            className="rounded-md py-2 px-4 md:py-4 align-middle text-yellow text-xs md:text-normal bg-primary md:font-semibold"
            onClick={addNewListing}
          >
            {buttonText}
          </button>
        </Link>
      )}
    </div>
  );
};

export { DashboardSectionTitle };
