import { useState } from "react";
import { Link } from "react-router-dom";

import harmburger from "../assets/harmburger.svg";
import logoTwo from "../assets/logoTwo.svg";
import closeButton from "../assets/closeButton.svg";
import { SectionWrapper } from "../Layout";
import { DropdownMenu } from "../pages";
import navArrow from '../assets/nav-arrow.svg'
import ProfileDefaultImage from "../atoms/ProfileDefaultImage";

const Header = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const user = JSON.parse(localStorage.getItem('auth'))?.user
  const toggleShowDropDown = () => {
    setShowDropDown(!showDropDown);
  };
  return (
    <SectionWrapper>
      <header className="flex justify-between items-center mb-4">
        <div className="md:hidden w-full flex justify-between cursor-pointer">
          <img src={logoTwo} alt="logo" className="w-24 h-9" />
          {showDropDown ? (
            <img
              src={closeButton}
              alt="closeButton"
              onClick={toggleShowDropDown}
            />
          ) : (
            <img
              src={harmburger}
              alt="harmburger"
              className="cursor-pointer"
              onClick={toggleShowDropDown}
            />
          )}
        </div>
        <Link to='/' className="hidden md:block">
          <img src={logoTwo} alt="logo" className="w-36 h-14" />
        </Link >

        <nav className="hidden md:block">
          <ul className="flex justify-between items-center list-none text-primary font-semibold text-sm">
            <Link to="/legal/news">
              <li className="mr-6">Legal</li>
            </Link>
            <Link to="/about">
              <li className="mr-6">About Us</li>
            </Link>
            <Link to="/contactus">
              <li className="mr-6">Contact Us</li>
            </Link>
            <Link to="/all-properties">
              <li className="mr-6">All Properties</li>
            </Link>
          </ul>
        </nav>

        <nav className="hidden md:block">
          {
            localStorage.getItem('currentUser') && user ?

              <Link to='/dashboard'>
                <div
                  className="flex text-primary items-center space-x-3 "
                >
                  {user.avatar
                    ? <img
                      src={user?.avatar}
                      alt={user?.first_name || "avatar"}
                      className="w-10 h-10 border-2 border-primary object-cover rounded-full"
                    />
                    : <ProfileDefaultImage firstName={user?.first_name} lastName={user?.lastName} />
                  }
                  <p className="font-bold capitalize text-sm mr-4">
                    {user.first_name} <br /> {user.last_name}
                  </p>
                </div>
              </Link>
              :
              <ul className="flex justify-between items-center list-none text-primary font-semibold  text-sm">
                <Link to="/login">
                  <button className="text-primary w-max rounded-md py-2 px-4  mr-6 focus:outline-none">
                    Sign In
                  </button>
                </Link>
                <Link to="/register">
                  <button className="bg-primary text-xs  text-yellow flex w-28 h-11  items-center justify-center  rounded-md py-2 px-4 mr-6 focus:outline-none">
                    Register
                    <img src={navArrow} alt="nav arrow" className="ml-1" />
                  </button>
                </Link>
              </ul>
          }
        </nav>
      </header>
      <div className="">{showDropDown && <DropdownMenu />}</div>
    </SectionWrapper>
  );
};

export { Header };
