import { useState } from "react";
import { useHistory } from "react-router-dom";
import { axiosWithAuth } from "../Auth/Axios";
import SimpleDropZone from "../templates/SimpleDropZone";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { DashboardSectionTitle } from "../atoms";

const ImageUpload = () => {
  const location = useHistory();
  let { id } = useParams();

  const handleChangeStatus = ({ meta }, status) => { };

  const [loading, setLoading] = useState(false);

  const handleSubmit = (files) => {
    const fileNames = files.map((file) => file.file);

    const formdata = new FormData();
    for (const image of fileNames) {
      formdata.append("files", image);
    }
    // formdata.append("files", fileNames);
    setLoading(true);
    axiosWithAuth()
      .post(`property/${id}/images`, formdata)
      .then((response) => {
        const successMessage = response.data.data;
        toast.success(successMessage);
        setLoading(false);
        location.push(`/dashboard/listings/videoupload/${id}`);
      })
      .catch(function (error) {
        if (error.response) {
          setLoading(false);
          const errorMessage = error.response.data.data;
          toast.error(errorMessage);
        }
        // handle error
      });
  };

  return (
    <div className="m-auto h-full w-11/12  md:ml-10 mt-6 mb-20">
      <div className="w-full ">
        <DashboardSectionTitle text='Upload Image' showButton={false} />
      </div>
      <p className="font-bold text-textGray text-base mt-14">Images Of Properties</p>
      <div className=" w-11/12  ">
        <SimpleDropZone
          maximumFiles="7"
          minimumFiles="5"
          fileType="image/*,png/*,svg/*,jpeg/*,jpg/*"
          handleSubmit={handleSubmit}
          handleChangeStatus={handleChangeStatus}
          buttonText="Continue"
          loading={loading}
        />

      </div>
    </div>
  );
};

export { ImageUpload };
