import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { axiosInstance } from "../Auth/Axios";
import { Button, Input, InputTwo, SectionTitle } from "../atoms";
import { SectionWrapper } from "../Layout";
import { Header } from "../molecules";
import { Footer } from "../organisms";


// const InputBordeless = ({ className, type, name, id, error, placeholder, label, readOnly, register, inputProps, ...props }) => {
//   return (
//     <div className="mb-6">

//       <input
//         type={type}
//         name={name}
//         id={id}
//         {...register}
//         placeholder={label || placeholder}
//         {...props}
//         className="w-full border-b border-lightAsh transition-colors duration-500 focus:border-black focus:outline-none px-2 py-2 mt-1"
//       />
//       <span>
//         <p className="text-red-500 text-sm"> {error}</p>
//       </span>
//     </div>
//   )
// }

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
    phone_number: Yup.number()
      .typeError("Phone Number must be a number")
      .required("Phone Number is required"),
    message: Yup.string()
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;

  const submitMessage = (data) => {
    setLoading(true);
    axiosInstance
      .post("contact", data)
      .then(function (response) {
        reset();
        const successMessage = response.data.data;
        setResponse(successMessage);
        toast.success(successMessage);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          reset();
          const errorMessage = error.response.data.data;
          toast.error(errorMessage);
          setLoading(false);
        }
      });
  };

  return (
    <div>
      <Header />
      <SectionWrapper className='py-20 md:px-36 '>
        <div className="md:fle mt-14">
          <section>
            <SectionTitle title="Contact Form" />
            {/* <p className="my-6 font-semibold text-2xl text-lightBlack">
              We are just few calls away.
            </p> */}
            <p className="font-norma text-lightAsh font-semibold text-lg ">
              Fill the form below. we will get back to you as soon as
              possible.
            </p>
          </section>
          <form className="mt-14 md:ml-14" onSubmit={handleSubmit(submitMessage)}>

            <div className="md:flex w-full my-4 ">
              <div className="md:w-1/2 md:mr-20">
                <Input
                  type="email"
                  label="Email Address"
                  register={register("email")}
                  error={errors.email?.message}
                />
              </div>
              <div className="md:w-1/2">
                <Input
                  type="number"
                  label="Contact Number"
                  error={errors.phone_number?.message}
                  register={register("phone_number")}
                />
              </div>
            </div>

            <textarea
              rows="4"
              cols="94"
              placeholder="Additional Message"
              register={register("message")}
              error={errors.message?.message}
              className="bg-gray rounded-md p-5 px-6 border w-full leading-7 focus:outline-none focus:border-black border-lightAsh mt-4"
            />
            <div className="w-1/4 mx-auto">
              <Button buttonText="Submit" loading={loading} />
            </div>
          </form>
        </div>
      </SectionWrapper >
      <Footer />
    </div >
  );
};





// const ContactOld = () => (
//   <div>
//     <Header />
//     <SectionWrapper>
//       <div className="md:flex mt-14">
//         <section>
//           <SectionTitle title="Get In Touch" />
//           <p className="my-6 font-semibold text-2xl text-lightBlack">
//             We are just few calls away.
//           </p>
//           <p className="font-normal text-lg max-w-lg mr-10">
//             Kindly fill the form below. we will get back to you as soon as
//             possible
//           </p>
//         </section>
//         <form className="mt-14" onSubmit={handleSubmit(submitMessage)}>
//           <div className="flex w-full">
//             <div className="w-1/2 mr-8">
//               <InputTwo
//                 placeholder="example@example.com"
//                 type="email"
//                 label="Email Address"
//                 register={register("email")}
//                 error={errors.email?.message}
//               />
//             </div>
//             <div className="w-1/2">
//               <InputTwo
//                 placeholder="00000000"
//                 type="text"
//                 label="Contact Number"
//                 register={register("phone_number")}
//                 error={errors.phone_number?.message}
//               />
//             </div>
//           </div>
//           {/* <label
//               htmlFor="Message"
//               className="text-lg font-semibold text-left pb-4"
//             >
//               Message
//             </label> */}
//           <textarea
//             rows="4"
//             cols="74"
//             placeholder="Message"
//             register={register("message")}
//             error={errors.message?.message}
//             className="bg-gray rounded-md p-2 border border-lightAsh mt-4"
//           ></textarea>
//           <div className="w-1/2">
//             <Button buttonText="Submit" loading={loading} />
//           </div>
//         </form>
//       </div>
//     </SectionWrapper>
//     <Footer />
//   </div>
// )





export { ContactUs };
