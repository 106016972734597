import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { axiosInstance } from "../Auth/Axios";
import logo from "../assets/userDashboard/logo.svg";
import { getInTouch, company, support } from "../data/FooterItems";
import { SectionWrapper } from "../Layout";
import whatsappFooter from "../assets/whatsappFooter.svg";
import linkedin from "../assets/linkedin.svg";
import facebook from "../assets/facebook.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;

  const subscribe = (data) => {
    setLoading(true);
    axiosInstance
      .post("contact/mail-list", data)
      .then(function (response) {
        reset();
        const successMessage = response.data.data;
        setResponse(successMessage);
        toast.success(successMessage);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          reset();
          const errorMessage = error.response.data.data;
          setError(errorMessage);
          toast.error(errorMessage);
          setLoading(false);
        }
      });
  };

  return (
    <div className="md:mt-10 bg-primary text-white pt-4 text-base font-normal ">
      <SectionWrapper className='md:flex md:px-16 px-5 md:py-24 ' >
        <div className="w-4/12 mx-auto">
          <img src={logo} alt="logo" className="md:my-32 my-20 md:mt-0" />
        </div>
        <div className="md:flex flex-1 justify-between md:space-x-16 m-auto pb-9 w-11/12">
          <section style={{ flex: 2 }} className=" whitespace-pre-line">
            <h4 className="font-bold text-lg text-yellow md:pb-6 text-center md:text-left my-8 md:my-0 capitalize">
              Get in Touch
            </h4>
            <h4 className="font-normal text-lg md:mb-3 text-center md:text-left cursor-pointer  hover:text-yellow active:text-yellow-200 active:transition  ">
              Contact Us
            </h4>
            {getInTouch.map(({ item, image, link }, index) => {
              return (
                <div className=" pb-4 cursor-pointer hover:text-yellow active:text-yellow-200 active:transition " key={item}>
                  <div className="flex justify-center md:justify-start items-center">
                    <img src={image} alt={item} className={`mr-3 ${index === 1 && '-mr-1 pr-1 md:pr-0 md:mr-3'} `} />
                    <a href={link || '#'} className="font-normal text-lg text-center md:text-left">{item}</a>
                  </div>
                </div>
              );
            })}
          </section>
          <section className="flex-1">
            <h4 className="font-bold text-yellow text-lg md:pb-6 text-center md:text-left my-8 md:my-0 capitalize">
              Company
            </h4>
            <div className="space-y-10">

              {company.map(({ item, path }) => {
                return (
                  <Link
                    to={path}
                    className=""
                    key={item}
                    onClick={window.scrollTo(0, 0)}
                  >
                    <p className="font-normal text-center md:text-left pb-3 text-lg hover:text-yellow active:text-yellow-200 active:transition ">
                      {item}
                    </p>
                  </Link>
                );
              })}
            </div>
          </section>
          <section className="flex-1">
            <h4 className="font-bold text-yellow text-lg md:pb-6 text-center md:text-left my-8 md:my-0 capitalize">
              Support
            </h4>
            {support.map(({ item, path }) => {
              return (
                <Link
                  to={path}
                  className="mb-3"
                  key={item}
                  onClick={window.scrollTo(0, 0)}
                >
                  <p className="font-normal text-center md:text-left pb-3 text-lg hover:text-yellow active:text-yellow-200 active:transition ">
                    {item}
                  </p>
                </Link>
              );
            })}
          </section>
          {/* <div className="">
            <p className="font-bold text-lg pb-6 uppercase my-8 md:my-0 text-center md:text-left">
              subscribe to our newsletter
            </p>
            <form
              className="flex items-center mb-4"
              onSubmit={handleSubmit(subscribe)}
            >
              <div className="flex flex-col items-center ">
                <input
                  type="text"
                  placeholder="Email Address"
                  register={register("email")}
                  className="p-2 font-semibold text-lg rounded-r-none rounded-md text-black focus:outline-none"
                />
                <span>
                  <p className="text-red-500 text-sm">
                    {errors.email?.message}
                  </p>
                </span>
              </div>
              <button className="bg-primary font-semibold text-lg px-4 py-2 focus:outline-none">
                Subscribe
              </button>
            </form>
            <div className="my-6 md:my-0 flex justify-center md:justify-start">
              <img src={whatsappFooter} alt="whatsappFooter" className="mr-6" />
              <img src={facebook} alt="facebook" className="mr-6" />
              <img src={linkedin} alt="linkedin" />
            </div>
          </div> */}
        </div>
      </SectionWrapper >

      <p className="md:text-indigo-100 text-yellow pl-5 bg-primary mix-blend-multiply text-sm font-bold md:px-40 py-6">
        &copy; <span  >2021 Uplandshunt. All Right Reserved</span>
      </p>
    </div >
  );
};

export { Footer };
