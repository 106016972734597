import { useState, useEffect } from "react";

import placeholder from "../assets/placeholder.png";
import { PurchaseLayout } from "../Layout";
import DashboardLoader from "../templates/DashboardLoader";
import { axiosWithAuth } from "../Auth/Axios";
import { PropertyCardThree } from "../organisms";

const PropertySold = () => {
  const [loading, setLoading] = useState(false);
  const [propertySold, setPropertySold] = useState([]);

  const getPropertySold = () => {
    setLoading(true);
    axiosWithAuth()
      .get("/transaction/property?tag=sold")
      .then((response) => {
        const results = response.data.data;
        setPropertySold(results);
        setLoading(false);
      });
  };

  useEffect(() => {
    getPropertySold();
  }, []);

  return (
    <PurchaseLayout>
      <>
        {loading ? (
          <DashboardLoader />
        ) : propertySold.length === 0 ? (
          <p className="text-center font-bold text-3xl py-6">
            No property sold
          </p>
        ) : (
          <div className="ml-4">
            {propertySold.map(
              ({ id, price, name, property, address_line_one }) => {
                return (
                  <PropertyCardThree
                    id={id}
                    price={price}
                    name={name}
                    image_url={property?.images[0]?.image_url || placeholder}
                    // image_url={placeholder || property.images[0].image_url}
                    city={address_line_one}
                    showPaymentHistory={true}
                    showDocument={false}
                  // amenities={property.amenities.map(({ id, name }) => {
                  //   return (
                  //     <div key={id} className="flex">
                  //       <p>{name}</p>
                  //     </div>
                  //   );
                  // })}
                  />
                );
              }
            )}
          </div>
        )}
      </>
    </PurchaseLayout>
  );
};

export { PropertySold };
