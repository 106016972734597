import React from "react";
import { Link } from "react-router-dom";

const SectionTitle = ({ title, more, noLine, moreLink }) => {
  return (
    <div className="my-7">
      <div className="flex justify-between">
        <h3 className="text-primary font-bold text-2xl">{title}</h3>
        {(more || moreLink) &&
          <Link to={moreLink || '#'}>
            <p role={'button'} className="text-primary px-10 pt-2 cursor-pointer w-max font-semibold text-base" >
              See More
            </p>
          </Link>
        }
      </div>
      {!noLine && <div className="bg-yellow my-2 h-2 w-16" />}
    </div>
  );
};

export { SectionTitle };
