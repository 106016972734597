import Background from "../templates/Background";
import { Link } from 'react-router-dom'

const AuthLayout = ({ children }) => {
  return (
    <div className="flex h-screen bg-primary">
      <div className="hidden md:block " style={{ flex: "1" }}>
        <Background />
      </div>
      <section className="mx-auto font-circular bg-white md:rounded-tl-3xl h-screen overflow-y-auto md:w-10/12 pt-16" style={{ flex: "3" }}>
        <div className=" text-textGray hover:text-black w-max  pl-10 -mt-10 mb-10">
          <Link to='/'>
            {'< '} Home
          </Link>
        </div>
        {children}
      </section>
    </div>
  );
};

export { AuthLayout };
