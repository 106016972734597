import cell from "../assets/cell.svg";
import addy from "../assets/addy.svg";
import email from "../assets/email.svg";
import dashboard from "../assets/userDashboard/dashboard.svg";
import listing from "../assets/userDashboard/listings.svg";
import subscription from "../assets/userDashboard/subscription.svg";
import profile from "../assets/userDashboard/profile.svg";
import savedProperty from "../assets/userDashboard/savedProperty.svg";
import purchase from "../assets/userDashboard/purchase.svg";
import request from "../assets/userDashboard/request.svg";
import transaction from "../assets/userDashboard/transaction.svg";

export const getInTouch = [
  {
    id: "2",
    item: "+447846305105",
    image: cell,
    link: "tel:+447846305105"
  },
  {
    id: "3",
    item: "Lagos office : 77 Opebi Road ikeja \n Abuja Office: Suite T11, Febson Mall, Zone 4, Wuse, Abuja.",
    image: addy,
  },
  {
    id: "4",
    item: "info@uplandshunt.ng \n Contact@uplandshunt.ng",
    image: email,
    link: "mailto:info@uplandshunt.ng"
  },
];

export const company = [
  {
    id: "1",
    item: "About",
    path: "/about",
  },
  {
    id: "2",
    item: "Blog",
    path: "/legal/blog",
  },
  {
    id: "3",
    item: "News",
    path: "/legal/news",
  },
  {
    id: "4",
    item: "Regulations",
    path: "/legal/regulations",
  },
];

export const support = [
  {
    id: "1",
    item: "Terms & Conditions",
    path: "/terms",
  },
  {
    id: "2",
    item: "Legal",
    path: "/legal/news",
  },
  {
    id: "3",
    item: "Privacy policy",
    path: "/privacypolicy",
  },

];



export const blogNavItems = [
  {
    id: "1",
    item: "News",
    url: "/legal/news",
  },
  {
    id: "2",
    item: "Regulations",
    url: "/legal/regulations",
  },
  {
    id: "3",
    item: "Blog",
    url: "/legal/blog",
  },
];

export const userDashboard = [
  {
    id: "1",
    navItem: "Dashboard",
    image: dashboard,
    link: "/dashboard",
  },
  {
    id: "2",
    navItem: "My Listing",
    image: listing,
    link: "/dashboard/listings",
  },
  {
    id: "3",
    navItem: "My Subscription",
    image: subscription,
    link: "/dashboard/subscription",
  },
  {
    id: "4",
    navItem: "My Profile",
    image: profile,
    link: "/dashboard/profile",
  },
  {
    id: "5",
    navItem: "Saved Property",
    image: savedProperty,
    link: "/dashboard/savedproperty",
  },
  {
    id: "6",
    navItem: "Property Request",
    image: request,
    link: "/dashboard/request/propertyrequest",
  },
  {
    id: "7",
    navItem: "My Purchase",
    image: purchase,
    link: "/dashboard/purchase/propertypurchased",
  },
  {
    id: "8",
    navItem: "My Transactions",
    image: transaction,
    link: "/dashboard/transactions",
  },
];
