import { useEffect, useState } from "react";
import { useAuthState } from "../Context";

// import search from "../assets/search.svg";
// import active from "../assets/userDashboard/active.svg";
import closeButton from "../assets/closeButton.svg";
import DashboardLoader from "../templates/DashboardLoader";
import notify from "../assets/userDashboard/Notify.png";
import harmburger from "../assets/harmburger.svg";
import { Sidebar } from "../pages";
import { Link } from "react-router-dom";
import { axiosWithAuth } from "../Auth/Axios";

const DashboardSidebarFooter = () => {
  const userDetails = useAuthState();
  console.log(userDetails?.user?.avatar);

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState([])
  const [error, setError] = useState(null)
  const [notifications] = useState("0");
  const [showNotifications, setShowNotifications] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleShowSideBar = () => {
    setShowSidebar(!showSidebar);
  };

  const getUserProfile = () => {
    setLoading(true);
    axiosWithAuth()
      .get("auth/me")
      .then(function (response) {
        const profile = response.data.data;
        setResponse(profile);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          setError(error.response.data.data);
          setLoading(false);
        }
        // handle error
        setError(error.status);
      });
  };

  // const getNotifications = () => {
  //   setLoading(true);
  //   axiosWithAuth()
  //     .get("/user/dashboard/notification_count")
  //     .then((response) => {
  //       const result = response.data;
  //       setNotifications(result);
  //       setLoading(false);
  //     });
  // };

  // const fetchNotifications = () => {
  //   setLoading(true);
  //   axiosWithAuth()
  //     .get("/user/dashboard/notifications")
  //     .then((response) => {
  //       const result = response.data;
  //       setNotifications(result);
  //       setLoading(false);
  //     });
  // };

  const showUserNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  useEffect(() => {
    // getNotifications();
    // fetchNotifications();
  }, []);
  return (
    <>
      {loading ? (
        <DashboardLoader />
      ) : (
        <>
          <div
            className="flex items-center w-full p-2  bg-white"
            style={{ boxShadow: "0px 0px 4px 0px #eea7a740" }}
          >


            <div className="flex-1 flex justify-between mx-5  ">
              {/* <div className="flex w-full">
        <input
        type="text"
        placeholder="Lagos"
        className="rounded-3xl rounded-r-none p-4 text-sm font-normal border border-lightAsh"
        style={{ width: "50%" }}
        />
        <img src={search} alt="search" />
      </div> */}
              <Link to='/dashboard/profile'>
                <div
                  className="flex items-center space-x-3 "
                  onClick={showUserNotifications}
                >
                  <img
                    src={userDetails?.user?.avatar}
                    alt="avatar"
                    className="w-10 h-10 border-2 border-primary object-cover rounded-full"
                  />
                  <p className="font-bold capitalize text-sm mr-4">
                    {userDetails.user?.first_name}{userDetails.user?.last_name}<br />
                    <span className="text-xs lowercase "> {userDetails.user?.email}</span>
                  </p>
                </div>
              </Link>
              <div className="flex  mt-2 cursor-pointer">
                <img src={notify} alt="active" className="w-7 h-7" />
                <p className="bg-primary rounded-full p-2 text-white h-5 w-5 -ml-4 font-bold font-mono flex items-center text-xxs sm:justify-center cursor-pointer">
                  {/* {notifications.length} */}
                  {notifications}
                </p>
              </div>
            </div>
            {/* {showNotifications && (
              <div className="grid grid-cols-3">
                <div className="col-start-3 col-end-4 bg-white shadow-md rounded-md p-4 border-b border-lightAsh"></div>
              </div>
            )} */}
          </div>
          {/* <div className="md:hidden">{!showSidebar && <Sidebar />}</div> */}
        </>
      )}
    </>
  );
};

export { DashboardSidebarFooter };
