export const commaSeparate = (price = '') => {
  let split = price.split('')
  let len = split.length
  let commad =
    len < 4
      ? price
      : len < 7
        ? split.splice(len - 3, 0, ',')
        : (split.splice(len - 3, 0, ','), split.splice(len - 7, 0, ','))
  return Array.isArray(split) ? split.join('') : split
}
