import React from 'react'
import { SectionTitle } from '../atoms'
import { ReactComponent as builder } from '../assets/builder-whyupland.svg'
import { ReactComponent as building } from '../assets/building-whyupland.svg'
import { ReactComponent as document } from '../assets/document.svg'
import { ReactComponent as trade } from '../assets/trade-whyupland.svg'
import iphone from '../assets/iphone.png'

const HowToBuyItems = ({ icon: Component, heading, description }) => (
  <div className='flex items-center space-x-5 md:space-x-10   '>
    <div style={{ borderRadius: '50%' }} className=' w-14 h-14 md:w-20 md:h-20 border border-primary  flex justify-center items-center p-3 md:p-0 ' >
      <Component fill='#54436B' className='w-max  ' />
    </div>
    <div className='flex-1' >
      <h4 className='font-circular text-lg md:text-2xl font-medium text-black '>{heading}</h4>
      <p className='font-sofia mt-3 md:mt-5 text-sm md:text-lg leading-4 text-dimGrey' >{description}</p>
    </div>
  </div>
)

const HowToBuy = () => {
  return (
    <div className='font-circular py-5  pb-20 md:px-16 bg-white'>
      {/* <div className='w-full bg-white py-16 px-5 md:px-16 pb-20' /> */}

      <div className='px-5' >
        <SectionTitle className='  ' title={'How to Buy and Sell'} />
      </ div>
      <div className='px-12'>

        <div className='md:flex-row flex-col-reverse flex justify-center md:space-x-48'>
          <div className=' space-y-12 md:mt-0 mt-16 flex-1 ' >
            <HowToBuyItems icon={building} heading='Create Listings'
              description='Add all the properties as listings in the system to get the common user'
            />
            <HowToBuyItems icon={trade} heading='Bid/Show Interest'
              description='Buyer can either show intrest on the property or place a bid '
            />
            <HowToBuyItems icon={builder} heading='Sellers Notified'
              description='Seller notified with the inrests or Bids palced by the buyer'
            />
            <HowToBuyItems icon={building} heading='Save Listings'
              description='The property is marked as sold and rest details are received as email'
            />
            <HowToBuyItems icon={document} heading='View Documents'
              description='Buyer verifies the property document and approve/reject the sales'
            />
            <HowToBuyItems icon={document} heading='Document Transferred'
              description='Seller selects a buyer &amp; Transfer document to initiate sales.'
            />
          </div>
          <div className='flex justify-center md:items-center mt-20 md:mt-0' >
            <img src={iphone} alt="mobile app screen" className='w-80 md:h-3/4 ' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowToBuy