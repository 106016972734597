import { useState, useEffect } from "react";
import { ListingsLayout } from "../Layout";
import { useParams } from "react-router";

import EditPropertyForm from "../templates/EditPropertyForm";
import { axiosInstance } from "../Auth/Axios";
import DashboardLoader from "../templates/DashboardLoader";

const EditDetails = () => {

  return (
    <>

      <ListingsLayout>
        <EditPropertyForm />
      </ListingsLayout>
    </>
  );
};

export { EditDetails };
