import { useState } from "react";

import { toast } from "react-toastify";
import { axiosWithAuth } from "../Auth/Axios";
import LoadSpinner from "../templates/LoadSpinner";

const DeleteAccount = () => {
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  const deleteAccount = () => {
    setSpinnerLoading(true);
    axiosWithAuth()
      .delete("account/delete-account")
      .then((response) => {
        const successMessage = response.data.data;
        toast.success(successMessage);
        window.location.replace("/register");
      })
      .catch(function (error) {
        if (error.response) {
          setSpinnerLoading(false);
          const errorMessage = error.response.data.data;
          toast.error(errorMessage);
        }
      });
  };
  return (
    <div className=" ">
      <div className=" text-base">
        <p style={{ color: "#F12B2C" }} className=''>Deleting your account will:</p>
        <div className="my-2">
          <p>Delete your account from the system</p>
          <p>Erase all transactions made on the system</p>
          <p className="font-bold text-xl py-5">Are you sure?</p>
        </div>
      </div>
      <div
        className="m-auto w-11/12 flex justify-center items-center"
        onClick={deleteAccount}
      >
        <button
          className="text-white text-base font-bold px-6 py-2 my-2 rounded-md shadow-sm focus:outline-none"
          style={{ backgroundColor: "#F12B2C" }}
        >
          {spinnerLoading ? <LoadSpinner /> : "Delete Account"}
        </button>
      </div>
    </div>
  );
};

export { DeleteAccount };
