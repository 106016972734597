import closeButton from "../assets/userDashboard/closeButton.png";

const Modal = ({
  showModal = false,
  children,
  handleClose,
  modalTitle,
  showButton = true,
  transferDocument = false,
}) => {
  return (
    <>
      {showModal ? (
        <div className=" z-10 fixed left-0 top-0 right-0 bottom-0 modalBg flex items-center justify-center">
          <div className="w-2/4 h-1/2  bg-white shadow-md rounded-md">
            <div className="flex p-6">
              <p className="font-bold font-inter flex-1 text-2xl text-center">{modalTitle}</p>
              <div className="ml-auto w-max ">
                <img
                  src={closeButton}
                  alt="closeButton"
                  className="w-6 cursor-pointer"
                  onClick={handleClose}
                />
              </div>
            </div>

            <div className="mb-4 px-10 flex flex-col space-y-5 justify-cente items-cente">
              {showButton && (
                <div className="flex justify-self-end">
                  {transferDocument && (
                    <p className="text-center text-2xl font-bold py-4">
                      Transfer Document
                    </p>
                  )}
                </div>
              )}
              <div className="h-full  ">{children}</div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export { Modal };
