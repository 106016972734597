import { useState, useEffect } from "react";

import { LegalLayout } from "../Layout/LegalLayout";
import BlogCard from "../templates/BlogCard";
import { axiosInstance } from "../Auth/Axios";
import DashboardLoader from "../templates/DashboardLoader";
import { Footer } from "../organisms";
import { Header } from "../molecules";
import blogHeader from "../assets/blog-header.png"
import { SectionTitle } from "../atoms";
import { Link } from "react-router-dom";
import blogImage from '../assets/blog-image.png'
import blogImage1 from '../assets/blog-image-1.png'
import blogImage2 from '../assets/blog-image-2.png'
import blogImage3 from '../assets/blog-image-3.png'
import blogImage4 from '../assets/blog-image-4.png'
import blogImage5 from '../assets/blog-image-5.png'
import blogImage6 from '../assets/blog-image-6.png'
import blogImage7 from '../assets/blog-image-7.png'

import newsImage from '../assets/news-image.png'
import newsImage1 from '../assets/news-image-1.png'
import newsImage2 from '../assets/news-image-2.png'
import { category } from "../data/SelectOptions";

const BlogItem = ({ img, id, date, title, precept, category }) => (
  <Link to={`/legal/blogview/${id + 1}`} className="space-y-4 md:w-64 w-48 md:min-w-0  min-w-full font=lora my-16">
    <div className="relative">
      <img src={img} alt='blog-item' className="w-full" />
      <div style={{ backgroundColor: '#FFFFFF26', minWidth: 20, fontSize: 10 }} className="w-max absolute top-2 right-2 rounded-lg px-2 py-1 text-xs mb-4 uppercase text-white">{category}</div>
    </div>
    <div className="text-lightAsh">{date}</div>
    <div style={{ color: '#495057' }} className="font-bold text-lg text-lightBlack font" >{title}</div>
    <div className="text-lightAsh text-xs leading-5 " >  {precept}</div>
  </Link >
)

const NewsItem = ({ img, id, date, title, precept, category }) => (
  <Link to={`/legal/newsview/${id + 1}`} style={{ backgroundImage: `url(${img})`, width: 350, height: 420 }} className=" md:min-w-0 min-w-full  rounded-md relative px-10 flex items-end bg-cover bg-no-repeat cursor-pointer  text-white bg-left">
    <div className=" "  >
      <div style={{ backgroundColor: '#FFFFFF26', minWidth: 20, fontSize: 10 }} className="w-max absolute top-2 right-2 rounded-lg px-2 py-1 mb-4 uppercase ">{category}</div>
      <div className="mb-4 font-lora md:flex font-extralight italic ">{date} </div>
      <div className="font-sofia font-bold text-xl mb-4">{title}</div>
      <div className="mb-14 font-lora md:flex font-normal leading-5 italic text-xs ">
        {precept}
      </div>
    </div>
  </Link >
)

const Blog = () => {


  const blogCategories = [
    'All',
    'Real Estate',
    'Rentals',
    'Events',
    'Technology',
  ]


  const newsDetails = [
    {
      img: newsImage,
      date: '08.08.2021',
      title: 'How to build in low cost',
      precept: 'Progressively incentivize cooperative systems through technically sound functionalities. Credibly productivate seamless data with flexible schemas.',
      category: 'fashion',
    },
    {
      img: newsImage1,
      date: '08.08.2021',
      title: 'How to build in low cost',
      precept: 'Progressively incentivize cooperative systems through technically sound functionalities. Credibly productivate seamless data with flexible schemas.',
      category: 'fashion',
    },
    {
      img: newsImage2,
      date: '08.08.2021',
      title: 'How to build in low cost',
      precept: 'Progressively incentivize cooperative systems through technically sound functionalities. Credibly productivate seamless data with flexible schemas.',
      category: 'fashion',
    },
  ]

  const blogDetails = [
    {
      img: blogImage,
      date: '08.08.2021',
      title: 'How to build in low cost',
      precept: 'Progressively incentivize cooperative systems through technically sound functionalities. Credibly productivate seamless data with flexible schemas.',
      category: 'fashion',
    },
    {
      img: blogImage1,
      date: '08.08.2021',
      title: 'How to build in low cost',
      precept: 'Progressively incentivize cooperative systems through technically sound functionalities. Credibly productivate seamless data with flexible schemas.',
      category: 'fashion',
    },
    {
      img: blogImage2,
      date: '08.08.2021',
      title: 'How to build in low cost',
      precept: 'Progressively incentivize cooperative systems through technically sound functionalities. Credibly productivate seamless data with flexible schemas.',
      category: 'fashion',
    },
    {
      img: blogImage3,
      date: '08.08.2021',
      title: 'How to build in low cost',
      precept: 'Progressively incentivize cooperative systems through technically sound functionalities. Credibly productivate seamless data with flexible schemas.',
      category: 'fashion',
    },
    {
      img: blogImage4,
      date: '08.08.2021',
      title: 'How to build in low cost',
      precept: 'Progressively incentivize cooperative systems through technically sound functionalities. Credibly productivate seamless data with flexible schemas.',
      category: 'fashion',
    },
    {
      img: blogImage5,
      date: '08.08.2021',
      title: 'How to build in low cost',
      precept: 'Progressively incentivize cooperative systems through technically sound functionalities. Credibly productivate seamless data with flexible schemas.',
      category: 'fashion',
    },
    {
      img: blogImage6,
      date: '08.08.2021',
      title: 'How to build in low cost',
      precept: 'Progressively incentivize cooperative systems through technically sound functionalities. Credibly productivate seamless data with flexible schemas.',
      category: 'fashion',
    },
    {
      img: blogImage7,
      date: '08.08.2021',
      title: 'How to build in low cost',
      precept: 'Progressively incentivize cooperative systems through technically sound functionalities. Credibly productivate seamless data with flexible schemas.',
      category: 'fashion',
    },
  ]

  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);

  const getAllBlogs = () => {
    setLoading(true);
    axiosInstance.get("/blogs").then((response) => {
      const results = response.data.data;
      setBlogs(results);
      setLoading(false);
    });
  };

  useEffect(() => {
    // getAllBlogs();
  }, []);

  return (
    <div>
      <Header />
      {loading ? (
        <DashboardLoader />
      ) : (
        // <LegalLayout text="News about the buildings">
        //   {blogs.map((blogs) => {
        //     return <BlogCard blogs={blogs} />;
        //   })}
        // </LegalLayout>

        <div>

          <div id="blog-hero" style={{ backgroundImage: `url(${blogHeader})`, height: 600 }} className="hero pl-16 flex items-end bg-cover bg-no-repeat bg-left ">
            <div className=" w-1/2 mb-14 "  >
              <div className="mb-14">
                <div style={{ backgroundColor: '#FFFFFF26', minWidth: 20, }} className="w-max  rounded-lg px-2 py-1 text-sm mb-4 ">Real Estate</div>
                <div className="font-sofia font-bold text-4xl text">Blogs &amp; News</div>
              </div>
              <div className="mb-14 font-lora md:flex font-extralight italic ">
                <span className="block">  08.08.22 </span>
                <span className="tracking-tighter w-max leading-5 block px-3"> -------- </span>
                <span className="flex-1 block  ">
                  Progressively incentivize cooperative systems through technically sound functionalities. The credibly productivate seamless data.
                </span>
              </div>
              <div className="tracking-widest">. . .</div>
            </div>
          </div>

          <main className=" px-7 md:px-20 pt-7 pb-24 ">
            <section id='blog-section'>
              <SectionTitle title=' Blog' />
              <div className="flex justify-between w-full">
                <nav>
                  <ul className="flex flex-wrap space-x-7 pr-2 text-primary">
                    {blogCategories.map(category => (
                      <li className='hover:text-black font-semibold py-3 '>
                        <Link to='#' className="">
                          {category}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
                <button className="w-max text-primary font-bold text-sm hover:text-black">
                  <Link to='#'>
                    View All
                  </Link>
                </button>
              </div>

              <div id="blog-items" className="flex md:flex-wrap md:overflow-hidden overflow-auto  space-x-10  justify-between py-7 " >
                {blogDetails.map((blog, index) => (
                  <BlogItem id={index}  {...blog} />
                ))}
              </div>

            </section>


            <section id='news-section'>
              <SectionTitle title='News' />


              <div id="blog-items" className="flex md:flex-wrap md:overflow-hidden overflow-auto space-x-5 justify-between py-7 " >
                {newsDetails.map((blog, index) => (
                  <NewsItem id={index} {...blog} />
                ))}
              </div>

            </section>

          </main>


        </div>
      )}
      <Footer />

    </div >
  );
};

export { Blog };
