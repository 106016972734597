const Select = ({
  values,
  onValueChange,
  selectedValue,
  className,
  labelName,
  error,
  register,
  ...rest
}) => {
  if (values && Array.isArray(values[0])) {
    values = values
      .filter((item, index) => index !== 0)
      .map((item, index) => {
        return { id: item[0], name: item[1] }
      })
  }
  !values && console.log(className);
  return (
    <div className="my-14 text-  w-full otherSelect">
      {/* {labelName && <label className="text-lg font-semibold w-max text-left">{labelName}</label>} */}
      <select
        className={`w-full border-b  cursor-pointer border-ashThree text- focus:outline-none p-1 capitalize  ${className}`}
        placeholder={labelName}
        defaultValue={''}
        onChange={({ target: { value } }) => onValueChange(value)}
        {...register}
        {...rest}
      >
        <option value="" disabled>{labelName}</option>

        {values && values?.map(({ id, name }, i) => (
          <option key={id + i} className='text-' value={id}>
            {name}
          </option>
        ))}
      </select>

      <span>
        <p className="text-red-500 text-sm">{error}</p>
      </span>
    </div>
  );
};

export default Select;
