import React from 'react'
import { SectionTitle } from '../atoms'
import { ReactComponent as builder } from '../assets/builder-whyupland.svg'
import { ReactComponent as building } from '../assets/building-whyupland.svg'
import { ReactComponent as money } from '../assets/money-whyupland.svg'
import { ReactComponent as trade } from '../assets/trade-whyupland.svg'


const WhyUplandHuntItems = ({ Icon: Component, heading, description }) => (
  <div className='flex items-center space-x-5 md:space-x-10   '>
    <div style={{ borderRadius: '50%', }} className=' w-14 h-14 md:w-20 md:h-20 bg-primary flex justify-center items-center p-3 md:p-0' >
      <Component fill='#FFCF81' className=' w-max ' />
    </div>
    <div className='flex-1' >
      <h4 className='font-circular text-lg md:text-2xl font-medium text-black '>{heading}</h4>
      <p className=' font-sofia mt-3 md:mt-5 text-sm md:text-lg leading-4 text-dimGrey' >{description}</p>
    </div>
  </div>
)
const WhyUplandHunt = () => {
  return (
    <div className='w-full bg-white py-5 px-5 md:px-16  pb-20'>
      <div className='' >
        <SectionTitle title={'Why UplandHunt?'} />
      </div>
      <div className='md:grid md:grid-cols-2 md:gap-x-20 gap-y-9 mt-12 space-y-9 md:space-y-0  ' >
        <WhyUplandHuntItems Icon={builder}
          heading='Top class builders with huge projects'
          description='Experienced and qualified builders working on all kinds of property projects.'
        />
        <WhyUplandHuntItems Icon={trade}
          heading='Beyond Borders and Seas'
          description='Regardless of your location, buy, sell, rent or lease properties anywhere in Nigeria.'
        />
        <WhyUplandHuntItems Icon={building}
          heading='Best Experience Ever'
          description='We go to great lengths to make the process of finding your next home simple and fulfilling by assisting you in finding the finest property for your needs. We provide convenient methods for contacting the seller or agent as well as clearly displayed quality listings.'
        />
        {/* <WhyUplandHuntItems Icon={money}
          heading='Top class builders with huge projects'
          description='Experienced and qualified builders working on all kinds of property projects.'
        /> */}

      </div>

    </div>
  )
}

export default WhyUplandHunt