export const propertyType = [
  ["", ""],
  ["1", "Under construction"],
  ["2", "Occupancy stage"],
  ["3", "Flat"],
  ["4", "Detached"],
  ["5", "Semi Detached"],
  ["6", "Town house"],
  ["7", "Bungalow"],
  ["8", "Residential"],
  ["9", "Student accomodation"],
  ["10", "Office space"],
  ["11", "Condo Apartment"],
];

export const availability = [
  ["", ""],
  ["1", "Available"],
  ["2", "Unavailable"],
];

export const listType = [
  ["", ""],
  ["1", "To Rent"],
  ["2", "To Buy"],
  ["3", "Under Construction"],
];

export const filterBy = [
  ["Category", "Category"],
  ["Type", "Type"],
  ["Selling Status", "Selling Status"],
  ["Price", "Price"],
  ["Amenities", "Amenities"],
  ["Deposit Structure", "Deposit Structure"],
  ["Bedroom", "Bedroom"],
  ["Date", "Date"],
  ["Size", "Size"],
  ["Furnishing Type", "Furnishing Type"],
];

export const filterCategory = [
  ["Under construction", "Under construction"],
  ["To Buy", "To Buy"],
  ["To Rent", "For Rent"],
];

export const category = [["Category", "Category"]];

// export const deposit = [
//   ["1", "10%"],
//   ["2", "20%"],
//   ["3", "30%"],
//   ["4", "40%"],
//   ["5", "50%"],
//   ["6", "60%"],
//   ["7", "70%"],
//   ["8", "80%"],
//   ["9", "90%"],
//   ["10", "100%"],
// ];

export const date = [
  ["Just listed", "Just listed"],
  ["Less than 30 days", "Less than 30 days"],
  ["A month ago", "A month ago"],
];

export const furnishingType = [
  ["", ""],
  ["1", "Not Furnished"],
  ["2", "Semi-Furnished"],
  ["3", "Fully Furnished"],
];

export const sellingStatus = [
  ["Not selling", "Not selling"],
  ["Featured", "Featured"],
  ["Coming soon", "Coming soon"],
  ["Planning", "Planning"],
  ["Sold out", "Sold out"],
  ["Promotion/deals", "Promotion/deals"],
  ["Low deposits", "Low deposits"],
];

export const bedrooms = [
  ["Studio", "Studio"],
  ["1 bed", "1 bed"],
  ["2 beds", "2 beds"],
  ["3 beds", "3 beds"],
  ["4 beds", "4 beds"],
  ["5 beds", "5 beds"],
  ["6 beds", "6 beds"],
  ["7 beds", "7 beds"],
  ["8 beds", "8 beds"],
  ["9 beds", "9 beds"],
  ["10 beds", "10 beds"],
];

export const squareFeet = [
  ["Minimum square feet", "Minimum square feet"],
  ["Maximum square feet", "Maximum square feet"],
];

export const price = [
  ["Minimum", "Minimum"],
  ["Maximum", "Maximum"],
];

export const minimumPrice = [
  ["₦50,000", "₦50,000"],
  ["₦100,000", "₦100,000"],
  ["₦200,000", "₦200,000"],
  ["₦300,000", "₦300,000"],
  ["₦400,000", "₦400,000"],
  ["₦500,000", "₦500,000"],
  ["₦600,000", "₦600,000"],
  ["₦700,000", "₦700,000"],
  ["₦800,000", "₦800,000"],
  ["₦900,000", "₦900,000"],
  ["₦1 million", "₦1 million"],
];

export const maximumPrice = [
  ["₦2 million", "₦2 million"],
  ["₦5 million", "₦5 million"],
  ["₦10 million", "₦10 million"],
  ["₦20 million", "₦20 million"],
  ["₦50 million", "₦50 million"],
  ["₦100 million", "₦100 million"],
  ["₦200 million", "₦200 million"],
  ["₦500 million", "₦500 million"],
  ["₦1 billion", "₦₦1 billion"],
];

export const amenities = [
  ["Gym", "Gym"],
  ["Swimming pool", "Swimming pool"],
  ["Rooftop decker", "Rooftop decker"],
  ["Concierge", "Concierge"],
  ["Barbeque", "Barbeque"],
  ["Party room", "Party room"],
  ["Balcony", "Balcony"],
  ["Fibre stream internet", "Fibre stream internet"],
  ["Air conditioner", "Air conditioner"],
  ["Guest suites", "Guest suites"],
  ["Pets allowed", "Pets allowed"],
  ["Washing machine", "Washing machine"],
  ["Geysers", "Geysers"],
  ["Lifts", "Lifts"],
  ["Parking", "Parking"],
];

export const depositStructure = [
  ["", ""],
  ["1", "10%"],
  ["2", "20%"],
  ["3", "30%"],
  ["4", "40%"],
  ["5", "50%"],
  ["6", "60%"],
  ["7", "70%"],
  ["8", "80%"],
  ["9", "90%"],
  ["10", "100%"],
];

export const multiSelectOptions = [
  { label: "Gym", value: "1" },
  { label: "Swimming pool", value: "2" },
  { label: "Rooftop decker", value: "3" },
  { label: "Concierge", value: "4" },
  { label: "Barbeque", value: "5" },
  { label: "Party room", value: "6" },
  { label: "Balcony", value: "7" },
  { label: "Fibre stream internet", value: "8" },
  { label: "Air conditioner", value: "9" },
  { label: "Guest suites", value: "10" },
  { label: "Pets allowed", value: "11" },
  { label: "Washing machine", value: "12" },
  { label: "Geysers", value: "13" },
  { label: "Lifts", value: "14" },
  { label: "Parking", value: "15" },
];

export const propertyTypeTwo = [
  ["", ""],
  ["1", "Under construction"],
  ["2", "Occupancy stage"],
  ["3", "Flat"],
  ["4", "Detached"],
  ["5", "Semi Detached"],
  ["6", "Town house"],
  ["7", "Bungalow"],
  ["8", "Residential"],
  ["9", "Student accomodation"],
  ["10", "Office space"],
  ["11", "Condo Apartment"],
];
