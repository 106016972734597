import { useRef } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { city } from "../data/Properties";
import locationIcon from "../assets/Location-icon.svg"
import rightArrow from "../assets/right.svg";
import leftArrow from "../assets/left.svg";
import { SectionTitle } from "../atoms";

const Explore = ({ title }) => {
  const sliderRef = useRef();

  const handlePrev = (ref) => {
    ref.current.slickPrev();
  };

  const handleNext = (ref) => {
    ref.current.slickNext();
  };
  const settings = {
    // customPaging: function (i) {
    //   return (
    //     <a>
    //       {/* <img src={`${baseUrl}/abstract0${i + 1}.jpg`} /> */}
    //       <p>{i + 1}</p>
    //     </a>
    //   );
    // },
    dotsClass: "slick-dots slick-thumb",
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          infinite: true,
        },
      },
    ],
  };
  return (
    <>
      <SectionTitle title={title} more />
      <Slider ref={sliderRef} {...settings}>
        {city.map(({ name, photo, location, id }) => {
          return (
            <Link to={`/all-properties/${location}`} key={id}>
              <div className="mr-8 mb-8 p-4 border border-white shadow-xl rounded-md cursor-pointer">
                <section className="relative w-full h-auto shadow rounded p-3 object-cover">
                  <img
                    src={photo}
                    alt="location"
                    className="object-cover w-64 rounded m-auto h-52"
                  />
                  <div className="absolute top-4 bg-green rounded text-white right-4">
                    <p className="font-bold py-1 px-4 text-sm text-white">
                      {location}
                    </p>
                  </div>
                </section>
                <section className="flex space-x-4 py-5 text-black font-semibold text-base" >
                  <img src={locationIcon} alt="location" className="mr-2" />{location}, Nigeria.

                </section>
              </div>
            </Link>
          );
        })}
      </Slider>
      {/* <div className="mt-10 flex justify-center items-center ">
        <section>
          <img
            src={leftArrow}
            alt="left-arrow"
            onClick={() => handlePrev(sliderRef)}
            className="cursor-pointer w-4 slider-arrow mr-2"
          />
        </section>
        <p>
          Previous <span className="text-primary">Next</span>
        </p>
        <section>
          <img
            src={rightArrow}
            alt="right-arrow"
            onClick={() => handleNext(sliderRef)}
            className="cursor-pointer w-4 slider-arrow"
          />
        </section>
      </div> */}
    </>
  );
};

export { Explore };
