import React, { useState } from "react";
import { Button, SectionTitle } from "../atoms";
import { SectionWrapper } from "../Layout/SectionWrapper";
import SubCard from "../templates/SubCard";
import bronze from "../assets/bronze.svg";
import plan from "../assets/plan.svg";
import bronzeBg from "../assets/bronzeBg.svg";
import SubscriptionCard from "../templates/SubscriptionCard";
import check from "../assets/check-circle.svg";
import { useHistory } from "react-router-dom";


export const SubsCard = ({ interval, dashboard, setGetPlanId, getPlanId, upgradeSub }) => {

  const bronzeItems = [
    'All limited links',
    'Own analytics platform',
    'Chat support',
    'Optimize hashtags',
    'Unlimited users',
  ]

  const Span = ({ children, small, hovered }) => (
    <span
      style={{ color: hovered ? 'white' : '#231D4F' }}
      className={`font-extrabold text-black font-circular ${small ? 'text-2xl' : 'text-4xl'} `}  >{children} </span>
  )

  const Card = ({ subId, price, type, desc, items, gold }) => {
    const [hovered, setHovered] = useState(false)
    return (
      <div
        onMouseLeave={() => setHovered(false)}
        onMouseEnter={() => setHovered(true)}
        onClick={() => setHovered(true)}
        onTouchMove={() => setHovered(true)}
        onTouchEnd={() => setHovered(false)}
        className={` rounded-t-md shadow ${dashboard && 'mx-5'} ${gold && 'bg-gold'}`}>

        <div
          style={{ height: subId === 1 ? '513px' : '473px' }}
          className={`w-72  ${!gold && 'bg-white'} p-11 cursor-pointer  ${hovered && ' bg-primary text-white transition  relative'}    ${subId === 3 && 'rounded-tl-md '}  ${subId === 1 && ' rounded-t-md '}   `}>
          <p className="mb-7 ">
            <Span hovered={hovered}>${price} </Span> <span>-{interval}</span>
          </p>
          <div className="mb-4">
            <Span hovered={hovered} small>{type}</Span>
          </div>
          <p className="mb-6">{desc}</p>
          <ul className="">
            {items.map((item => (
              <li key={item} className="flex items-center my-3">
                <img src={check} alt="check" className="mr-6" />
                {/* <Check fill='#f6e0f8' className="mr-6 hover:text-white hover:" /> */}

                <p>{item}</p>
              </li>

            )))}
          </ul>
        </div>
        {dashboard &&
          <div
            role={'button'}
            onClick={() => {
              upgradeSub(subId)
            }}
            className=" px-5 border-t border-white bg-primaryDullBg text-primary text-center hover:bg-primary hover:text-white py-4 md:w-full rounded-b-md"
          >
            Get started today
          </div>
        }
      </div>
    );
  }

  return (
    <div className="w-auto overflow-x-scroll md:overflow-hidden " >
      <div className=" text-primary flex items-end w-max mx-auto rounded-md text-sm leading-6 ">
        <Card
          price='20' type='Bronze' items={bronzeItems} subId={3}
          desc='It is a long established fact that a reader will be distracted by the..'
        />
        <Card
          price='50' type='Silver' items={bronzeItems} subId={2}
          desc='For most businesses that want to otpimize web queries'
        />
        <Card
          price='100' type='Gold' items={bronzeItems} subId={1}
          desc='For most businesses that want to optimize web queries'
          gold
        />
      </div>

    </div>
  )
}

const Subscription = () => {
  const [billingInterval, setBillingInterval] = useState('monthly')

  const history = useHistory()
  const authObj = JSON.parse(localStorage.getItem('auth'))

  return (
    <SectionWrapper>
      <div className="my-5 ">
        <div className="md:flex justify-between">
          <SectionTitle title="Subscription" noLine={false} />
          {/* <img src={plan} alt="plan" /> */}
        </div>

        <div style={{ color: '231D4F' }} className="">

          <div className="font-circular mb-5">
            <div className=" my-5 mx-auto text-center " >
              <h5 className="text-4xl mb-6 font-semibold">
                Simple, transparent pricing
              </h5>
              <p className="text-primaryDull font-sofia text-normal text-xl mb-10 ">No contracts. No surprise fees.</p>
              <div className="flex w-max m-auto">
                <div role='button'
                  onClick={() => setBillingInterval('monthly')}
                  className={`py-4 px-16 font-normal text-base rounded-l-md shadow transition  ${billingInterval === 'monthly' ? 'text-yellow bg-primary ' : 'text-black bg-white border border-primary'} `}>Monthly</div>
                <div role='button'
                  onClick={() => setBillingInterval('yearly')}
                  className={`py-4 px-16 font-normal text-base rounded-r-md shadow transition ${billingInterval === 'yearly' ? 'text-yellow bg-primary ' : 'text-black bg-white border border-primary'} `}>Yearly</div>
              </div>
            </div>
          </div>

          <SubsCard interval={billingInterval} />

          {/* <div className="w-full md:grid grid-cols-3 gap-6 justify-between my-10">
            <SubCard
            category="Bronze"
            icon={bronze}
            amount="$10.00"
            bgImage={bronzeBg}
          />
            <SubscriptionCard />
        </div> */}
        </div>

        <div className="flex w-full md:w-1/3 mx-auto justify-center items-center text-center my-10">
          <Button
            buttonText={authObj?.token ? "Subscribe" : "Log in to subscribe"}
            onClick={() => history.push(authObj?.token
              ? 'dashboard/subscription/upgradeSub'
              : '/login')} />
        </div>

      </div>
    </SectionWrapper >
  );
};

export { Subscription };
