import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";

import bin from "../assets/bin.png";
import { Button, Input } from "../atoms";
import { InputTwo } from "../atoms";
import SelectInput from "../atoms/Select";
import { MultiSelect } from "react-multi-select-component";
import {
  availability,
  listType,
  furnishingType,
  depositStructure,
  multiSelectOptions,
  propertyType,
} from "../data/SelectOptions";
import { axiosInstance, axiosWithAuth } from "../Auth/Axios";
import { Link } from "react-router-dom";
import LoadSpinnerTwo from "./LoadSpinnerTwo";
import DashboardLoader from "./DashboardLoader";
import Select from "../atoms/Select";

const EditPropertyForm = () => {



  const { id } = useParams();
  const [data, setData] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);
  const [listingTypeOptions, setListingTypeOptions] = useState([]);
  const [availabilityOptions, setAvailabilityOptions] = useState([]);
  const [furnishingTypeOptions, setFurnishingTypeOptions] = useState([]);
  const [depositStructureOptions, setDepositStructureOptions] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([])
  const [allCities, setAllCities] = useState({})
  const [cityOptions, setCityOptions] = useState([])
  const [roomsOptions, setRoomsOptions] = useState([{
    bedroom: '',
    bathroom: '',
    kitchen: '',

  }])

  let propertyImages
  const getPropertyData = () => {
    axiosInstance
      .get(`property/${id}`)
      .then(function (response) {
        // handle success
        const details = response.data.data;
        propertyImages = details.images;
        setLoading(false);
        setData(details);
        setImages(propertyImages);
      })
      .catch(function (error) {
        // handle error
      })
      .then(function () {
        // always executed
      });
  };

  const validationSchema = Yup.object().shape({
    type: Yup.number().required("Property Type is required"),
    category: Yup.number().required("Category is required"),
    price: Yup.string().required("Price is required"),
    area: Yup.number().required("Area of the Property is required"),
    availability: Yup.number().required("Availability is required"),
    kitchen: Yup.string().required("Number of Kitchen is required"),
    bathroom: Yup.string().required("Number of Bathroom is required"),
    bedroom: Yup.string().required("Number of Bedrooms is required"),
    furnish_type: Yup.number().required("Furnishing Type is required"),
    deposit_structure: Yup.number().required("Deposit Structure is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState, control, reset } = useForm({
    formOptions,
    defaultValues: {
      name: data?.name,
      city: data?.city,
      price: data?.price,
      area: data?.area,
      postal_code: data?.postal_code,
      address_line_one: data?.address_line_one,
      address_line_two: data?.address_line_two,
      country: data?.country,
      kitchens: data?.kitchens.kitchen.charAt(0),
      category: data?.property_category?.id,
      availability: data?.availability_status?.id,
      type: data?.property_type.id,
      description: data?.description,
      bedroom: data?.bed.bedroom.charAt(0),
      bathroom: data?.bath.bathroom.charAt(0),
      kitchen: data?.kitchens?.id,
      furnish_type: data?.furnish?.id,
      deposit_structure: data?.deposit_type?.id,
      amenities: data?.amenities
    },
  });
  const { errors } = formState;

  const editDetails = (data) => {
    let amenities = { amenity: [selected[1]?.value] || [] };
    const propertyData = { ...amenities, ...data };
    const payload = {
      kitchen: propertyData.kitchens,
      bedroom: propertyData.bed,
      bathroom: propertyData.bath,
      // category: propertyData.property_category,
      type: propertyData.property_type,
      ...propertyData,
    };
    setLoading(true);
    axiosWithAuth()
      .put(`property/${id}`, payload)
      .then((response) => {
        if (response) {
          setLoading(false);
        }
        toast.success(response.data.message);
      })
      .catch(function (error) {
        if (error.response) {
          setLoading(false);
          const errorMessage = error.response.data.data;
          Object.values(errorMessage.errors)
            .flat()
            .map((err) => {
              toast.error(err);
            });
        }
      });
  };

  const deletePropertyImage = (imageId) => {
    setSpinnerLoading(true);
    axiosWithAuth()
      .delete(`property/${id}/images/${imageId}`)
      .then((response) => {
        const successMessage = response.data.data;
        toast.success(successMessage);
        setSpinnerLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          const errorMessage = error.response.data.data;
          toast.error(errorMessage);
          setSpinnerLoading(false);
        }
        // handle error
        // setError(error.status);
      });
  };

  const getFilters = () => {
    setLoading(true);
    axiosInstance
      .get("property/attributes/all")
      .then((response) => {
        // handle success
        const category = response.data;
        const entries = Object.entries(category.data);
        // console.log(entries);
        const propertyEntries = Object.values(entries[0][1]);
        const availabilityEntries = Object.values(entries[1][1]);
        const bathroom = Object.values(entries[3][1])
        const bedroom = Object.values(entries[4][1])
        const depositEntries = Object.values(entries[5][1]);
        const furnishingEntries = Object.values(entries[6][1]);
        const kitchen = Object.values(entries[7][1])
        const listingEntries = Object.values(entries[8][1]);
        const countries = Object.keys(Object.values(entries[9])[1]).filter(item => item === 'nigeria').map(item => ({ id: item, name: item }))
        const allCities = Object.values(entries[9])[1]

        setRoomsOptions({
          bedroom: bedroom?.map(item => ({ id: item?.id, name: item?.bedroom })),
          bathroom: bathroom?.map(item => ({ id: item?.id, name: item?.bathroom })),
          kitchen: kitchen?.map(item => ({ id: item?.id, name: item?.kitchen })),
        })
        setListingTypeOptions(listingEntries);
        setPropertyTypeOptions(propertyEntries);
        setAvailabilityOptions(availabilityEntries);
        setFurnishingTypeOptions(furnishingEntries);
        setDepositStructureOptions(depositEntries);
        setCountriesOptions(countries);
        setAllCities(allCities);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
      })
      .then(function () {
        // always executed
      });
  };

  useEffect(() => {
    // const fetchData = async () => {
    //   setData(await getPropertyData);
    // };
    getPropertyData();
    getFilters();
  }, []);

  useEffect(() => {
    console.log(data);
    const defaultValues = {
      name: data?.name,
      city: data?.city,
      price: data?.price,
      area: data?.area,
      postal_code: data?.postal_code,
      address_line_one: data?.address_line_one,
      address_line_two: data?.address_line_two,
      country: data?.country,
      kitchens: data?.kitchens.kitchen.charAt(0),
      category: data?.property_category?.id,
      availability: data?.availability_status?.id,
      type: data?.property_type.id,
      description: data?.description,
      bedroom: data?.bed.bedroom.charAt(0),
      bathroom: data?.bath.bathroom.charAt(0),
      kitchen: data?.kitchens?.id,
      furnish_type: data?.furnish?.id,
      deposit_structure: data?.deposit_type?.id,
      amenities: data?.amenities
    };
    reset(defaultValues);
  }, [data, reset]);



  return (
    <>

      {loading ? (
        <DashboardLoader />
      ) : (

        <>
          <form className="py-14" onSubmit={handleSubmit(editDetails)}>
            <>
              {/* <section className="">
              <Input
                type="text"
                label="Property Name"
                register={register("name")}
                readOnly />
              <SelectInput
                values={propertyType}
                selectedValue="Under construction"
                labelName="Property Type"
                register={register("property_type")}
                error={errors.type?.message} />
              <Input
                type="text"
                label="City"
                register={register("city")}
                readOnly />
              <Input
                type="text"
                label="Price"
                name="price"
                register={register("price")}
                error={errors.price?.message} />
              <Input
                type="text"
                label="Area of the Property"
                name="area"
                register={register("area")}
                error={errors.area?.message} />
              <Input
                type="text"
                label="Postal Code"
                name="postal_code"
                register={register("postal_code")}
                readOnly />
              <Input
                type="text"
                label="Address Lane 1"
                name="address_line_one"
                register={register("address_line_one")}
                readOnly />
              <Input
                type="text"
                label="Address Lane 2"
                name="address_line_two"
                register={register("address_line_two")}
                readOnly />
              <MultiSelect
                options={multiSelectOptions}
                value={selected}
                onChange={setSelected}
                labelledBy="Amenities"
                className="mt-7" />
              <Input
                type="text"
                label="Country"
                name="country"
                register={register("country")}
                readOnly />
              <SelectInput
                values={listType}
                selectedValue="Rent"
                labelName="Listing Type"
                name="category"
                register={register("category")}
                error={errors.category?.message} />
              <SelectInput
                values={availability}
                selectedValue="Available"
                labelName="Availability"
                name="availability"
                register={register("availability")}
                error={errors.availability?.message} />
              <Input
                type="text"
                label="Kitchen"
                name="kitchen"
                register={register("kitchens")}
                error={errors.kitchen?.message} />
              <Input
                type="text"
                label="Bathroom"
                name="bathroom"
                register={register("bath")}
                error={errors.bathroom?.message} />
              <Input
                type="text"
                label="Bedroom"
                name="bedroom"
                register={register("bed")}
                error={errors.bedroom?.message} />
              <div>
                <label className="text-lg font-semibold text-left">
                  Property Description
                </label>
                <textarea
                  className="w-full border border-gray-400 focus:outline-none px-2 rounded-md py-1 mt-1"
                  rows="4"
                  cols="5"
                  readOnly
                  placeholder="Write a short property description"
                  name="description"
                  register={register("description")}
                ></textarea>
              </div>
              <SelectInput
                values={furnishingType}
                selectedValue="Not furnished"
                labelName="Furnishing Type"
                name="furnish_type"
                register={register("furnish_type")}
                error={errors.furnish_type?.message} />
              <SelectInput
                values={depositStructure}
                selectedValue="10%"
                labelName="Deposit Structure"
                name="deposit_structure"
                register={register("deposit_structure")}
                error={errors.deposit_structure?.message} />
            </section> */}
            </>


            <section className="md:w-10/12 w-full ">
              <Input
                type="text"
                label="Property Name"
                name="name"
                register={register("name")}
                error={errors.name?.message}
              />
              <SelectInput
                values={propertyTypeOptions}
                selectedValue="Sell"
                labelName="Category"
                name="category"
                register={register("category")}
                error={errors.category?.message}
              />

              <Input
                type="text"
                label="Price"
                name="price"
                register={register("price")}
                error={errors.price?.message}
              />

              <Input
                type="text"
                label="Area of Property (in sq. km.)"
                name="area"
                register={register("area")}
                error={errors.area?.message}
              />
              <Input
                type="text"
                label="Postal Code"
                name="postal_code"
                register={register("postal_code")}
                error={errors.postal_code?.message}
              />

              <SelectInput
                // onChange={getCities}
                // values={[...countriesOptions, { id: 'us', name: 'usa' }]}
                values={countriesOptions}
                type="text"
                labelName="Country"
                name="country"
                register={register("country")}
                error={errors.country?.message}
              />


              <SelectInput
                // values={cityOptions}
                values={allCities?.['nigeria']
                  ?.sort()
                  ?.map(item => ({ id: item, name: item }))
                  || []}
                type="text"
                labelName="City"
                name="city"
                register={register("city")}
                error={errors.city?.message}
              />

              {/* {cityOptions.length > 0
            && 
          } */}

              {/* <Input
            type="text"
            label="City"
            name="city"
            register={register("city")}
            error={errors.city?.message}
          /> */}
              <Input
                type="text"
                label="Address Line 1"
                name="address_line_one"
                register={register("address_line_one")}
                error={errors.address_line_one?.message}
              />
              <Input
                type="text"
                label="Address Line 2"
                name="address_line_two"
                register={register("address_line_two")}
                error={errors.address_line_two?.message}
              />


              <SelectInput
                values={listingTypeOptions}
                selectedValue="1"
                labelName="Listing Type"
                name="type"
                register={register("type")}
                error={errors.type?.message}
              />
              <SelectInput
                values={availabilityOptions}
                selectedValue="Residence"
                labelName="Availability"
                name="availability"
                register={register("availability")}
                error={errors.availability?.message}
              />
              <SelectInput
                values={roomsOptions?.kitchen || []}
                type="text"
                labelName="Kitchens"
                name="kitchen"
                register={register("kitchen")}
                error={errors.kitchen?.message}
              />
              <SelectInput
                values={roomsOptions?.bathroom || []}
                type="text"
                labelName="Bathrooms"
                name="bathroom"
                register={register("bathroom")}
                error={errors.bathroom?.message}
              />
              <SelectInput
                values={roomsOptions?.bedroom || []}
                type="text"
                labelName="Bedrooms"
                name="bedroom"
                register={register("bedroom")}
                error={errors.bedroom?.message}
              />


              <SelectInput
                values={furnishingTypeOptions}
                selectedValue="Not furnished"
                labelName="Furnishing Type"
                name="furnish_type"
                register={register("furnish_type")}
                error={errors.furnish_type?.message}
              />
              <SelectInput
                values={depositStructureOptions}
                selectedValue="10%"
                labelName="Deposit Structure"
                name="deposit_structure"
                register={register("deposit_structure")}
                error={errors.deposit_structure?.message}
              />

              <div className="mb-10 mt-20 ">

                <MultiSelect
                  options={multiSelectOptions}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="Amenities"
                  className="mt-7" />
              </div>



              <div>
                <label className="text-lg font-semibold text-left">
                  Property Description
                </label>
                <textarea
                  className="w-full border border-ashThree focus:outline-none px-2 rounded-md py-1 mt-2"
                  rows="4"
                  cols="5"
                  placeholder="Write a short property description"
                  name="description"
                  error={errors.description?.message}
                  {...register("description")}
                ></textarea>
              </div>


            </section>
            <div className="flex w-full justify-center items-center text-center mb-10">
              <div className="w-full md:w-1/2">
                <Button loading={loading} buttonText="Save" />
              </div>
            </div>
          </form>


          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 my-8 justify-between">
            {images.map((image, index) => {
              return (
                <div className="rounded-md" key={index}>
                  <img
                    src={image?.image_url}
                    alt="propertyImage"
                    className="w-40 h-40" />
                  <div className="w-1/2 flex justify-between items-center">
                    <Link
                      to={`/dashboard/listings/updateImage/${image.id}`}
                      className="bg-primary text-white py-2 px-4 rounded-2xl my-2 text-sm font-normal focus:outline-none"
                    >
                      Update
                    </Link>
                    <div
                      className="w-4 cursor-pointer"
                      onClick={() => deletePropertyImage(image.id)}
                    >
                      {spinnerLoading ? (
                        <LoadSpinnerTwo />
                      ) : (
                        <img src={bin} alt="bin" className="w-8 md:w-4" />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div></>

      )}

    </>
  );
};

export default EditPropertyForm;




// "devDependencies": {
//   "autoprefixer": "^10.4.13",
//     "postcss": "^8.4.18",
//       "tailwindcss": "^3.2.3"
// }