import React, { useState, useEffect } from "react";
import PureModal from "react-pure-modal";
import "react-pure-modal/dist/react-pure-modal.min.css";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

import redbrick from '../assets/redbrick-logo.png'
import fraser from '../assets/fraser-property.png'
import redbrickProperty from '../assets/redbrick-property.png'
import modalItem from '../assets/modal-item.svg'
import modalItem1 from '../assets/modal-item1.svg'
import modalItem2 from '../assets/modal-item2.svg'
import modalItem3 from '../assets/modal-item3.svg'
import modalItem4 from '../assets/modal-item4.svg'
import modalItem5 from '../assets/modal-item5.svg'
import modalItem6 from '../assets/modal-item6.svg'

import locationIcon from "../assets/location.svg";
import placeholder from "../assets/placeholder.png";


import { axiosInstance } from "../Auth/Axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const LandingModal = () => {
  const [modal, setModal] = useState(true);
  const [middleProperty, setMiddleProperty] = useState([]);

  const location = useLocation()
  const oldUser = localStorage.getItem("UPLAND_OLD_USER")
  const modalItems = [modalItem, modalItem1, modalItem2, modalItem3, modalItem4, modalItem5, modalItem6]

  const getBoosted = () => {
    axiosInstance
      .get(`property/boosted/all`)
      .then(function (response) {
        // handle success
        const boostedProperty = response.data.data;
        setMiddleProperty(boostedProperty?.[0]);
      })
      .catch(function (error) {
        // handle error
      })
      .then(function () {
        // always executed
      });
  };

  useEffect(() => {
    getBoosted();


  }, []);



  return (
    <>
      {(location.pathname === '/' && !oldUser)
        &&
        <div>
          <PureModal
            isOpen={modal}
            closeButton="X"
            closeButtonPosition="header"
            onClose={() => {
              setModal(false);
              return true;
            }}
            width="99vw"
          >
            <div className="mx-auto md:px-52 md:py-1 ">
              <div style={{}} className='bg-white' key={middleProperty?.id}>
                <div className=" border border-white shadow-xl rounded-md">
                  <section className="flex w-full text-center md:mt-5 lg:mb-6 justify-between">
                    {/* <img src={redbrick} alt="redbrick" className="h-5 md:h-10 mt-2 md:-mt-2 object-contain" /> */}
                    <h1 style={{ color: '#4D4D4B' }} className="font-black font-roboto mx-auto leading-4 text-sm lg:text-4xl ">Real Estate <br className="lg:hidden" /> Re-Imagined</h1>
                    {/* <img src={fraser} alt="fraser" className="h-5 md:h-10 mt-2 md:-mt-2 object-contain" /> */}
                  </section>
                  <section className="w-full h-auto relative">

                    <div style={{ background: ` linear-gradient(to top right, #3F3D56 , #005BE3)` }} className=" w-24 h-12 md:h-20 absolute bottom-7 md:bottom-10 left-3 md:left-5 xl:left-12 p-1 flex flex-col justify-around text-white">
                      <p className="text-xs">
                        Starting Price
                      </p>
                      <p className="font-bold flex items-start text-xl md:text-3xl tracking-widest  w-max">
                        {middleProperty?.price && <>
                          <span className="text-base md:text-xl">₦</span>
                          {middleProperty?.price[0] + middleProperty?.price[1]}M
                        </>
                        }
                      </p>
                      <hr className="hidden md:block" />
                      <p className="break-words text-xxxs hidden md:block ">
                        Payment Plans up to 18 Months Available ff
                      </p>
                    </div>


                    <Link
                      to={`/propertydetails/${middleProperty?.id}`}
                      className="w-full"
                      onClick={() => setModal(false)}
                    >
                      <img
                        src={middleProperty?.images?.[0]?.image_url || redbrickProperty}
                        alt="location"
                        // width={276}
                        className="mx-auto w-44 md:w-72 object-contain"
                      />
                    </Link>
                  </section>




                  {/* <section className="mt-4 p-2">
                <p className="font-bold text-sm">{middleProperty?.name}</p>
                <div className="flex">
                <img src={locationIcon} alt="location" className="mr-2" />
                  <p className="font-bold text-sm text-ash my-1">
                    {middleProperty?.address_line_one}
                  </p>
                </div>
                <div className="w-full flex justify-between items-center">
                  <p className="font-bold text-base">{middleProperty?.price}</p>
                  <Link
                    to={`/propertydetails/${middleProperty?.id}`}
                    className="bg-primary text-white font-semibold
                          rounded-md p-3"
                  >
                    View Property
                    </Link>
                    </div>
                  </section> */}
                </div>
                <section className="flex bg-white lg:pt-2 md:px-10 items-center justify-between">
                  <div style={{ color: '#4D4B4B' }} className="font-inter text-xxs lg:text-sm leading-3  md:leading-4 font-bold capitalize  p-2 md:p-0 lg:p-2 ">
                    <p> No agency fees </p>
                    <p> No legal fees </p>
                    <p> No security deposits</p>
                  </div>

                  <div className="ml-auto  w-max md:mb-2 font-inter font-bold   ">
                    <p className="font-light text-xs lg:text-sm pr-8 lg:pr-12 leading-3 "> Exquisite &amp; Affordable</p>
                    <p style={{ color: '#4D4B4B' }} className="font-black text-xl md:text-3xl leading-4">Luxury</p>
                  </div>
                </section>

                <section className="flex justify-between p-2 bg-white shadow-md rounded ">

                  {modalItems.map(item =>
                    <div key={item}>
                      <img src={item} alt="modal item" className=" h-6 md:h-8 w-5 md:w-7" />
                    </div>
                  )}
                </section>
              </div>
            </div >
          </PureModal >
        </div >

      }
    </>
  );
};

export default withRouter(LandingModal);
