import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import bronzeBg from "../assets/bronzeBg.svg";
import SubCard from "../templates/SubCard";
import bronze from "../assets/bronze.svg";
import { plans } from "../data/subscription";
import { axiosWithAuth } from "../Auth/Axios";
import DashboardLoader from "../templates/DashboardLoader";
import { Button, SectionTitle } from "../atoms";
import { SubsCard, Subscription as Sub } from "../organisms";


const Subscription = ({ id }) => {
  const [active, setActive] = useState(1);
  const [getPlanId, setGetPlanId] = useState("");
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [getMonthId, setGetMonthId] = useState(3);
  const [billingInterval, setBillingInterval] = useState('Billed / 3 months')


  const callBack = window.location.origin;

  const upgradeSub = (planId) => {
    const subObject = {
      subscription_id: planId,
      duration: getMonthId,
      call_back_url: `${callBack}/paymentSuccess`,
    };
    setSpinnerLoading(true);
    axiosWithAuth()
      .put("/subscription/upgrade", subObject)
      .then((response) => {
        const successMessage = response.data;
        const authUrl = successMessage.data.authorization_url;
        setSpinnerLoading(false);
        window.open(authUrl, "_blank");
      })
      .catch(function (error) {
        if (error.response) {
          setSpinnerLoading(false);
          const errorMessage = error.response.data.data;
          Object.values(errorMessage.errors)
            .flat()
            .map((err) => {
              toast.error(err);
            });
          setError(errorMessage);
        }
      });
  };

  useEffect(() => {
    if (getMonthId && getPlanId) {
      // upgradeSub();
    }
  }, [getMonthId, getPlanId]);

  return (
    <>
      {loading ? (
        <DashboardLoader />
      ) : (
        // <div className="m-auto w-11/12">
        //     <div className="w-full my-4">
        //       <p className="text-2xl font-bold">Subscriptions</p>
        //       <div className="bg-primary w-16 h-2 ml-3"></div>
        //     </div>
        //     <div className="my-6 w-3/4 flex items-center shadow-md rounded-md  border border-lightAsh">
        //       {plans.map(({ monthId, plan, duration }) => {
        //         return (
        //           <div
        //             className="cursor-pointer"
        //             key={monthId}
        //             onClick={(e) => {
        //               setGetMonthId(() => duration);
        //               setActive(() => monthId);
        //               setGetPlanId("");
        //             }}
        //           >
        //             <p
        //               className="font-semibold text-base p-4 text-ash mr-8"
        //               style={+active === +monthId
        //                 ? { backgroundColor: "#5C1763", color: "#ffffff" }
        //                 : { backgroundColor: "#ffffff", color: "#1c1c1c" }}
        //             >
        //               {plan}
        //             </p>
        //           </div>
        //         );
        //       })}
        //     </div>
        //     <div className="w-full md:grid grid-cols-3 gap-6 mb-10">
        //       <SubCard
        //         icon={bronze}
        //         amount="$10.00"
        //         bgImage={bronzeBg}
        //         buttonText="Select"
        //         showButton={true}
        //         getPlanId={(getPlanId) => setGetPlanId(getPlanId)}
        //         loading={loading} />
        //     </div>
        //     <div
        //       className="flex w-full justify-center items-center text-center mb-10"
        //       onClick={upgradeSub}
        //     >
        //       <div className="w-2/6">
        //         <Button loading={spinnerLoading} buttonText="Upgrade" />
        //       </div>
        //     </div>
        //   </div>
        <div className="p-10">
          <SectionTitle title="Subscription" noLine />


          <section className="flex w-max m-auto">
            <div role='button'
              onClick={() => {
                setBillingInterval('Billed / 3 months')
                setGetMonthId(3)
              }}
              className={`py-4 px-16 font-normal text-base rounded-l-md transition  ${billingInterval === 'Billed / 3 months' ? 'text-yellow bg-primary ' : 'text-black bg-white border border-r-0 border-primary'} `}>
              3 Months
            </div>
            <div role='button'
              onClick={() => {
                setBillingInterval('Billed / 6 months')
                setGetMonthId(6)
              }}
              className={`py-4 px-16 font-normal text-base transition  ${billingInterval === 'Billed / 6 months' ? 'text-yellow bg-primary ' : 'text-black bg-white border-t border-b border-primary'} `}>
              6 Months
            </div>
            <div role='button'
              onClick={() => {
                setBillingInterval('Billed / 9 months')
                setGetMonthId(9)

              }}
              className={`py-4 px-16 font-normal text-base transition  ${billingInterval === 'Billed / 9 months' ? 'text-yellow bg-primary ' : 'text-black bg-white border-t border-b border-primary'} `}>
              9 Months
            </div>
            <div role='button'
              onClick={() => {
                setBillingInterval('Billed Yearly')
                setGetMonthId(12)
              }
              }
              className={`py-4 px-16 font-normal text-base rounded-r-md transition ${billingInterval === 'Billed Yearly' ? 'text-yellow bg-primary ' : 'text-black bg-white border border-l-0 border-primary'} `}>
              Yearly
            </div>
          </section>

          <div className="my-10">
            <SubsCard interval={billingInterval} dashboard upgradeSub={upgradeSub} />
          </div>
        </div>
      )}
    </>
  );
};

export { Subscription };
