import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import search from "../assets/search.svg";
import dropdown from "../assets/dropdown.svg";
import googleDownload from "../assets/google-play-img.svg"
import appleDownload from "../assets/apple-store-img.svg";
import location from "../assets/Location-icon.svg"
import { axiosInstance } from "../Auth/Axios";
import DashboardLoader from "../templates/DashboardLoader";
import { paramsParser } from "../utils/helpers/paramsParser";
import { commaSeparate } from "../utils/helpers/commaSeparator.js";


const Hero = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [filterBy, setFilterBy] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState({
    category: '',
    value: ''
  });
  const [getCategoryName, setGetCategoryName] = useState("");
  const [propertyType, setPropertyType] = useState("To Rent")
  const [searchType, setSearchType] = useState("location")

  const history = useHistory()

  const handleSearchTypeChange = ({ target }) => {
    setSearchType(target.value)
  }

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const getFilters = () => {
    setLoading(true);
    axiosInstance
      .get("property/attributes/all")
      .then(function (response) {
        // handle success
        const category = response.data.data.category;
        setFilterBy(category);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
      })
      .then(function () {
        // always executed
      });
  };

  // const getCategory = (name) => {
  //   return setGetCategoryName;
  // };

  const submitSearch = (e) => {
    e.preventDefault();
    let searchValueToSearch = searchType === 'price'
      ? searchValue.value.replaceAll(',', '')
      : searchValue.value
    history.push(`/all-properties?property_type=${paramsParser(propertyType)}&${searchType}=${paramsParser(searchValueToSearch)}`)
  }

  useEffect(() => {
    getFilters();
  }, []);


  return (
    <>
      {loading ? (
        <DashboardLoader />
      ) : (
        <>
          <div className="hero mb-40 ">
            <div style={{ background: 'rgba(84, 67, 107, 0.6)' }} className="pt-20  flex flex-col h-full w-full items-center justify-between">
              <div className=" text-center capitalize text-white text-3xl md:text-5xl leading-14 font-bold">
                <p>Real estate re-imagined</p>
                <p className="text-base normal-case w-3/5 text-center mx-auto my-8 font-normal " >
                  0% Agency fees
                  <br />
                  0% Legal fees
                  <br />
                  0% Security deposit
                  <br />
                  When you sell, rent or buy from us.
                </p>
                {/* {searchBar} */}

                <div className="flex w-max mx-auto my-5 space-x-5 ">
                  <div className="flex bg-black text-white space-x-2 rounded-lg ">
                    <a href="https://play.google.com" rel="noreferrer" target={'_blank'}  ><img src={googleDownload} alt="google app download" /></a>
                  </div>
                  <div className="flex bg-black text-white space-x-2  rounded-lg  ">
                    <a href="https://apps.apple.com" rel="noreferrer" target={'_blank'} ><img src={appleDownload} alt="Apple app download" /></a>
                  </div>
                </div>

              </div>
              <form onSubmit={submitSearch} className=" text-sm md:-mb-20 lg:w-2/3 xl:w-1/2 2xl:w-1/3 w-full  text-center">
                <div className=" w-max mx-auto text-xs rounded-md" >
                  <div className="flex font-circular text-lightBlack bg-white rounded-t w-max p-3 shadow border border-solid border-blue-50 ">
                    <p className={`text-center flex-1  p-2 px-5 mx-auto shadow rounded  ${propertyType === "To Rent" && 'bg-primary text-yellow'}`}
                      role={'button'}
                      onClick={(e) => setPropertyType("To Rent")}>
                      To Rent
                    </p>
                    <p className={`text-center flex-1 p-2 px-5 mx-auto shadow rounded  ${propertyType === "To Buy" && 'bg-primary text-yellow'} `}
                      role={'button'}
                      onClick={(e) => setPropertyType("To Buy")}>
                      To Buy
                    </p>
                    <p className={`text-center flex-1 py-auto flex items-center text-xxs px-5 leading-3 mx-auto shadow rounded  ${propertyType === "Under Construction" && 'bg-primary text-yellow'} `}
                      role={'button'}
                      onClick={(e) => setPropertyType("Under Construction")}>
                      Under Construction
                    </p>
                  </div>
                </div>

                <div className="flex  font-sofia w-full text-xxs md:text-normal h-28 justify-between items-center px-2 md:px-5 text-black mx-auto lg:space-x-4 space-x-2 rounded bg-white shadow" >
                  <div className="flex-1 ">
                    <input type="text"
                      placeholder={`Search by ${searchType} ${searchType === 'price' ? '(₦)' : ''}`}
                      className="flex rounded shadow md:px-5 px-2 text-sm w-full h-10 text-black focus:outline-none justify-between items-center"
                      onFocus={({ target }) => {
                        if (searchType !== 'price') return
                        const { value } = { ...target }
                        setSearchValue(prev => {
                          return { ...prev, value: value?.replaceAll(',', '') }
                        })
                      }}

                      onBlur={({ target }) => {
                        if (searchType !== 'price') return
                        const { value } = { ...target }
                        setSearchValue(prev => {
                          return { ...prev, value: commaSeparate(value) }
                        })
                      }}

                      onChange={(e) => setSearchValue(prev => {
                        return { ...prev, value: e.target.value }
                      })}
                      value={searchValue.value}
                    />
                  </div>
                  <>

                    {/* <div role={'button'} onClick={toggleDropDown} className=" relative rounded curs shadow md:pr-5 px-2 flex- md:h-10 h-8   ">
                    <div className="flex h-full w-max justify-between items-center">

                      <div className="w-max md:mr-3 mr-1">Property Types</div>
                      <div> <img src={dropdown} alt="dropdown" className={`w-2 md:w-3 mt-1 font-thin bg-white ${showDropDown && ''} `} /></div>
                    </div>
                    {showDropDown && (
                      <div className="flex  shadow rounded-md bg-white justify-center items-center absolute top-0 right-0 mt-14 flex-1 mx-auto">
                        <div className="align-end items-center w-full bg-white cursor-pointer space-y-1 p-1 ml-18">
                          {filterBy.map(({ id, name }) => {
                            return (
                              <div
                                className="flex hover:bg-primary hover:text-white w-full transition rounded text-black"
                                key={id}
                                onClick={() => {
                                  setGetCategoryName(() => name);
                                }}
                              >
                                <p
                                  className=" py-2 w-full    items-center"
                                  style={{ flex: "1" }}
                                >
                                  {name}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                  </div> */}
                  </>
                  <div>
                    <select onChange={handleSearchTypeChange} value={searchType} disabled name="searchType" id="searchType" className="bg-gray shadow cursor-pointer text-sm md:pr-16 pl-2 pr-5 flex- md:h-10 h-8">
                      <option value="location" >Location</option>


                      {/* <option value="price">Price</option> */}
                    </select>
                  </div>


                  <div role={'button'} className="flex- w-max"  >
                    <button
                      className="flex rounded shadow  lg:w-32 px-2 lg:px-0 border text-xs lg:text-sm bg-primary text-yellow md:h-10 h-8 justify-center items-center "
                    >
                      Search
                    </button>
                  </div>
                </div>


              </form >
            </div>

          </div>

        </>
      )}
    </>
  );
};

export { Hero };
