import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import placeholder from "../assets/placeholder.png";
import building from "../assets/userDashboard/building.svg";
import EmptyState from "../templates/EmptyState";
import { axiosWithAuth } from "../Auth/Axios";
import LoadSpinner from "../templates/LoadSpinner";
import { DashboardSectionTitle } from "../atoms";
import PropertyCard from "../templates/PropertyCard";
import { Property } from "../organisms/PropertySlider"
import { Link } from "react-router-dom";

const Listings = () => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [numberOfProperty, setNumberOfProperty] = useState("");
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  const getUserListings = () => {
    setLoading(false);
    axiosWithAuth()
      .get("property/user/listing")
      .then(function (response) {
        const properties = response.data.data;
        setResponse(properties);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          setError(error.response.data.data);
          setLoading(false);
        }
        // handle error
        setError(error.status);
      });
  };

  const getUserSub = () => {
    setLoading(true);
    axiosWithAuth()
      .get("/subscription/active")
      .then((response) => {
        const results = response.data.data;
        setNumberOfProperty(results.plan.number_of_property);
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.data || 'Error! Check your internet connection';
        toast.error(errorMessage);
        setError(error);
      });
  };

  const deleteProperty = (id) => {
    setSpinnerLoading(true);
    axiosWithAuth()
      .delete(`property/${id}`)
      .then((response) => {
        const successMessage = response.data.data;
        toast.success(successMessage);
        setSpinnerLoading(false);
      })
      .catch((error) => {
        const errorMessage = error.response.data.data;
        toast.error(errorMessage);
        setSpinnerLoading(false);
      });
  };

  useEffect(() => {
    getUserListings();
    getUserSub();
  }, []);

  const propertiesToSell = response.filter(
    (property) => property.property_category.name === "To Buy"
  );

  const propertiesToRent = response.filter(
    (property) => property.property_category.name === "To Rent"
  );

  const newDevelopment = response.filter(
    (property) => property.property_category.name === "Under Construction"
  );

  return (
    <div>
      <DashboardSectionTitle
        text="Listings"
        buttonText="Add New Listings"
        buttonUrl="/dashboard/listings/add"
        error={error}
        numberOfProperty={numberOfProperty}
        response={response}

      />
      {loading ? (
        <LoadSpinner />
      ) : response.length === 0 ? (
        <EmptyState
          image={building}
          text="You have not added any listings yet!"
          buttonText="Add New Listings"
        />
      ) : (
        <div className="mx-4 my-10">

          <div className="my-10">
            <p className="text-xl font-bold">Properties To Buy</p>
            <div className="md:grid md:grid-cols-3 gap-2 mt-3">
              {propertiesToSell?.length > 0 ?
                propertiesToSell.map((property) => (
                  // <div>
                  //   <PropertyCard
                  //     location={city}
                  //     price={price}
                  //     place={name}
                  //     photo={placeholder}
                  //     id={id}
                  //     showDeleteIcon={true}
                  //     showViewProperty={true}
                  //     deleteProperty={() => deleteProperty(id)}
                  //   />
                  // </div>
                  <Link key={property.id} to={`/dashboard/listings/editDetails/${property.id}`}>
                    <Property property={property} />
                  </Link>

                ))
                : <div className="text-textGray">No Properties to Show </div>
              }
            </div>
          </div>

          <div className="my-10">
            <p className="text-xl font-bold">Properties To Rent</p>
            <div className="md:grid md:grid-cols-3 gap-2 mt-3">

              {propertiesToRent.length > 0 ?
                propertiesToRent.map((property) => (
                  <Link key={property.id} to={`/dashboard/listings/editDetails/${property.id}`}>
                    <Property property={property} />
                  </Link>
                ))
                : <div className="text-textGray">No Properties to Show </div>
              }
            </div>
          </div>

          <div className="my-10">
            <p className="text-xl font-bold">Under Construction</p>
            <div className="md:grid md:grid-cols-3 gap-2 mt-6">
              {newDevelopment.length > 0 ?
                newDevelopment.map((property) => (
                  <Link key={property.id} to={`/dashboard/listings/editDetails/${property.id}`}>
                    <Property property={property} />
                  </Link>
                ))
                : <div className="text-textGray">No Properties to Show </div>
              }
            </div>
          </div>
        </div>
      )
      }
    </div >
  );
};

export { Listings };
