import { useState } from "react";
import { privacyPolicy } from "../data/values";
import { SectionWrapper } from "../Layout";
import { Header } from "../molecules";
import { Footer } from "../organisms";
import { Link } from "react-scroll";
import { SectionTitle } from "../atoms";


const TermsAndConditions = () => {
  const [selected, setSelected] = useState(0)

  const policyHeadings = [
    'Acceptance Of Agreement',
    'Content Liability',
    'Hyper Links',
    'Disclaimer',
    'Intellectual Property',
    'Contact Us',
    'Entire Agreement',
    'Indemnity'
  ]

  const Section = ({ title, children, ourValues }) => (

    <div className="">
      <h4 id={title.trim().replace(/ /g, '')} className="text-2xl font-black text-primary mb-5">
        {title}
      </h4>
      <div className="flex flex-col-reverse md:flex-row items-center">
        <div className="flex-grow text-justify">
          {ourValues ? <>{children}</> :
            <p className="font-normal font-sofia text-sm text-black leading-6 space-y-5">
              {children}
            </p>
          }
        </div>
      </div>

    </div>)


  return (
    <div>
      <Header />
      <div style={{ backgroundColor: '#F9F9F9' }} className="flex pt-10 pb-32 ">
        <div className="w-96  hidden md:flex sticky top-0 md:flex-col items-center">
          {/* <h3 className="text-3xl text-primary font-bold mb-10 mx-1">Terms &amp; Conditions</h3> */}
          <SectionTitle title='Terms &amp; Conditions' />

          <ul className="w-max space-y-5">

            {policyHeadings.map((heading, index) => (
              <li key={heading}
                className={`font-sofia font-semibold text-sm cursor-pointer ${selected === index ? 'text-primary' : 'text-textGray'}`} >
                <Link onClick={() => setSelected(index)}
                  to={`${heading.trim().replace(/ /g, '')}`} spy={true} smooth={true} delay={100} duration={100} className='w-full' >
                  {heading}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <main className="flex-1 py-20 px-7 sm:px-20 lg:pr-44 md:pl-20 space-y-11 ">
          <Section title={policyHeadings[0]} >
            <p>
              The following terms and conditions constitute a legally binding contract (this "Agreement") between you (“you” or “your”) and Uplandshunt, Inc., a Uplandshunt corporation that governs all use by you of uplandshunt.com website (the "Site") and the services available on or at the Site (taken together with the use of the Site, the "Services"). We’ll refer to uplandshunt and all of its subsidiaries and affiliated companies collectively as "uplandshunt", "we", "our", "us", etc.
            </p>

            <p>
              We provide this website, for the convenience of buyers, tenants, landlords business owners and browsers interested in immovable property, and collect content in the form of advertisements from private sellers, subscribing estate agents, letting agents, developers as well as related parties, to display properties they are marketing.  YOUR USE OF THE SITE CONSTITUTES YOUR ACCEPTANCE OF AND AGREEMENT TO BE BOUND BY THIS AGREEMENT.  FURTHERMORE, BY PLACING AN ORDER FOR PRODUCTS OR SERVICES FROM THE SITE, YOU ACCEPT AND ARE BOUND BY THIS  AGREEMENT.  If you do not agree to this Agreement, do not use the Site or any other Services. If you use our Services only for your personal use, you are considered a "User". If you use our Services to execute orders you are considered a "Agent".
            </p>
          </Section>

          <Section title={policyHeadings[1]} >
            <p>
              We shall have no responsibility or liability for any content appearing on your Website. You agree to indemnify and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any page on your Website or within any context containing content or materials that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights
            </p>
          </Section>

          <Section title={policyHeadings[2]} >
            <p>
              We will consider requests to remove links but will have no obligation to do so or to respond directly to you. We endeavor to ensure that the information on this website is correct, but we do not warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is kept up to date
            </p>
          </Section>

          <Section title={policyHeadings[3]} >
            <p>
              IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE WEB SITE, OR WITH ANY PROVISION OF THE AGREEMENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE WEB SITE. UNDER NO CIRCUMSTANCES SHALL THE COMPANY BE LIABLE TO ANY USER OR ANY THIRD PARTY ON ACCOUNT OF THAT USER'S USE OF THE WEB SITE. IN NO EVENT SHALL THE COMPANY AND/OR ITS SUPPLIERS BE LIABLE TO YOU OR ANY SUCH THIRD PARTY FOR ANY DIRECT, INDIRECT, PUNITIVE, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE WEB SITE, THE DELAY OR INABILITY TO USE THE WEB SITE, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE WEB SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE WEB SITE, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF THE COMPANY OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
            </p>
          </Section>

          <Section title={policyHeadings[4]} >
            <p>
              It includes the following (wherever and when ever arising and for the full term of each of them): any patent, trade mark, trade name, service mark, service name, design, design right, copyright, database right, moral rights, know how, trade secret and other confidential information, rights in the nature of any of these items in any country, rights in the nature of unfair competition rights and rights to sue for passing off or other similar intellectual or commercial right (in each case whether or not registered or registrable) and registrations of and applications to register any of them.
            </p>
          </Section>

          <Section title={policyHeadings[5]} >
            <p>
              If you have any questions regarding any of our terms, please <a className=" font-sans pl-5 underline text-blue-800" href="tel:+447846305105">contact us</a>
            </p>
          </Section>

          <Section title={policyHeadings[6]} >
            <p>
              These terms and conditions together with any documents expressly referred to in them, contain the entire Agreement between us relating to the subject matter covered and supersede any previous Agreements, arrangements, undertakings or proposals, written or oral: between us in relation to such matters. No oral explanation or oral information given by any party shall alter the interpretation of these terms and conditions. In agreeing to these terms and conditions, you have not relied on any representation other than those expressly stated in these terms and conditions and you agree that you shall have no remedy in respect of any misrepresentation which has not been made expressly in this Agreement.
            </p>
          </Section>

          <Section title={policyHeadings[7]} >
            <p>
              Any legal person that delivers or attempts to deliver any damaging code to this web Site, to gain unauthorised access to any page on this web site, to tamper with any of the content or pages that constitute this web site or duplicate such pages or content shall be prosecuted to the full extent of the law, and civil damages shall be claimed in the event that we suffer any damage or loss as a result of such actions.
            </p>
          </Section>

        </main>

      </div>
      <Footer />
    </div >
  );

};

export { TermsAndConditions };

// return (
//   <div>
//     <Header />
//     <SectionWrapper>
//       <div className="mt-10">
//         <p className="font-bold text-2xl ">Terms and Conditions</p>
//         <div className="bg-yellow h-2 w-20 ml-14" />
//       </div>
//       <div className="mt-6">
//         {privacyPolicy.map(({ id, title, note }) => {
//           return (
//             <div className="mb-4" key={id}>
//               <p className="text-primary font-semibold text-xl mb-2">
//                 {title}
//               </p>
//               <p className="font-normal text-base leading-relaxed">{note}</p>
//             </div>
//           );
//         })}
//       </div>
//     </SectionWrapper>
//     <Footer />
//   </div>
// );