import LoadSpinner from "../templates/LoadSpinner";

const Button = ({ buttonText, loading, className, type, ...props }) => {
  return (
    <div className={`active-button bg-primary rounded-md my-8 flex w-full justify-between items-center cursor-pointer text-center ${className ? className : 'text-yellow font-sembold'}`} {...props} >
      <button type={type} className="flex p-4  justify-center items-center h-full bg-primary w-full focus:outline-none ">
        {loading ? <LoadSpinner /> : buttonText}
      </button>
    </div>
  );
};

export { Button };
