
import React from 'react'

const ProfileDefaultImage = ({ firstName, lastName }) => {
  const getInitials = name => name?.[0]

  return (
    <div className='w-10 h-10 border-2 border-primary flex justify-center items-center bg-gray object-cover rounded-full'>
      <p className='uppercase text-xl font-bold text-primary'>
        {getInitials(firstName)}{getInitials(lastName)}
      </p>
    </div>
  )
}

export default ProfileDefaultImage