import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import bronzeBg from "../assets/bronzeBg.svg";
import SubCard from "../templates/SubCard";
import bronze from "../assets/bronze.svg";
import { plans } from "../data/subscription";
import { axiosWithAuth } from "../Auth/Axios";
import DashboardLoader from "../templates/DashboardLoader";
import { Button, SectionTitle } from "../atoms";
import { SubsCard, Subscription as Sub } from "../organisms";
import BidsSubCard from "../atoms/BidsSubCard";


const BidsSubscription = ({ bidData }) => {
  const [active, setActive] = useState(1);
  const [getPlanId, setGetPlanId] = useState("");
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [getMonthId, setGetMonthId] = useState(3);
  const [billingInterval, setBillingInterval] = useState('Billed / 3 months')


  const callBack = window.location.origin;

  const upgradeBidSub = (planId) => {
    const subObject = {
      token_id: planId,
      call_back_url: `${callBack}/paymentSuccess`,
    };
    setSpinnerLoading(true);
    axiosWithAuth()
      .post("/bid/purchase-token", subObject)
      .then((response) => {
        const successMessage = response.data;
        const authUrl = successMessage.data.authorization_url;
        setSpinnerLoading(false);
        window.open(authUrl, "_blank");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (getMonthId && getPlanId) {
      // upgradeSub();
    }
  }, [getMonthId, getPlanId]);

  return (
    <>
      {loading ? (
        <DashboardLoader />
      ) : (

        <div className="px-10">
          <SectionTitle title="Bids Subscription" noLine />

          <div className="my-10 flex">
            <BidsSubCard bidData={bidData} interval={'3 '} dashboard upgradeSub={upgradeBidSub} />
          </div>
        </div>
      )}
    </>
  );
};


export default BidsSubscription