import { useState, useEffect, useLayoutEffect, useMemo } from "react";
import { useParams, useHistory, Link, useLocation } from "react-router-dom";

import dropdown from "../assets/dropdown.svg";

import { FilterHeader, Header } from "../molecules";
import FilterOption from "../templates/FilterOption";
import { axiosInstance, axiosWithAuth } from "../Auth/Axios";
import DashboardLoader from "../templates/DashboardLoader";
// import { SingleSearchResult } from "../templates/SingleSearchResult";
import { sortByPrice } from "../data/subscription";
import { Button } from "../atoms";
import { Property } from "../organisms";
import { paramsUnparser } from "../utils/helpers/paramsParser";
import Pagination from "../molecules/Pagination";
import useQuery from "../utils/hooks/useQuery";


const SearchResult = () => {
  const query = useQuery()
  const location = query.get('location')?.length > 0 ? query.get('location') : null
  const price = query.get('price')
  const category = query.get('property_type')

  // console.log({ location, category, price })

  const history = useHistory()

  const [filterBy, setFilterBy] = useState([]);
  const [activeFilters, setactiveFilters] = useState([])
  const [loading, setLoading] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [togglePrice, setTogglePrice] = useState(false);
  const [paginateData, setPaginateData] = useState({
    currentPage: 1,
    itemPerPage: 10,
    nextPage: 2,
    prevPage: null,
    totalItems: 40,
    totalPages: 4,
  })
  const [currentPage, setCurrentPage] = useState(1)


  const initial = {
    category,
    location,
    availability: "",
    amenity: "",
    bathroom: "",
    bedroom: "",
    deposit_structure: "",
    furnish_type: "",
    kitchen: "",
    type: "",
    city: "",
    country: "",
    date: "",
    listed_by: "",
    price: price || '',
    page: "1"
  }
  let removeInitialSearch = { location: '', category: '' }
  const [queryParameter, setQueryParameter] = useState(initial);

  const getFilters = () => {
    setLoading(true);
    axiosInstance
      .get("property/attributes/all")
      .then(function (response) {
        // handle success
        const category = response.data;
        const entries = Object.entries(category.data);
        setFilterBy(entries);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
      })
      .then(function () {
        // always executed
      });
  };

  // const getSearchedProperty = () => {
  //   axiosInstance
  //     // .get(`property?search=${value}`)
  //     .get(`property?category=${category ?? ''}&location=${value ?? ''}`)
  //     // .get(`property?category=${""}&search=${""}`)
  //     .then(function (response) {
  //       // handle success
  //       const results = response.data.data;
  //       setSearchResults(results);
  //       setPaginateData(response.data.meta);
  //       setInitialState(false);
  //       setLoading(false);
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     })
  //     .then(function () {
  //       // always executed
  //     });
  // };


  const searchParameters = (params) => {
    setQueryParameter(prev => ({ ...prev, ...removeInitialSearch }))


    setLoading(true);
    const tempQuery = { ...queryParameter, ...params }

    const allActiveFilters = Object.entries(tempQuery)
      .filter((item) => item[1])
    setactiveFilters(allActiveFilters)

    const queryUrl = allActiveFilters
      .map((item) => item.join("="))
      .join("&");


    axiosInstance
      // .get(`property?search=${value}`)
      .get(`property?${queryUrl}`)
      .then(function (response) {
        // handle success
        const results = response.data.data;
        setSearchResults(results);
        setPaginateData(response.data.meta);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
      })
      .then(function () {
        // always executed
      });
  };

  const togglePriceDropDown = () => {
    setTogglePrice(!togglePrice);
  };

  const sortPropertiesByPrice = (id) => {
    setLoading(true);
    axiosWithAuth()
      .get(`property?sort=${id}`)
      .then((response) => {
        const results = response.data.data;
        setSearchResults(results);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
      })
      .then(function () {
        // always executed
      });
  };

  const checkNext = prev => (prev.page >= paginateData?.totalPages)
  const checkPrev = prev => (prev.page <= 1)


  // const handlePageChange = ({ selected }) => {
  //   setQueryParameter(prev => ({
  //     ...prev,
  //     page: check(prev) ? prev.page : `${Number(prev.page) + Number(selected)}`
  //   }))
  //   return Number(selected) + 1
  // }


  // const handleActivePage = ({ selected }) => {
  //   setQueryParameter(prev => ({
  //     ...prev,
  //     page: `${Number(selected) + 1}`
  //   }))
  // }


  const handlePageChange = ({ type }) => {

    switch (type) {
      case 'Next':
        setQueryParameter(prev => ({
          ...prev,
          page: checkNext(prev) ? prev.page : `${Number(prev.page) + 1}`
        }))
        break;

      case 'Prev':
        setQueryParameter(prev => ({
          ...prev,
          page: checkPrev(prev) ? prev.page : `${Number(prev.page) - 1}`
        }))
        break;

      default:
        break;
    }
  }


  const handleActivePage = ({ selectedPage }) => {

    setQueryParameter(prev => ({
      ...prev,
      page: selectedPage
    }))
  }



  useEffect(() => {
    // searchParameters()
    searchParameters()
  }, [queryParameter.page, queryParameter.price]);

  useEffect(() => {
    getFilters();
    searchParameters()
    // getSearchedProperty();
    // setQueryParameter(initial)
  }, []);


  return (
    <div className='' >
      {loading
        ? (
          <DashboardLoader />
        ) : (
          <>
            <Header />
            <div style={{ backgroundColor: '#f0f0f0' }} className="py-2"   >
              <div style={{ width: '92%' }} className="m-auto  mt-6 ">
                <div className="mb-8 w-full md:flex items-center justify-between">
                  <div className="flex justify-between items-center">
                    <section className="border-white bg-white rounded-md p-2 shadow-md mr-6">
                      <p className="font-bold text-base">
                        Page:{" "}
                        <span className="font-normal">
                          {paginateData?.currentPage} of {paginateData?.totalPages}
                        </span>
                      </p>
                    </section>
                    <button
                      onClick={() => window.location.replace('/all-properties')}
                      // onClick={() => { searchParameters({ ...initial, ...removeInitialSearch }) }}
                      className=" shadow-md text-yellow rounded bg-primary p-2 px-4 ">
                      Reset Filter
                    </button>
                  </div>

                  <div className="flex flex-col cursor-pointer">
                    <div
                      className="flex items-center mt-6 w-ma md:mt-0 ml-52 md:ml-0 py-2 px-6 bg-white shadow-md rounded-md"
                      onClick={togglePriceDropDown}
                    >
                      <p className="font-bold text-base">
                        Sort by Price: <span className="font-normal"> {queryParameter?.price || 'NIL'}  </span>
                      </p>
                      <img src={dropdown} alt="dropdown" className="p-1 mb-1" />
                    </div>
                    <section className="mt-2 bg-white shadow-md relative border-lightAsh">
                      {togglePrice && (
                        <div className="absolute -top-3 mt-0 bg-white capitalize w-full">
                          {sortByPrice.map(({ item, id }) => {
                            return (
                              <div
                                className=""
                                key={id}
                                onClick={(e) => {
                                  // sortPropertiesByPrice(id)
                                  // searchParameters(e, id)
                                  setTogglePrice(false)
                                  setQueryParameter(prev => { return { ...prev, price: id } })
                                }}
                              >
                                <p className={`hover:text-textGray transition py-2 px-6 ${queryParameter.price === id && 'bg-primaryDullBg'} `}>
                                  {item}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </section>
                  </div>
                </div>

                <div className="py-2 px-2 flex space-x-2 items-center my-2">
                  <p>Active Filters:</p>
                  <div className="flex">
                    {activeFilters.length !== 1 ?
                      activeFilters.filter(item => item[0] !== 'page').map(filter =>
                        <div key={filter[1]} className="py-1.5 px-3 mr-3 capitalize bg-white rounded shadow ">
                          {filter[0]}
                        </div>
                      ) :
                      <div className="font-bold">NIL</div>
                    }

                  </div>

                </div>

                <div className="grid grid-cols-10 gap-6">
                  <div className="hidden md:block col-start-1 col-end-3">
                    <div className=" bg-white rounded p-6">
                      <p className="font-bold text-lg  border-lightAsh mb-4">
                        {/* Filter by: */}
                      </p>
                      {filterBy.map((item, index) => {
                        return (
                          <FilterOption
                            setQueryParameter={setQueryParameter}
                            queryParameter={queryParameter}
                            item={item}
                            key={index}
                          />
                        );
                      })}
                      <button
                        onClick={() => searchParameters()}
                        className="bg-primary text-yellow w-full  px-4 py-2 rounded "
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  <div className="col-start-1 md:col-start-3 col-end-12 md:col-end-12">
                    {loading ? (
                      ""
                    ) : searchResults.length === 0 ? (
                      <div className="flex flex-col items-center justify-center">
                        <p className="font-bold text-base pb-4">
                          Oops! There are no results found on the property you are
                          looking for.{" "}
                        </p>
                        <p className="font-normal text-sm">
                          Check your spellings for typographical errors and try
                          another search.
                        </p>
                        <Link to="/" className="w-1/2">
                          <Button buttonText="Back to home" />
                        </Link>
                      </div>
                    ) : (
                      <div className="flex flex-wrap ">
                        {searchResults.map((searches, index) => (
                          // return <SingleSearchResult searches={searches} />;
                          <Property key={index} property={searches} />
                        ))}
                      </div>
                    )}


                    {/* <div className="my-10 ">
                      {paginateData.totalPages &&
                        < ReactPaginate
                          breakLabel="..."
                          nextLabel="next >"
                          onPageChange={handlePageChange}
                          // pageRangeDisplayed={5}
                          pageCount={paginateData.totalPages}
                          previousLabel=""
                          renderOnZeroPageCount={null}
                          className='flex p-5 font-mono rounded-md font-bold capitalize justify-between w-1/2 mx-auto bg-primary text-white border'
                          pageClassName="font-normal"
                          onPageActive={handleActivePage}
                          disableInitialCallback
                        />
                      }
                    </div> */}


                    <div className="my-10 ">
                      <Pagination
                        pageCount={paginateData?.totalPages}
                        currentPage={paginateData?.currentPage}
                        onPageChange={handlePageChange}
                        onSelectPage={handleActivePage}
                        nextText='Next >'
                        prevText='< Prev'
                      />
                    </div>
                  </div>
                  {/* <div class="col-start-6 col-end-8 hidden md:block">
                  <FilterHeader />
                </div> */}

                </div>
              </div>



            </div>

          </>
        )
      }
    </div >
  );
};

export { SearchResult };
