import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import placeholder from "../assets/placeholder.png";
import { DashboardSectionTitle, Button } from "../atoms";
import { axiosInstance, axiosWithAuth } from "../Auth/Axios";
import PropertyCard from "../templates/PropertyCard";
import SelectTwo from "../atoms/SelectTwo";
import { Property } from "../organisms";
import Select from "../atoms/Select";

const RequestProperty = () => {
  const messageRef = useRef(null);
  const location = useHistory();

  const [loading, setLoading] = useState(false);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [listTypeResult, setListTypeResult] = useState("");
  const [propertyTypeResult, setPropertyTypeResult] = useState("");
  const [results, setResults] = useState([]);
  const [initialState, setInitialState] = useState(true);
  const [propertyId, setPropertyId] = useState("");
  const [listingType, setListingType] = useState([]);
  const [propertyType, setPropertyType] = useState([]);

  const validationSchema = Yup.object().shape({
    message: Yup.string().required("Message is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm({
    formOptions,
    defaultValues: {
      name: '',
      city: '',
      price: '',
      area: '',
      postal_code: '',
      address_line_one: '',
      address_line_two: '',
      country: '',
      kitchens: '',
      category: '',
      availability: '',
      type: '',
      description: '',
      bedroom: '',
      bathroom: '',
      kitchen: '',
      furnish_type: '',
      deposit_structure: '',
      amenities: ''
    },
  });
  const { errors } = formState;

  const { register: registerTwo, handleSubmit: handleSubmitTwo } = useForm();

  const getFilters = () => {
    setLoading(true);
    axiosInstance
      .get("property/attributes/all")
      .then(function (response) {
        // handle success
        const category = response.data;
        const entries = Object.entries(category.data);
        const listingEntries = Object.values(entries[8][1]);
        const propertyEntries = Object.values(entries[0][1]);
        setListingType(listingEntries);
        setPropertyType(propertyEntries);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
      })
      .then(function () {
        // always executed
      });
  };

  const getPropertiesAvailable = (data) => {
    console.log(data);
    setLoading(true);
    axiosInstance
      .get(`property?type=${listingType[+data?.type - 1]?.name}&category=${propertyType[+data?.category - 1]?.name}`)
      .then((response) => {
        const searchResults = response.data.data;
        setResults(searchResults);
        setInitialState(false);
        setLoading(false);
      });
  };

  const getPropertyId = (id) => {
    setPropertyId(id);
    setShowMessageBox(true);
    if (messageRef.current) {
      messageRef.current.scrollIntoView();
    }
  };

  const submitPropertyRequest = (data) => {
    let id = { property_id: propertyId };
    const userData = { ...id, ...data };
    setLoading(true);
    axiosWithAuth()
      .post("request", userData)
      .then((response) => {
        if (response) {
          toast.success(response.data.message);
        }
        setLoading(false);
        location.push(`/dashboard/request/bidsPlaced`);
      });
  };

  useEffect(() => {
    setInitialState(true);
  }, [listTypeResult, propertyTypeResult]);

  useEffect(() => {
    getFilters();
  }, []);
  console.log(propertyType);
  return (
    <div className="m-auto w-11/12">
      <DashboardSectionTitle text="Request a Property" showButton={false} />
      <>
        <div className="flex items-center">
          <form
            className="w-full grid grid-cols-3 gap-6"
            onSubmit={handleSubmit(getPropertiesAvailable)}
          >
            <Select
              values={listingType}
              selectedValue="To Rent"
              labelName="Listing Type"
              register={register("type")}
              error={errors.category?.message}
            />
            <Select
              values={propertyType}
              selectedValue="To Rent"
              labelName="Property Type"
              register={register("category")}
              error={errors.category?.message}
            />
            <div className="">
              <Button buttonText="Search Property" loading={loading} />
            </div>
            {/* <div className="w-1/2">
                <Button loading={loading} buttonText="Submit" />
              </div> */}
          </form>
        </div>
        <div className="">
          {initialState ? (
            ""
          ) : results.length === 0 ? (
            <p className="text-center font-bold text-3xl py-6">
              No properties match your search
            </p>
          ) : (
            <div className="ml-4">
              <div className="md:grid md:grid-cols-3 gap-4 mt-6 cursor-pointer">
                {results.map((property) => {
                  return (
                    // <PropertyCard
                    //   location={city}
                    //   price={price}
                    //   place={name}
                    //   photo={placeholder || images[0].image_url}
                    //   getPropertyId={() => getPropertyId(id)}
                    // />
                    <div >
                      <div className="w-max mx-auto pr-10" role='button'
                        bids // onClick={ }
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16"> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                      </div>
                      <Property property={property} />
                    </div>

                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className=""></div>
      </>
      {showMessageBox ? (
        <form ref={messageRef} onSubmit={handleSubmit(submitPropertyRequest)}>
          <textarea
            name=""
            cols="20"
            rows="5"
            placeholder="Message"
            className="w-full font-normal text-base border border-gray-400 focus:outline-none px-2 rounded-md py-1 mt-1"
            style={{ backgroundColor: "#EFF0F6" }}
            register={register("message")}
            error={errors.message?.message}
          ></textarea>

          <div className="flex w-full justify-center items-center text-center mb-10">
            <div className="w-1/2">
              <Button loading={loading} buttonText="Submit" />
            </div>
          </div>
        </form>
      ) : (
        ""
      )}
    </div>
  );
};

export { RequestProperty };
