import logo from "../assets/userDashboard/logo.svg";
import { NavLink } from "react-router-dom";

import { userDashboard } from "../data/FooterItems";

const Sidebar = ({ toggleShowSideBar }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <section onClick={toggleShowSideBar} className=" w-ful ">
        {/* <div className="w-1/2 bg-primary h-screen  z-10 fixed top-0 left-0 right-0 bottom-0"> */}
        <div style={{ left: -2 }} className=" bg-primary text-white h-screen overflow-y-auto  mt-14 fixed top-0  z-40 w-3/4">
          <div className="flex justify-center">
            <img src={logo} alt="logo" className="object-contain w-3/4 " />
          </div>
          <div className="mt-6 space-y-10 h-4/5 ">
            {userDashboard.map(({ id, image, navItem, link }) => {
              return (
                <NavLink
                  exact
                  activeClassName="active"
                  to={link}
                  className="flex items-center cursor-pointer ml-4"
                  key={id}
                  onClick={scrollToTop}
                >
                  <img src={image} alt={navItem} className="mr-4" />
                  <p className="font-bold text-base">{navItem}</p>
                </NavLink>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export { Sidebar };
