import React from "react";
import { Link, NavLink } from "react-router-dom";

import logo from "../assets/userDashboard/logo.svg";
import { userDashboard } from "../data/FooterItems";
import { useAuthDispatch, logout } from "../Context";
import { DashboardHeader } from "./DashboardHeader";
import { DashboardSidebarFooter } from "./DashboardSidebarFooter";

const Sidebar = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const dispatch = useAuthDispatch();

  const handleLogout = () => {
    logout(dispatch);
    window.location.replace("/login");
  };

  return (
    <div className=" w-full flex flex-col sidebar bg-primary overflow-y-auto scrollbar-hide overflow-x-hidden h-screen pt-5 relative z-10 ">
      <Link
        to="/"
        className="cursor-pointer flex justify-center mr-5"
      >
        <img src={logo} alt="logo" />
      </Link>
      <div className="flex-1 flex flex-col justify-between">

        <div className="pt-10 ml-10 space-y-5 h-4/5 ">
          {userDashboard.map(({ id, image, navItem, link }) => {
            return (
              <NavLink
                exact
                activeClassName="active-dashboard "
                to={link}
                className="flex items-center font-circular rounded cursor-pointer w-4/5 p-2.5 text-gray hover:bg-primaryDull  "
                key={id}
                onClick={scrollToTop}
              >
                <img src={image} alt={navItem} className="mr-4" />
                <p className="font-normal  text-sm " style={{ flex: "1" }}>
                  {navItem}
                </p>
              </NavLink>
            );
          })}
          <button
            onClick={handleLogout}
            className="font-bold block w-full focus:outline-none text-left cursor-pointer text-white ml-10 text-base"
          >
            Logout
          </button>
        </div>


        <DashboardSidebarFooter />

      </div>
    </div>
  );
};

export { Sidebar };
