import logoTwo from "../assets/logoTwo.svg";

const Intro = ({ title, subtitle }) => {
  return (
    <div className="md:w-2/3 md:mx-auto mx-10 ">
      {/* <p className="text-3xl font-semibold mb-5 text-center">Company Logo</p> */}

      <p className="text-3xl  text-lightBlack md:mb-3 mb-10 text- ">{title} </p>
      <p className="text-lg text-dimGrey transition ">{subtitle}</p>
    </div>
  );
};

export default Intro;
