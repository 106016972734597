// eslint-disable-next-line react-hooks/exhaustive-deps
import { useState } from "react";
import { toast } from "react-toastify";

import eye from "../assets/userDashboard/eye.png";
import file from "../assets/userDashboard/file.png";
import bin from "../assets/bin.png";
import { axiosWithAuth } from "../Auth/Axios";
import LoadSpinnerTwo from "../templates/LoadSpinnerTwo";
import { Link } from "react-router-dom";

const Document = ({ document, id }) => {
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [spinnerLoadingTwo, setSpinnerLoadingTwo] = useState(false);
  const [error, setError] = useState(false);
  const deleteDocument = (docId) => {
    setSpinnerLoading(true);
    axiosWithAuth()
      .delete(`property/${id}/documents/${docId}`)
      .then((response) => {
        const result = response.data.data;
        toast.success(result);
        setSpinnerLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          setSpinnerLoading(false);
          const errorMessage = error.response.data.data;
          setError(errorMessage);
          toast.error(errorMessage);
        }
        // handle error
        setError(error.status);
      });
  };
  console.log(document);
  return (
    <>
      {/* <div
        key={document.id}
        className="px-4 grid grid-cols-4 gap-8 py-6 font-normal text-sm md:text-base border-b border-ashThree"
      >
        <div style={{}} className="flex w-60 items-center  text-sm font-semibold ">
          <img src={file} alt="file" className="w-8 h-8 mr-2" />
          <p>{document.name} </p>
        </div>
        <div className="cursor-pointer flex-1 flex items-center justify-center">
          <a
            href={document?.image_url}
            rel="noreferrer"
            target="_blank"
            className=" m-auto"
          >
            <img src={eye} alt="" className="md:h-6 h-4 " />
          </a>
        </div>
        <div className="flex items-center w-3/5 justify-center">
          <button
            className="  uppercase font-sofia font-bold text-white mx-auto  px-2 py-1 text-xxs md:text-xs "
            style={{
              color:
                document.status === "approved"
                  ? "#29CC97"
                  : document.status === "pending"
                    ? "black"
                    : "#5C1763",
            }}
          >
            {document.status}
          </button>
        </div>
        <div className="flex items-center ">
          <Link
            to={`/dashboard/listings/documentUpdate/${document.id}/${document.name}`}
          >
            <button className="bg-white rounded-md py-2 px-10 mr-4 text-primary uppercase border border-primary text-xs">
              {spinnerLoadingTwo ? <LoadSpinnerTwo /> : "Update"}
            </button>
          </Link>
          <div
            className="cursor-pointer"
            onClick={() => deleteDocument(document.id)}
          >
            {spinnerLoading ? (
              <LoadSpinnerTwo />
            ) : (
              <img src={bin} alt="bin" className="w-4" />
            )}
          </div>
        </div>
      </div>
 */}


      <tr
        key={document.id}
        className=" w-full shadow mb-4 font-normal text-base "
      >
        <td className="flex items-center p-4 ">
          <img src={file} alt="file" className="w-8 h-8 mr-2" />
          <p>{document.name} </p>
        </td>
        <td>
          <a
            href={document?.image_url}
            rel="noreferrer"
            target="_blank"
            className=" m-auto"
          >
            <img src={eye} alt="" className="md:h-6 h-4 " />
          </a>
        </td>
        <td >
          <button
            className="  uppercase font-sofia font-bold text-white mx-auto  px-2 py-1 text-xxs md:text-xs "
            style={{
              color:
                document.status === "approved"
                  ? "#29CC97"
                  : document.status === "pending"
                    ? "black"
                    : "#5C1763",
            }}
          >
            {document.status}
          </button>
        </td>

        <td className="font-semibold ">
          <div className="flex items-center">

            <Link
              to={`/dashboard/listings/documentUpdate/${document.id}/${document.name}`}
            >
              <button className="bg-white rounded-md py-2 px-10 mr-4 text-primary uppercase border border-primary text-xs">
                {spinnerLoadingTwo ? <LoadSpinnerTwo /> : "Update"}
              </button>
            </Link>
            <div
              className="cursor-pointer w-max"
              onClick={() => deleteDocument(document.id)}
            >
              {spinnerLoading ? (
                <LoadSpinnerTwo />
              ) : (
                <img src={bin} alt="bin" className="w-4" />
              )}
            </div>
          </div>
        </td>
      </tr>

    </>
  );
};

export { Document };
