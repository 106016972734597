import bgImage from "../assets/bgImg.png";
import abstraction from "../assets/Abstraction.png";
import logoWhite from "../assets/logoWhite.svg";


const Background = () => {
  return (
    <div className="w-full h-full object-cover relative text-center overflow-auto">
      <div className="h-full ">
        <img
          src={abstraction}
          alt="bgImage"
          className=" bg-primary h-full w-full object-cover"
        />
      </div>
      <p className="absolute top-16 right-1/4 -mr-5 w-max text-white text-5xl leading-14 font-bold capitalize">
        <img src={logoWhite}
          alt="site logo"
          className=" bg-primary  object-cover"
        />

      </p>
    </div>
  );
};

export default Background;
