import React, { useState } from "react";
import { Button, SectionTitle } from "../atoms";
import { SectionWrapper } from "../Layout/SectionWrapper";
import SubCard from "../templates/SubCard";
import bronze from "../assets/bronze.svg";
import plan from "../assets/plan.svg";
import bronzeBg from "../assets/bronzeBg.svg";
import SubscriptionCard from "../templates/SubscriptionCard";
import check from "../assets/check-circle.svg";
import { useHistory } from "react-router-dom";


const BidsSubCard = ({ interval, bidData, dashboard, setGetPlanId, getPlanId, upgradeSub }) => {

  const bronzeItems = [

  ]

  const Span = ({ children, small, hovered }) => (
    <span
      style={{ color: hovered ? 'white' : '#231D4F' }}
      className={`font-extrabold text-black font-circular ${small ? 'text-2xl' : 'text-4xl'} `}  >{children} </span>
  )

  const Card = ({ subId, price, type, desc, items, gold, noOfBids }) => {
    const [hovered, setHovered] = useState(false)
    return (
      <div
        onMouseLeave={() => setHovered(false)}
        onMouseEnter={() => setHovered(true)}
        onClick={() => setHovered(true)}
        onTouchMove={() => setHovered(true)}
        onTouchEnd={() => setHovered(false)}
        className={` rounded-t-md shadow ${dashboard && 'mx-5'} ${gold && 'bg-gold'}`}>

        <div
          style={{ height: gold ? '513px' : '473px' }}
          // style={{ height: gold ? '313px' : '273px' }}
          className={`w-72  ${!gold && 'bg-white'} p-11 cursor-pointer  ${hovered && ' bg-primary text-white transition  relative'}    ${subId === 3 && 'rounded-tl-md '}  ${subId === 1 && ' rounded-t-md '}   `}>
          <p className="mb-7 ">
            <Span hovered={hovered}><s>N</s>{price} </Span> <p> - {noOfBids} extra bids</p>
          </p>
          <div className="mb-4">
            <Span hovered={hovered} small>{type}</Span>
          </div>
          <p className="mb-6">{desc}</p>
          <ul className="">
            {items.map((item => (
              <li key={item} className="flex items-center my-3">
                <img src={check} alt="check" className="mr-6" />
                {/* <Check fill='#f6e0f8' className="mr-6 hover:text-white hover:" /> */}

                <p>{item}</p>
              </li>

            )))}
          </ul>
        </div>
        {dashboard &&
          <div
            role={'button'}
            onClick={() => {
              upgradeSub(subId)
            }}
            className=" px-5 border-t border-white bg-primaryDullBg text-primary text-center hover:bg-primary hover:text-white py-4 md:w-full rounded-b-md"
          >
            Buy Now
          </div>
        }
      </div>
    );
  }


  console.log(bidData);
  return (
    <div className="w-auto overflow-x-scroll md:overflow-hidden " >
      <div className=" text-primary flex items-end w-max mx-auto rounded-md text-sm leading-6 ">
        {/* <Card
          price='20' type='Bronze' items={bronzeItems} subId={3}
          desc='It is a long established fact that a reader will be distracted by the..'
        />
        <Card
          price='50' type='Silver' items={bronzeItems} subId={2}
          desc='For most businesses that want to otpimize web queries'
        />
        <Card
          price='100' type='Gold' items={bronzeItems} subId={1}
          desc='For most businesses that want to optimize web queries'
          gold
        /> */}

        {bidData?.sort((a, b) => a?.id - b?.id)?.map((datum) => (

          <Card
            key={datum?.id} subId={datum?.id}
            price={datum?.amount} type={datum?.name}
            items={datum?.attributes}
            desc={datum?.description}
            gold={datum?.id === 3}
            noOfBids={datum?.number_of_tokens}
          />

        ))}
      </div>

    </div>
  )
}

export default BidsSubCard