import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useParams } from "react-router";

import { Modal, SubsCard } from "../organisms";
import interest from "../assets/interest.png";
import { axiosInstance, axiosWithAuth } from "../Auth/Axios";
import { Button, InputTwo, SectionTitle } from "../atoms";
import ModalContainer from "../organisms/ModalContainer";
import { BidsRecieved, UserSubscription } from "../pages";
import BidsSubscription from "../pages/BidsSubscription";

const Bids = ({ bidsLeft, propertyOwnerId }) => {
  let { id } = useParams();
  const path = useLocation();

  const getUserDetails = JSON.parse(localStorage.getItem("auth"));
  const getUserAuthStatus = getUserDetails.isAuthenticated;
  const [bidsReceived, setBidsReceived] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showBidSubscriptionModal, setShowBidSubscriptionModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getPlanId, setGetPlanId] = useState("");
  const [bidData, setBidData] = useState([])
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [error, setError] = useState("");


  const validationSchema = Yup.object().shape({
    amount: Yup.string().required("Price is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;
  const currentUserId = JSON.parse(localStorage.getItem('auth'))?.user?.id
  const isPropertyOwner = +currentUserId === +propertyOwnerId



  const [getMonthId, setGetMonthId] = useState(3);
  const [billingInterval, setBillingInterval] = useState('Billed / 3 months')


  const callBack = window.location.origin;
  // const userId = JSON.parse(localStorage.getItem('auth'))?.user?.id

  const upgradeSub = (planId) => {
    const subObject = {
      subscription_id: planId,
      duration: getMonthId,
      call_back_url: `${callBack}/paymentSuccess`,
    };
    setSpinnerLoading(true);
    axiosWithAuth()
      .put("/subscription/upgrade", subObject)
      .then((response) => {
        const successMessage = response.data;
        const authUrl = successMessage.data.authorization_url;
        setSpinnerLoading(false);
        window.open(authUrl, "_blank");
      })
      .catch(function (error) {
        if (error.response) {
          setSpinnerLoading(false);
          const errorMessage = error.response.data.data;
          Object.values(errorMessage.errors)
            .flat()
            .map((err) => {
              toast.error(err);
            });
          setError(errorMessage);
        }
      });
  };



  const fetchBidSub = (planId) => {
    axiosWithAuth()
      .get("/bid/token-plans")
      .then((response) => {
        setBidData(response.data.data)
      })
      .catch(function (error) {
        toast.error(error);
        setError(error);
      });
  };




  const getBidsReceived = () => {
    // axiosInstance.get(`bid/${id}/bids`).then((response) => {
    axiosWithAuth().get(`bid/bid-history/${currentUserId}/${id}`).then((response) => {
      const results = response.data?.data
      // ?.filter(item => item?.user_id
      // === `${currentUserId}`);
      setBidsReceived(results);
    });
  };

  const handleClose = () => {
    setShowModal(false);
    setShowBidSubscriptionModal(false);
  };

  const placeBid = (data) => {
    if (getUserAuthStatus) {
      let propertyId = { property_id: id };
      const userData = { ...propertyId, ...data };
      setLoading(true);
      return axiosWithAuth()
        .post(`bid`, userData)
        .then((response) => {
          const successMessage = response.data.data;
          toast.success(successMessage);
          reset();
          setLoading(false);
          getBidsReceived()
        })
        .catch(function (error) {
          if (error.response) {
            setLoading(false);
            const errorMessage = error.response.data.data;
            reset();
            toast.error(errorMessage);
          }
        });
    } else {
      const currentPath = path.pathname;
      //Save data to sessionStorage
      sessionStorage.setItem("propertyPath", currentPath);
      window.scrollTo(0, 0);
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (getMonthId && getPlanId) {
      // upgradeSub();
    }
  }, [getMonthId, getPlanId]);

  useEffect(() => {
    getBidsReceived();
    fetchBidSub()
  }, []);


  console.log(bidsReceived);

  return (
    <>

      <ModalContainer modalOpen={showBidSubscriptionModal} toggleModal={handleClose}>
        <div>
          <div className="w-full flex h-3/4 bg-white p-10">
            <BidsSubscription bidData={bidData} />
            <div role='button' onClick={handleClose} className=""> x </div>

          </div>

        </div>

      </ModalContainer>

      <div className="grid grid-cols-2 gap-6 m-auto mt-10 w-11/12">
        {/* <Modal showModal={showBidSubscriptionModal} handleClose={handleClose} showButton={false}>
        </Modal> */}
        <Modal showModal={showModal} handleClose={handleClose} showButton={false}>
          <div className="text-center">
            <p className="font-bold text-lg my-4">
              Login or Register to place and save bid
            </p>
            <p className="font-normal text-sm text-ash">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              tortor nisi, mattis quis purus at, mattis mattis ligula. Quisque vel
              ex convallis, eleifend erat imperdiet, lacinia dui. Mauris elementum
              efficitur nisl.Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Aenean tortor nisi, mattis quis purus at, mattis mattis
              ligula. Quisque vel ex convallis, eleifend erat imperdiet, lacinia
              dui. Mauris elementum efficitur nisl.
            </p>
            <div className="flex items-center justify-center my-4">
              <Link to="/register">
                <button className="font-bold text-base text-primary py-2 px-8 border border-primary rounded-md mr-6">
                  Register
                </button>
              </Link>
              <Link to="/login">
                <button className="font-bold text-base text-white bg-primary py-2 px-8 rounded-md">
                  Login
                </button>
              </Link>
            </div>
          </div>
        </Modal>

        <img src={interest} alt="interest" />
        {getUserDetails?.user && !isPropertyOwner
          ? <div className="">
            <div className="p-4 flex justify-between bg-primary font-bold text-lg text-white rounded-tr-md rounded-tl-md">
              <p>Bids Received</p>
              <p className="rounded-full h-8 w-8 flex items-center justify-center text-primary bg-white">
                {bidsReceived.length}
              </p>
            </div>



            <div className="border border-b-name border-lightAsh">
              <div
                className="w-full flex font-bold justify-between border-b border-lightAsh"
                key={id}
              >
                <p className="uppercase p-4">
                  <p> Buyer /
                    <span className="uppercase text-blue-600"> Counter  </span> Bids
                  </p>
                </p>
                {/* <p className="uppercase p-4">
                  <p> Buyer Bids /</p>
                  <p className="uppercase text-blue-600"> Counter Bids </p>
                </p> */}

                <p className="uppercase p-4 "> Date </p>
              </div>


              {bidsReceived.map(({ id, amount, counterBidId, counterBid, updatedAt }) => {
                return (
                  <>
                    <div
                      className="w-full flex justify-between border-b border-lightAsh"
                      key={id}
                    >
                      <p className="font-bold text-lg p-4">₦{amount}</p>
                      <p className="font-normal text-sm p-4">
                        {new Date(updatedAt).toLocaleDateString()}
                      </p>
                    </div>

                    {counterBidId && <div
                      className="w-full flex justify-between border-b border-lightAsh"
                      key={id}
                    >
                      <p className="font-bold text-lg p-4 text-blue-600">₦{counterBid?.amount}</p>
                      <p className="font-normal text-sm p-4">
                        {new Date(counterBid?.createdAt).toLocaleDateString()}
                      </p>
                    </div>}
                  </>

                );
              })}
              {/* <p className="font-bold text-lg my-4 p-4">Place your bid</p> */}
              <p className="font-bold text-base px-4 my-4">Place your bid</p>
              <form onSubmit={handleSubmit(placeBid)}>

                {+bidsLeft !== 0 ? <>

                  <section className="m-auto w-11/12">
                    <InputTwo
                      register={register("amount")}
                      error={errors.amount?.message}
                      placeholder='Price'
                    />
                  </section>
                  {<div
                    className="bg-primary m-auto w-11/12 rounded-md mb-4"
                    onClick={placeBid}
                  >
                    <Button loading={loading} buttonText="Submit your bid" />
                  </div>

                  }
                </>
                  :
                  <div
                    className="bg-primary m-auto w-11/12 rounded-md mb-4"
                  // onClick={() => setShowBidSubscriptionModal()}
                  >

                    <Button onClick={() => setShowBidSubscriptionModal(true)}
                      loading={loading} buttonText="Pay for more bids " />

                  </div>
                }
              </form>
            </div>

          </div>
          : !isPropertyOwner
            ? <Link to={`/login`}>
              <Button
                loading={loading} buttonText="Sign in to make a bid " />
            </Link>
            : <div className="m-auto text-3xl font-bold">This property is owned by you</div>
        }


      </div>
    </>
  );
};

export default Bids;
