import React from "react";
import { Link } from "react-router-dom";
import { mobileMenu } from "../data/subscription";

const DropdownMenu = () => {
  return (
    <div>
      {mobileMenu.map(({ id, item, url }) => {
        return (
          <Link to={url} key={id}>
            <p className="font-semibold text-base mb-3 text-dimGrey">{item}</p>
          </Link>
        );
      })}
    </div>
  );
};

export { DropdownMenu };
