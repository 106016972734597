import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

import cloud from "../assets/cloud.png";
import upload from "../assets/upload-icon.png";
import LoadSpinner from "./LoadSpinner";

const SimpleDropZone = ({
  maximumFiles,
  minimumFiles,
  handleSubmit,
  handleChangeStatus,
  fileType,
  buttonText,
  imageTitle,
  loading,
  isImageTitle,
  video
}) => {
  const handleFileSubmit = (files) => {
    if (isImageTitle) {
      handleSubmit(files, imageTitle);
    } else {
      handleSubmit(files);
    }
  };
  return (
    <div className="mt-14">
      <p className="font-bold text-textGray text-base mb-4">{imageTitle}</p>
      <Dropzone
        onChangeStatus={handleChangeStatus}
        onSubmit={(files) => handleFileSubmit(files)}
        submitButtonContent={loading ? <LoadSpinner /> : buttonText}
        accept={fileType}
        inputContent={(files, extra) =>
          files.reject ? `Only ${video ? 'video' : 'image'} files allowed!` :
            <div className="w-full justify-start text-textGray  items-center">
              <div className="flex w-full px-10 space-x-5 items-center">

                <div role='button' className="text-sm text-yellow  flex items-center bg-gray p-2 rounded" >
                  Upload file...
                </div>

                <div className="flex ">
                  <img src={upload} alt="cloud" className="w-max" />
                  <p className="text-left text-base mx-3 font-normal">
                    Drop file here
                  </p>
                </div>
              </div>
            </div>
        }
        styles={{
          dropzoneReject: { borderColor: "red" },
          inputLabel: (files, extra) =>
            extra.reject ? { color: "#A02800" } : {},
          color: "#c4c4c4",
          fontSize: "16px",
          fontWeight: 600,

          dropzone: {
            border: "2px solid white",
            boxShadow: "0px 0px 4px 0px #00000040",
            overflowX: "hidden",
            overflowY: "hidden",
          },
          submitButton: (files) =>
            files.length < minimumFiles
              ? { backgroundColor: "#c4c4c4" }
              : { backgroundColor: "#5C1763" },
        }}
        maxFiles={maximumFiles}
        inputWithFilesContent={(files) => `${maximumFiles - files.length} more`}
        submitButtonDisabled={(files) => files.length < minimumFiles}
        // handleSubmit={handleSubmit}
        maxSizeBytes={video ? 33554432 : 2097152}
      />

      <div className="mt-4 ">
        <p className="text-xs font-semibold text-textGray">
          {video ? 'Each video should be an MP4, MKV or GIF format and each file size should be less than 30MB*'
            : 'Each image should be a JPG or PNG format and each file size should be less than 2MB*'}
        </p>
        <p className="text-xs font-semibold text-textGray">
          Minimum of  {minimumFiles} and Maximum of {maximumFiles} {video ? 'videos' : 'images'}**
        </p>
      </div>
    </div>
  );
};

export default SimpleDropZone;
