import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { DeleteAccount, Modal } from "../organisms";
import edit from "../assets/userDashboard/edit.svg";
import { InputTwo, Button, Input } from "../atoms";
import { axiosWithAuth } from "../Auth/Axios";
import DashboardLoader from "./DashboardLoader";
import { UploadProfilePicture } from "../organisms/UploadProfilePicture";

const IndividualProfile = () => {
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);

  const validationSchema = Yup.object().shape({
    old_password: Yup.string()
      .min(7, "Password must be at least 7 characters")
      .required("Password is required"),
    password: Yup.string()
      .min(7, "Password must be at least 7 characters")
      .required("Password is required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;

  const getUserProfile = () => {
    setLoading(true);
    axiosWithAuth()
      .get("auth/me")
      .then(function (response) {
        const profile = response.data.data;
        setResponse(profile);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          setError(error.response.data.data);
          setLoading(false);
        }
        // handle error
        setError(error.status);
      });
  };

  const changePassword = (data) => {
    setSpinnerLoading(true);
    axiosWithAuth()
      .post("account/change-password", data)
      .then((response) => {
        reset();
        const successMessage = response.data.data;
        toast.success(successMessage);
        setSpinnerLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          setSpinnerLoading(false);
          const errorMessage = error.response.data.data;
          Object.values(errorMessage.errors)
            .flat()
            .map((err) => {
              toast.error(err);
            });
          reset();
        }
      });
  };

  const showDeleteAccount = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const { email, first_name, phone, last_name, avatar } = response;

  const userObject = [
    ["Name", `${first_name} ${last_name || ''}`],
    ["Contact Number", phone],
    ["Email Address", email],
  ];
  return (
    <>
      {loading ? (
        <DashboardLoader />
      ) : (
        <div className="">

          <section className="md:mx-16 mx-10 flex justify-between items-center mb-5 py-5 border-b border-ashThree font-circular text-2xl">

            <p>Profile</p>
            <div className="px-8 md:px-16 py-2 rounded border  border-red-500 "
              onClick={showDeleteAccount} role={'button'}
            >
              <p className="text-red-500 text-base font-bold">
                Delete My Account
              </p>
            </div>
            <Modal
              modalTitle="Delete My Account"
              showModal={showModal}
              handleClose={handleClose}
            >
              <DeleteAccount />
            </Modal>
          </section>

          <section className="md:flex my-20 md:justify-between  ">
            <div className="flex-none mr-6 ml-10 mt-6 ">
              <UploadProfilePicture avatar={avatar} />
              <p className="font-bold text-center text-2xl mb-4">
                {first_name}
              </p>
            </div>

            <div className="flex-grow w-full ">
              <div className="border border-ashThree rounded-md mt-6 ml-10 md:ml-auto mr-10 w-3/4">
                <div className="bg-primary flex justify-between p-4 text-white">
                  <p className="font-bold text-base">Personal Information</p>
                  <Link
                    to="/dashboard/editindividualprofile"
                    className="cursor-pointer"
                  >
                    <img src={edit} alt="edit" />
                  </Link>
                </div>
                <div
                  className=""
                  style={{ boxShadow: "0px 0px 4px 0px #00000040" }}
                >
                  {userObject.map((item, index) => {
                    return (
                      <div
                        className="flex p-4"
                        key={index}
                        style={{
                          borderBottom: "0.7px solid #c4c4c4",
                        }}
                      >
                        {item[1] && <><p
                          className="font-semibold text-base"
                          style={{ flex: "1" }}
                        >
                          {item[0]}
                        </p><p
                          className="font-normal text-lg mr-4"
                          style={{ flex: "1" }}
                        >
                            {index === 1 && item[1][0] !== '0' && item[1].length < 11 && '0'}
                            {item[1]}
                          </p></>}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>

          <section className="md:flex mb-20">
            <div className="md:mx-16 mx-10 font-circular text-2xl">
              <p>Password</p>
            </div>

            <div className=" mt-6 ml-10 w-3/4 p-4">

              <div className="">

                <form onSubmit={handleSubmit(changePassword)}>
                  <div className="w-full py-3">
                    <Input
                      label="Old Password"
                      type="text"
                      register={register("old_password")}
                      error={errors.old_password?.message}
                    />
                    <Input
                      label="New Password"
                      type="text"
                      register={register("password")}
                      error={errors.password?.message}
                    />
                    <Input
                      label="Password Confirmation"
                      type="text"
                      register={register("password_confirmation")}
                      error={errors.password_confirmation?.message}
                    />
                  </div>
                  <div className="flex -my-8 w-full justify-center items-center text-center">
                    <div className="w-1/2">
                      <Button loading={spinnerLoading} buttonText="Save" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>




        </div>
      )}
    </>
  );
};

export default IndividualProfile;
