import { SectionWrapper } from ".";
import { ListingsHeader } from "../molecules";

const ListingsLayout = ({ children }) => {
  return (
    <>
      <div className="m-auto w-11/12 ">
        <SectionWrapper>
          <div className="m-auto ">
            <ListingsHeader />
          </div>
          <section>{children}</section>
        </SectionWrapper>
      </div>
    </>
  );
};

export { ListingsLayout };
