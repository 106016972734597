import Intro from "../templates/Intro";
import { AuthLayout } from "../Layout";
import { Link } from "react-router-dom";

const DashboardLogin = () => {
  return (
    <AuthLayout>
      <Intro title="Welcome Back" subtitle="Log in to access your dashboard " />
      <div className="mt-12 m-auto w-8/12">
        <div className="my-8 w-full text-center">
          <Link to='/login'>
            <button className="rounded-md p-4 mb-10 text-white bg-primary font-semibold w-full">
              Individual
            </button>
          </Link>
          <Link to='/login'>
            <button className="rounded-md p-4 text-white bg-primary font-semibold w-full">
              Administrator
            </button>
          </Link>
        </div>
      </div>
    </AuthLayout>
  );
};

export { DashboardLogin };
