import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Document } from "../organisms";
import DashboardLoader from "../templates/DashboardLoader";
import { axiosInstance } from "../Auth/Axios";
import { ListingsLayout } from "../Layout";

const Documents = ({ showHeader = true }) => {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);

  const getPropertyDocuments = () => {
    axiosInstance
      .get(`property/${id}`)
      .then(function (response) {
        // handle success
        const details = response.data.data.documents;
        setloading(false);
        setData(details);
      })
      .catch(function (error) {
        // handle error
      })
      .then(function () {
        // always executed
      });
  };

  useEffect(() => {
    getPropertyDocuments();
  }, []);

  return (
    <>
      <ListingsLayout >
        {loading ? (
          <DashboardLoader />
        ) : (
          <div className="overflow-x-auto">
            <table className="mt-8 w-full  border border-ashThree rounded-md overflow-auto">
              <tr className="mt-2 p-2 md:p-4  w-full mb-4 font-bold text-sm md:text-base border-b border-ashThree">
                <td className="p-4">Document Name</td>
                <td>View Document</td>
                <td>Status</td>
                <td>Action</td>
              </tr>

              {data.map((document) => <Document document={document} id={id} />)}
            </table>
          </div>
        )}
      </ListingsLayout>
    </>
  );
};

export { Documents };
