const Input = ({
  placeholder,
  className,
  type,
  name,
  id,
  error,
  label,
  readOnly,
  register,
  ...props
}) => {
  return (
    <div className="mb-10 flex-1 w-full ">

      <input
        type={type}
        name={name}
        id={id}
        placeholder={label}
        className="w-full border-b focus:border-black transition-colors duration-500 border-ashThree focus:outline-none px-2 py-1 mt-1"
        style={
          readOnly
            ? { backgroundColor: "#e4e4e4" }
            : { backgroundColor: "#ffffff" }
        }
        {...register}
        {...props}
      />
      <span>
        <p className="text-red-500 text-sm"> {error}</p>
      </span>
    </div>
  );
};

export { Input };
