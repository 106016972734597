import { Link, NavLink } from "react-router-dom";
import { propertyRequest } from "../data/subscription";

const PropertyRequestHeader = () => {
  return (
    <div className="flex items-center justify-between">
      {propertyRequest.map(({ id, item, url }) => {
        return (
          <NavLink
            className="cursor-pointer"
            activeClassName="dashboard-active"
            to={url}
            key={id}
          >
            <p className="font-bold text-lg text-ash mr-8">{item}</p>
          </NavLink>
        );
      })}
      <Link to="/dashboard/request/requestProperty">
        <button className="bg-primary font-bold text-white rounded-md p-6">
          Request A Property
        </button>
      </Link>
    </div>
  );
};

export { PropertyRequestHeader };
