import React from "react";

const LoadSpinnerTwo = () => (
  <div className="spin">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default LoadSpinnerTwo;
